import Tabs, { TabDetails } from "../common/Tabs";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import UserTaskListItemDto from "../../types/dtos/tasks/advanced-tasks/UserTaskListItemDto";
import TaskTab from "./TaskTab";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import advancedTasksApi from "../../api/task/advancedTasksApi";
import { ClientTaskType } from "../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import { UserContextInterface } from "../../state/UserContext";
import taskRestrictionHelper from "../../helpers/taskRestrictionHelper";
import { CountRestrictionType } from "../../types/dtos/tasks/advanced-tasks/CountRestrictionType";
import { ClientTaskTypeCategory } from "../../types/dtos/tasks/advanced-tasks/ClientTaskTypeCategory";
import TaskManagementRestrictionBanner from "./TaskManagementRestrictionBanner";
import UserTaskListGroupDto from "../../types/dtos/tasks/advanced-tasks/UserTaskListGroupDto";
import AddAdvancedTaskPopup from "../tasks/advanced-tasks/AddAdvancedTaskPopup";
import ExistingAdvancedTaskPopup from "../tasks/advanced-tasks/ExistingAdvancedTaskPopup";
import SuccessAlert from "../alerts/SuccessAlert";
import { BaseUserDetailsDto } from "../../types/dtos/generic";
import { AdvancedTaskTabCounts } from "../../types/dtos/tasks/advanced-tasks/AdvancedTaskTabCounts";
import { AdvancedTaskStatus } from "../../types/dtos/tasks/advanced-tasks/AdvancedTaskStatus";
import { AdvancedTaskListingResponse } from "../../types/dtos/tasks/advanced-tasks/api-response/AdvancedTaskListingResponse";
import { advancedTaskHelper } from "../../helpers";
import { TaskReviewDetailDto } from "../../types/dtos/tasks/advanced-tasks/TaskReviewDetailDto";
import TaskListLockedBanner from "./TaskListLockedBanner";

interface TaskManagementSectionProps {
  tabCounts: AdvancedTaskTabCounts;
  userContext: UserContextInterface;
  taskType: ClientTaskType;
  tasks: UserTaskListItemDto[];
  minTasks: number | null;
  maxTasks: number | null;
  countRestriction: CountRestrictionType;
  userGroup?: UserTaskListGroupDto;
  activeCategories: ClientTaskTypeCategory[] | null;
  userTaskIdFromUrl?: string | undefined | null;
  isTeamTaskManagement: boolean;
  isActivelyInReview: boolean,
  taskReviewDetails: TaskReviewDetailDto | null;
  /** Task state is held above this component, so if something is triggered inside here which needs to update the task state, that needs passing upwards */
  setTasks?: (
    tasks: UserTaskListItemDto[],
    tabCounts: AdvancedTaskTabCounts
  ) => void;
  setUserTasks?: (
    userId: number,
    tasks: UserTaskListItemDto[],
    tabCounts: AdvancedTaskTabCounts
  ) => void;
}

const TaskManagementSection = ({
  tabCounts,
  userContext,
  taskType,
  tasks,
  minTasks,
  maxTasks,
  countRestriction,
  activeCategories,
  userGroup,
  userTaskIdFromUrl,
  isTeamTaskManagement,
  isActivelyInReview,
  taskReviewDetails,
  setTasks,
  setUserTasks,
}: TaskManagementSectionProps) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const tasksApi = new advancedTasksApi(auth.user?.access_token);
  const clientColour = userContext.user.client.primaryHexColour;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeTaskTabType, setActiveTaskTabType] =
    useState<AdvancedTaskStatus>("ACTIVE");

  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalTaskCount, setTotalTaskCount] = useState<number>(tasks.length);
  const [tasksOnDisplay, setTasksOnDisplay] = useState<UserTaskListItemDto[]>(
    []
  );

  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState<boolean>(false);
  const [isExistingTaskModalOpen, setIsExistingTaskModalOpen] =
    useState<boolean>(false);
  const [selectedUserTaskId, setSelectedUserTaskId] = useState<string | null>(
    null
  );

  const [showSuccessNotification, setShowSuccessNotification] =
    useState<boolean>(false);
  const [successNotificationMessage, setSuccessNotificationMessage] =
    useState<string>("");

  const reachedMaximumTaskLimit = maxTasks != null && tasks.length >= maxTasks;
  let taskRestrictionText: string | null = null;
  let showRestrictionBanner: boolean = false;

  const doesUserHaveTaskRestrictions =
    minTasks != null ||
    maxTasks != null ||
    activeCategories?.find((x) => x.minTaskCount || x.maxTaskCount) != null;
  if (doesUserHaveTaskRestrictions) {
    taskRestrictionText =
      taskRestrictionHelper.getRestrictionBannerTextHtmlForManagementPages(
        taskType,
        activeCategories,
        countRestriction,
        minTasks,
        maxTasks,
        isTeamTaskManagement ? userGroup?.employeeFirstName! : null,
        t
      );

    // If the task type is set to suppress validation, then we don't show it
    showRestrictionBanner = taskType.suppressDashboardCreateAndValidate
      ? false
      : activeTaskTabType == "ACTIVE" &&
      !isActivelyInReview &&
      !taskRestrictionHelper.doesUserHaveTasksThatSatisfyRestrictions(
        tasks,
        minTasks,
        maxTasks,
        countRestriction,
        activeCategories
      );
  }

  // Whenever there is a change in tasks we need to reset the pagination
  useEffect(() => {
    resetPagination();
  }, [tasks]);

  useEffect(() => {
    if (userTaskIdFromUrl != undefined && userTaskIdFromUrl != null) {
      // Introduce a small delay to have the page load first before attempting to open the task
      setTimeout(() => {
        onTaskClick(userTaskIdFromUrl);
      }, 500);
    }
  }, [userTaskIdFromUrl]);

  const onActiveTabChange = (tabName: string) => {
    setIsLoading(true);

    let tab: AdvancedTaskStatus = "ACTIVE";
    switch (tabName) {
      case t("TaskType.Tabs.Active"):
        tab = "ACTIVE";
        break;
      case t("TaskType.Tabs.Completed"):
        tab = "COMPLETED";
        break;
      case t("TaskType.Tabs.Cancelled"):
        tab = "CANCELLED";
        break;
      case t("TaskType.Tabs.Historic"):
        tab = "HISTORIC";
        break;
    }

    setActiveTaskTabType(tab);
    refreshTaskData(tab);
  };

  const refreshTaskData = (tab: AdvancedTaskStatus) => {
    setShowSuccessNotification(false);

    if (isTeamTaskManagement && userGroup) {
      tasksApi.listTasksForManagerByTabAndUser(
        taskType.id,
        tab,
        userGroup.employeeId!,
        (data: AdvancedTaskListingResponse) => {
          // Sort the order of the tasks depending on the tab selected
          data.tasks =
            tab === "ACTIVE"
              ? data.tasks.sort(
                (a, b) =>
                  new Date(a.targetDate).getTime() -
                  new Date(b.targetDate).getTime()
              )
              : data.tasks.sort(
                (a, b) =>
                  new Date(b.targetDate).getTime() -
                  new Date(a.targetDate).getTime()
              );

          setIsLoading(false);
          if (setUserTasks != null) {
            setUserTasks(userGroup.employeeId, data.tasks, data.tabCounts);
          }
        },
        (error: any) => {
          console.error("Error getting tasks on tab change", error);
        }
      );
    } else {
      tasksApi.listTasksForOwnerByTab(
        taskType.id,
        tab,
        (data: AdvancedTaskListingResponse) => {
          // Sort the order of the tasks depending on the tab selected
          data.tasks =
            tab === "ACTIVE"
              ? data.tasks.sort(
                (a, b) =>
                  new Date(a.targetDate).getTime() -
                  new Date(b.targetDate).getTime()
              )
              : data.tasks.sort(
                (a, b) =>
                  new Date(b.targetDate).getTime() -
                  new Date(a.targetDate).getTime()
              );

          setIsLoading(false);
          if (setTasks != null) {
            setTasks(data.tasks, data.tabCounts);
          }
        },
        (error: any) => {
          console.error("Error getting tasks on tab change", error);
        }
      );
    }
  };

  const handleModalOpenChange = (isOpen: boolean) => {
    setIsExistingTaskModalOpen(isOpen);
    if (!isOpen) {
      refreshTaskData(activeTaskTabType);
    }
  };

  const handlePageNumberChange = (pageNumber: number) => {
    setPageNumber(pageNumber);
    calculateTasksToDisplay(pageNumber);
  };

  const resetPagination = () => {
    setPageNumber(1);
    setTotalTaskCount(tasks.length);
    calculateTasksToDisplay(1);
  };

  const calculateTasksToDisplay = (newPageNumber: number) => {
    var startIndex = (newPageNumber - 1) * itemsPerPage;
    let displayItems = [...tasks].splice(startIndex, itemsPerPage);
    setTasksOnDisplay(displayItems);
  };

  const onCommentCountChanged = (
    userTaskId: string,
    newCommentCount: number
  ) => {
    const newTasksArray = [...tasksOnDisplay];
    const taskIndexToUpdate = newTasksArray.findIndex(
      (x) => x.userTaskId == userTaskId
    );
    if (taskIndexToUpdate > -1) {
      newTasksArray[taskIndexToUpdate].commentCount = newCommentCount;
      setTasksOnDisplay(newTasksArray);
    }
  };

  const onSuccessfulTaskCreation = () => {
    refreshTaskData("ACTIVE");
    setIsAddTaskModalOpen(false);
    setShowSuccessNotification(true);
    setSuccessNotificationMessage(
      t("TaskType.Popup.Validation.SuccessfullySavedNewTask").replace(
        "#TASK_TYPE#",
        advancedTaskHelper.ToLowerCase(
          taskType.singularNameTranslationKeyIdentifier
        )
      )
    );
  };

  const onSuccessfulTaskChange = (messageTranslationKeyIdentifier: string) => {
    handleModalOpenChange(false);
    setShowSuccessNotification(true);
    setSuccessNotificationMessage(
      t(messageTranslationKeyIdentifier).replace(
        "#TASK_TYPE#",
        advancedTaskHelper.ToLowerCase(
          taskType.singularNameTranslationKeyIdentifier
        )
      )
    );
  };

  const onSuccessfulTaskUpdate = () => {
    onSuccessfulTaskChange("TaskType.Popup.Validation.SuccessfullyUpdatedTask");
  };

  const onSuccessfulTaskCancel = () => {
    onSuccessfulTaskChange(
      "TaskType.Popup.Validation.SuccessfullyCancelledTask"
    );
  };

  const onSuccessfulTaskClose = () => {
    onSuccessfulTaskChange(
      "TaskType.Popup.Validation.SuccessfullyCompletedTask"
    );
  };

  const onSuccessfulTaskReOpen = () => {
    onSuccessfulTaskChange(
      "TaskType.Popup.Validation.SuccessfullyReOpenedTask"
    );
  };

  const onSuccessfulSavedForLater = () => {
    onSuccessfulTaskChange(
      "TaskType.Popup.Validation.SuccessfullySavedForLater"
    );
  };

  const onTaskClick = (userTaskId: string) => {
    handleModalOpenChange(true);
    setSelectedUserTaskId(userTaskId);
    clearTaskHighlight(userTaskId);
  };

  /** If the task is highlighted for the current user, clear the highlight and update the state to remove the HighlightDot */
  const clearTaskHighlight = (userTaskId: string) => {
    const matchingTask = tasks.find((x) => x.userTaskId == userTaskId);

    if (!matchingTask) return;

    const newTasksArray = [...tasks];
    const taskIndexToUpdate = newTasksArray.findIndex(
      (x) => x.userTaskId == userTaskId
    );
    if (
      matchingTask.ownerEmployeeId == userContext.user.id &&
      matchingTask.highlightToOwner
    ) {
      newTasksArray[taskIndexToUpdate].highlightToOwner = false;
      setTasksOnDisplay(newTasksArray);
      userContext.removeTaskHighlight(
        matchingTask.ownerEmployeeId,
        taskType.id,
        userTaskId
      );
    } else if (
      matchingTask.ownerEmployeeId != userContext.user.id &&
      matchingTask.highlightToManager
    ) {
      newTasksArray[taskIndexToUpdate].highlightToManager = false;
      setTasksOnDisplay(newTasksArray);
      userContext.removeTaskHighlight(
        matchingTask.ownerEmployeeId,
        taskType.id,
        userTaskId
      );
    }
  };

  const getTabContent = (tabType: AdvancedTaskStatus) => {
    return (
      <>
        {!isLoading && showRestrictionBanner && taskRestrictionText != null && (
          <TaskManagementRestrictionBanner
            bannerTextHtml={taskRestrictionText}
          />
        )}
        {showSuccessNotification && successNotificationMessage.length > 0 && (
          <div className="pt-1">
            <SuccessAlert
              prefix={t("Common.Success")}
              message={successNotificationMessage}
            />
          </div>
        )}
        <TaskTab
          userContext={userContext}
          tabType={tabType}
          isLoading={isLoading}
          taskType={taskType}
          displayTasks={tasksOnDisplay}
          itemsPerPage={itemsPerPage}
          pageNumber={pageNumber}
          totalItemCount={totalTaskCount}
          userGroup={userGroup}
          disabledNewTaskButton={reachedMaximumTaskLimit}
          allTasks={tasks}
          minTasks={minTasks}
          maxTasks={maxTasks}
          countRestriction={countRestriction}
          activeCategories={activeCategories}
          isTeamTaskManagement={isTeamTaskManagement}
          isActivelyInReview={isActivelyInReview}
          onAddNewTaskClick={() => setIsAddTaskModalOpen(true)}
          onExistingTaskClick={onTaskClick}
          onPageNumberChange={handlePageNumberChange}
        />
      </>
    );
  };

  let tabs: Array<TabDetails> = [
    {
      title: t("TaskType.Tabs.Active"),
      content: getTabContent("ACTIVE"),
      displayAlertIcon: false,
      itemCount: tabCounts.active,
      itemCountDisplayMode: "BRACKETS",
    },
    {
      title: t("TaskType.Tabs.Completed"),
      content: getTabContent("COMPLETED"),
      displayAlertIcon: false,
      itemCount: tabCounts.completed,
      itemCountDisplayMode: "BRACKETS",
    },
    {
      title: t("TaskType.Tabs.Cancelled"),
      content: getTabContent("CANCELLED"),
      displayAlertIcon: false,
      itemCount: tabCounts.cancelled,
      itemCountDisplayMode: "BRACKETS",
    },
  ];

  if (tabCounts.historic > 0) {
    tabs.push({
      title: t("TaskType.Tabs.Historic"),
      content: getTabContent("HISTORIC"),
      displayAlertIcon: false,
      itemCount: tabCounts.historic,
      itemCountDisplayMode: "BRACKETS",
    });
  }

  const taskSubjectOwner: BaseUserDetailsDto =
    isTeamTaskManagement && userGroup
      ? {
        userId: userGroup.employeeId,
        firstName: userGroup.employeeFirstName,
        fullName: userGroup.employeeFullName,
      }
      : {
        firstName: userContext.user.firstName,
        fullName: userContext.user.fullName,
        userId: userContext.user.id,
      };

  return (
    <div>
      {isActivelyInReview && taskReviewDetails && (
        <TaskListLockedBanner
          taskType={taskType}
          isTeamTaskManagement={isTeamTaskManagement}
          taskReviewDetails={taskReviewDetails}
        />
      )}
      <Tabs
        tabs={tabs}
        selectedTabClassNames={cx(
          "radix-state-inactive:border-0 radix-state-inactive:hover:border-b-2 radix-state-inactive:hover:!border-b-gray-200",
          "radix-state-active:border-b-2",
          clientColour
            ? `radix-state-active:border-b-[#${clientColour}]`
            : "radix-state-active:border-b-gray-400",
          clientColour ? `radix-state-active:text-[#${clientColour}]` : ""
        )}
        maxTabsOnSmallScreens={3}
        contextClassNames="!py-0"
        tabListWidth="w-1/3"
        onActiveTabChangeEvent={onActiveTabChange}
      />
      {taskType && (
        <>
          <AddAdvancedTaskPopup
            usageScenario="DASHBOARD-TASKS"
            taskType={taskType}
            activeTasks={tasks ?? []}
            maxOverallTaskRestriction={maxTasks ?? null}
            categories={activeCategories ?? []}
            ownerEmployee={taskSubjectOwner}
            isOpen={isAddTaskModalOpen}
            onOpenChange={setIsAddTaskModalOpen}
            onSuccessfulCreation={onSuccessfulTaskCreation}
          />
          <ExistingAdvancedTaskPopup
            usageScenario="DASHBOARD-TASKS"
            taskType={taskType}
            userTaskId={selectedUserTaskId}
            categories={activeCategories ?? []}
            isOpen={isExistingTaskModalOpen}
            onOpenChange={handleModalOpenChange}
            onSuccessfulUpdate={onSuccessfulTaskUpdate}
            onSuccessfulCancel={onSuccessfulTaskCancel}
            onSuccessfulClose={onSuccessfulTaskClose}
            onSuccessfulReOpen={onSuccessfulTaskReOpen}
            onSuccessfulSavedForLater={onSuccessfulSavedForLater}
            onCommentCountChanged={onCommentCountChanged}
            isLocked={isActivelyInReview}
          />
        </>
      )}
    </div>
  );
};

export default TaskManagementSection;
