import cx from "classnames";
import AdvancedTaskDto from "../../../../types/dtos/forms/AdvancedTaskDto";
import TaskManagementConfigDto from "../../../../types/dtos/forms/TaskManagementConfigDto";
import TaskStatusBadge from "./TaskStatusBadge";
import { UserContextInterface } from "../../../../state/UserContext";
import { DropDownMenu, HighlightDot } from "../../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import { ClientTaskType } from "../../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import { advancedTaskHelper } from "../../../../helpers";
import { FormType } from "../../../../types/forms";
import EnforcedCommentsSection from "./EnforcedCommentsSection";
import { t } from "i18next";
import { TaskManagementDropDownMenuArgs } from "../../../../types/tasks/TaskManagementDropDownMenuArgs";

interface AdvancedTaskMobileItemProps {
  userContext: UserContextInterface;
  task: AdvancedTaskDto;
  taskType: ClientTaskType | undefined;
  config: TaskManagementConfigDto;
  isCancelled?: boolean;
  isReadOnly: boolean;
  formType: FormType;
  displayRatingColumn: boolean;
  columnCount: number;
  loggedInUserId: number;
  isLoggedInUserTheSubjectUser: boolean;
  subjectUsersName: string;
  nonSubjectUsersName: string;
  onDropdownMenuItemClick: (userTaskId: string, action: string) => void;
  onViewButtonClick: (userTaskId: string, snapshotDate: Date) => void;
}

function AdvancedTaskMobileItem({
  userContext,
  task,
  taskType,
  config,
  isCancelled = false,
  isReadOnly,
  formType,
  displayRatingColumn,
  columnCount,
  loggedInUserId,
  isLoggedInUserTheSubjectUser,
  subjectUsersName,
  nonSubjectUsersName,
  onDropdownMenuItemClick,
  onViewButtonClick,
}: AdvancedTaskMobileItemProps) {
  /// Show enforced comments section if its enabled and isn't for cancelled tasks
  const showEnforceCommentsSection =
    config.enforceComments == true &&
    task.enforcedComments != null &&
    isCancelled == false;

  const onDropDownItemClick = (index: number, action: string) => {
    onDropdownMenuItemClick(task.taskId, action);
  };

  const onViewClickHandler = () => {
    if (task.snapshotDate != null) {
      onViewButtonClick(task.taskId, task.snapshotDate);
    }
  };

  const btnDropDownContents = (
    <div>
      <button id="manager-dashboard-option" className="!px-4 block w-full">
        <FontAwesomeIcon icon={faEllipsisVertical} className="pl-2" />
      </button>
    </div>
  );

  const viewTaskDetailsPopup = () => {
    onDropDownItemClick(0, TaskManagementDropDownMenuArgs.ViewDetails);
  };

  return (
    <div className="bg-white border-b text-gray-800 p-3">
      <div className="flex flex-row">
        <div className="basis-11/12">
          <TaskStatusBadge
            status={task.status}
            isNewlyCreatedInThisJourney={task.isNewlyCreatedInThisJourney}
            isOverdue={task.isOverdue}
            textSize="text-base"
          />
        </div>
        {/* Not Read-only - Show actions button */}
        {!isReadOnly && (
          <div className="basis-1/12">
            <DropDownMenu
              eventType="EVENT"
              items={advancedTaskHelper.getDropDownMenuOptions(
                task,
                taskType,
                formType,
                showEnforceCommentsSection,
                config.questionMode,
                isLoggedInUserTheSubjectUser,
                subjectUsersName
              )}
              onItemClick={onDropDownItemClick}
              customButtonContents={btnDropDownContents}
              menuButtonClassName="cursor-pointer"
            />
          </div>
        )}
        {/* Read-only - Show view button */}
        {isReadOnly && (
          <div className="basis-1/12">
            <button
              className="text-[#5d5d5d] bg-[#F5F5F5] hover:bg-gray-200 font-medium rounded-sm py-1 px-4 border border-gray-300 text-xs w-full lg:inline-block lg:w-auto"
              onClick={onViewClickHandler}
            >
              {t("Common.View")}
            </button>
          </div>
        )}
      </div>
      <div className="pt-1">
        {advancedTaskHelper.taskIsHighlightedForUser(
          userContext,
          task,
          isReadOnly
        ) && <HighlightDot containerClassNames="mr-1" />}
        <button className="hover:cursor-pointer text-left" onClick={viewTaskDetailsPopup}>
          {task.title}
        </button>
      </div>
      {displayRatingColumn && (
        <div className="pt-1 italic">
          {isCancelled && <>Rating: N/A</>}
          {!isCancelled && (
            <>
              {task.rating == null
                ? "Rating: -"
                : `Rating: ${task.rating}/${config.ratingQuestionMaxValue}`}
            </>
          )}
        </div>
      )}
      {showEnforceCommentsSection && (
        <tr className="bg-white border-b">
          <td colSpan={columnCount}>
            <EnforcedCommentsSection
              task={task}
              taskType={taskType!}
              formType={formType}
              status={task.status}
              details={task.enforcedComments}
              contentClassName="pt-2"
              loggedInUserId={loggedInUserId}
              isLoggedInUserTheSubjectUser={isLoggedInUserTheSubjectUser}
              subjectUsersName={subjectUsersName}
              nonSubjectUsersName={nonSubjectUsersName}
              onItemAction={onDropdownMenuItemClick}
            />
          </td>
        </tr>
      )}
    </div>
  );
}

export default AdvancedTaskMobileItem;
