
import { t } from "i18next";
import { AdvancedTaskStatus } from "../../../../types/dtos/tasks/advanced-tasks/AdvancedTaskStatus";
import { FormType } from "../../../../types/forms";
import AdvancedTaskEnforcedCommentDto from "../../../../types/dtos/forms/AdvancedTaskEnforcedCommentDto";
import cx from "classnames";
import {
  faComments,
  faCheck,
  faBell,
  faWarning
} from "@fortawesome/pro-regular-svg-icons";
import { TaskManagementDropDownMenuArgs } from "../../../../types/tasks/TaskManagementDropDownMenuArgs";
import EnforcedCommentsBadge from "./EnforcedCommentsBadge";
import { ClientTaskType } from "../../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import AdvancedTaskDto from "../../../../types/dtos/forms/AdvancedTaskDto";
import { advancedTaskHelper } from "../../../../helpers";

interface EnforcedCommentsSectionProps {
  task: AdvancedTaskDto;
  taskType: ClientTaskType;
  formType: FormType;
  status: AdvancedTaskStatus;
  details: AdvancedTaskEnforcedCommentDto;
  contentClassName?: string;
  loggedInUserId: number;
  isLoggedInUserTheSubjectUser: boolean;
  subjectUsersName: string;
  nonSubjectUsersName: string;
  onItemAction: (userTaskId: string, action: string) => void;
}

function EnforcedCommentsSection({
  task,
  taskType,
  formType,
  status,
  details,
  contentClassName = "",
  loggedInUserId,
  isLoggedInUserTheSubjectUser,
  subjectUsersName,
  nonSubjectUsersName,
  onItemAction,
}: EnforcedCommentsSectionProps) {
  const showTaskDetails = () => {
    onItemAction(task.taskId, TaskManagementDropDownMenuArgs.ViewDetails);
  }

  const showTaskComments = () => {
    onItemAction(task.taskId, TaskManagementDropDownMenuArgs.AddComment);
  }

  const showTaskCommentsOnlyForNonSubjectUser = () => {
    if (!isLoggedInUserTheSubjectUser) {
      onItemAction(task.taskId, TaskManagementDropDownMenuArgs.AddComment);
    }
  }

  const showReviewForm = () => {
    onItemAction(task.taskId, TaskManagementDropDownMenuArgs.ReviewTask);
  }

  return (
    <>
      {/* Journey Display, and employee comments are required */}
      {formType === "JOURNEY" && !details.skipEmployeeRequiredComments && (
        <div className={cx(
          "flex flex-row",
          contentClassName
        )}>
          {/* Completed within the journey */}
          {status === "COMPLETED"
            && task.completionOrigin === "JOURNEY-COLLAB-DOC" && (
              <EnforcedCommentsBadge
                text={t("TaskType.EnforceComments.MarkedAsCompleted")}
                icon={faCheck}
                backgroundColourClassName="bg-green-200/50"
                textClassName="text-green-600 cursor-pointer"
                onClick={showTaskDetails}
              />
            )}
          {/* Not completed OR Completed outside of the journey */}
          {(status !== "COMPLETED"
            || status === "COMPLETED" && task.completionOrigin !== "JOURNEY-COLLAB-DOC") && (
              <>
                {/* Has no comments */}
                {details.ownerCount === 0 && (
                  <EnforcedCommentsBadge
                    text={t("TaskType.EnforceComments.PleaseCommentOnYourProgress")}
                    icon={faComments}
                    backgroundColourClassName="bg-red-200/50"
                    textClassName="text-red-600 cursor-pointer"
                    onClick={showTaskComments}
                  />
                )}
                {/* Has added comments */}
                {details.ownerCount > 0 && (
                  <EnforcedCommentsBadge
                    text={details.ownerCount === 1
                      ? t("TaskType.EnforceComments.YouAddedAComment")
                      : t("TaskType.EnforceComments.YouAddedXComments", {
                        count: details.ownerCount
                      })}
                    icon={faComments}
                    backgroundColourClassName="bg-green-200/50"
                    textClassName="text-green-600 cursor-pointer"
                    onClick={showTaskComments}
                  />
                )}
              </>
            )}
        </div>
      )}

      {/* Collab Doc Display */}
      {formType === "COLLAB-DOC" && (
        <div className={cx(
          "flex flex-row",
          contentClassName
        )}>
          {/* Need to complete/cancel the task */}
          {task.status === "ACTIVE" && details.skipEmployeeRequiredComments && (
            <EnforcedCommentsBadge
              text={t("TaskType.EnforcedComments.PleaseCancelOrComplete", {
                taskType: advancedTaskHelper.ToLowerCase(taskType.singularNameTranslationKeyIdentifier)
              })}
              icon={faWarning}
              backgroundColourClassName="bg-red-200/50"
              textClassName="text-red-600"
            />
          )}

          {/* Owner Badge */}
          <div>
            {/* Completed */}
            {status === "COMPLETED" && (
              <EnforcedCommentsBadge
                text={t("TaskType.EnforceComments.MarkedAsCompleted")}
                icon={faCheck}
                backgroundColourClassName="bg-green-200/50"
                textClassName="text-green-600 cursor-pointer"
                onClick={showTaskDetails}
              />
            )}
            {/* Not completed - Not in Review Mode/skipEmployeeRequiredComments */}
            {status !== "COMPLETED"
              && !details.skipEmployeeRequiredComments
              && (
                <>
                  {/* Has no comments */}
                  {details.ownerCount === 0 && (
                    <EnforcedCommentsBadge
                      text={isLoggedInUserTheSubjectUser
                        ? t("TaskType.EnforceComments.PleaseCommentOnYourProgress")
                        : t("TaskType.EnforceComments.PleaseCommentOnXProgress", {
                          name: subjectUsersName
                        })}
                      icon={faComments}
                      backgroundColourClassName="bg-red-200/50"
                      textClassName="text-red-600 cursor-pointer"
                      onClick={showTaskComments}
                    />
                  )}
                  {/* Has added comments */}
                  {details.ownerCount > 0 && (
                    <EnforcedCommentsBadge
                      text={isLoggedInUserTheSubjectUser
                        ? details.ownerCount === 1
                          ? t("TaskType.EnforceComments.YouAddedAComment")
                          : t("TaskType.EnforceComments.YouAddedXComments", {
                            count: details.ownerCount
                          })
                        : details.ownerCount === 1
                          ? t("TaskType.EnforceComments.XAddedAComment", {
                            name: subjectUsersName
                          })
                          : t("TaskType.EnforceComments.XAddedXComments", {
                            name: subjectUsersName,
                            count: details.ownerCount
                          })}
                      icon={faComments}
                      backgroundColourClassName="bg-green-200/50"
                      textClassName="text-green-600 cursor-pointer"
                      onClick={showTaskComments}
                    />
                  )}
                </>
              )}
          </div>
          {/* ManagerRole Badge */}
          <>
            {status === "COMPLETED"
              && (
                <>
                  {/* Manager hasn't provided a comment */}
                  {!task.enforcedComments.areManagerRoleCountConditionsSatisfied && (
                    <div>
                      <EnforcedCommentsBadge
                        text={isLoggedInUserTheSubjectUser
                          ? details.skipEmployeeRequiredComments
                            ? t("TaskType.EnforceComments.ReviewOnly.XNeedsToCommentOnYourProgress", {
                              name: nonSubjectUsersName,
                              taskTypeSingularName: advancedTaskHelper.ToLowerCase(taskType.singularNameTranslationKeyIdentifier)
                            })
                            : t("TaskType.EnforceComments.XNeedsToCommentOnYourProgress", {
                              name: nonSubjectUsersName
                            })
                          : details.skipEmployeeRequiredComments
                            ? t("TaskType.EnforceComments.ReviewOnly.PleaseCommentOnNamesProgress", {
                              name: subjectUsersName,
                              taskTypeSingularName: advancedTaskHelper.ToLowerCase(taskType.singularNameTranslationKeyIdentifier)
                            })
                            : t("TaskType.EnforceComments.PleaseCommentOnNamesProgress", {
                              name: subjectUsersName
                            })}
                        icon={faComments}
                        backgroundColourClassName="bg-red-200/50"
                        textClassName={cx(
                          "text-red-600",
                          !isLoggedInUserTheSubjectUser ? "cursor-pointer" : ""
                        )}
                        onClick={showTaskCommentsOnlyForNonSubjectUser}
                      />
                    </div>
                  )}

                  {/* Manager has provided a comment AND there is a review form */}
                  {task.enforcedComments.areManagerRoleCountConditionsSatisfied &&
                    taskType.reviewFormId != null && (
                      <div>
                        {!task.hasReviewFormResponse && (
                          <EnforcedCommentsBadge
                            text={t("TaskType.EnforceComments.YouNeedToReviewThisTask", {
                              task: advancedTaskHelper.ToLowerCase(taskType.singularNameTranslationKeyIdentifier)
                            })}
                            icon={faBell}
                            backgroundColourClassName="bg-red-200/50"
                            textClassName="text-red-600 cursor-pointer"
                            onClick={showReviewForm}
                          />
                        )}

                        {task.hasReviewFormResponse && (
                          <EnforcedCommentsBadge
                            text={
                              task.reviewFormLastModifiedByEmployeeId
                                && task.reviewFormLastModifiedByEmployeeId === loggedInUserId
                                ? details.skipEmployeeRequiredComments
                                  ? t("TaskType.EnforceComments.ReviewOnly.LastReviewedByYou")
                                  : t("TaskType.EnforceComments.LastReviewedByYou")
                                : details.skipEmployeeRequiredComments
                                  ? t("TaskType.EnforceComments.ReviewOnly.LastReviewedByX", {
                                    name: task.reviewFormLastModifiedByEmployeeName
                                  })
                                  : t("TaskType.EnforceComments.LastReviewedByX", {
                                    name: task.reviewFormLastModifiedByEmployeeName
                                  })
                            }
                            icon={faBell}
                            backgroundColourClassName="bg-green-200/50"
                            textClassName="text-green-600 cursor-pointer"
                            onClick={showReviewForm}
                          />
                        )}
                      </div>
                    )}

                  {/* Manager has provided a comment AND there is NO review form */}
                  {task.enforcedComments.areManagerRoleCountConditionsSatisfied &&
                    taskType.reviewFormId == null && (
                      <EnforcedCommentsBadge
                        text={isLoggedInUserTheSubjectUser
                          ? details.managerRoleCount === 1
                            ? t("TaskType.EnforceComments.XAddedAComment", {
                              name: nonSubjectUsersName
                            })
                            : t("TaskType.EnforceComments.XAddedXComments", {
                              name: nonSubjectUsersName,
                              count: details.managerRoleCount
                            })
                          : details.managerRoleCount === 1
                            ? t("TaskType.EnforceComments.YouAddedAComment")
                            : t("TaskType.EnforceComments.YouAddedXComments", {
                              count: details.managerRoleCount
                            })}
                        icon={faComments}
                        backgroundColourClassName="bg-green-200/50"
                        textClassName={cx(
                          "text-green-600",
                          !isLoggedInUserTheSubjectUser ? "cursor-pointer" : ""
                        )}
                        onClick={showTaskComments}
                      />
                    )}
                </>
              )}
            {/* Not Completed - Not in Review Mode/skipEmployeeRequiredComments */}
            {status !== "COMPLETED"
              && !details.skipEmployeeRequiredComments
              && (
                <div>
                  <>
                    {/* Has no comments */}
                    {details.managerRoleCount === 0 && (
                      <EnforcedCommentsBadge
                        text={isLoggedInUserTheSubjectUser
                          ? t("TaskType.EnforceComments.XNeedsToCommentOnYourProgress", {
                            name: nonSubjectUsersName
                          })
                          : t("TaskType.EnforceComments.PleaseCommentOnNamesProgress", {
                            name: subjectUsersName
                          })}
                        icon={faComments}
                        backgroundColourClassName="bg-red-200/50"
                        textClassName={cx(
                          "text-red-600",
                          !isLoggedInUserTheSubjectUser ? "cursor-pointer" : ""
                        )}
                        onClick={showTaskCommentsOnlyForNonSubjectUser}
                      />
                    )}
                    {/* Has added comments */}
                    {details.managerRoleCount > 0 && (
                      <EnforcedCommentsBadge
                        text={isLoggedInUserTheSubjectUser
                          ? details.managerRoleCount === 1
                            ? t("TaskType.EnforceComments.XAddedAComment", {
                              name: nonSubjectUsersName
                            })
                            : t("TaskType.EnforceComments.XAddedXComments", {
                              name: nonSubjectUsersName,
                              count: details.managerRoleCount
                            })
                          : details.managerRoleCount === 1
                            ? t("TaskType.EnforceComments.YouAddedAComment")
                            : t("TaskType.EnforceComments.YouAddedXComments", {
                              count: details.managerRoleCount
                            })}
                        icon={faComments}
                        backgroundColourClassName="bg-green-200/50"
                        textClassName={cx(
                          "text-green-600",
                          !isLoggedInUserTheSubjectUser ? "cursor-pointer" : ""
                        )}
                        onClick={showTaskCommentsOnlyForNonSubjectUser}
                      />
                    )}
                  </>
                </div>
              )}
          </>
        </div>
      )}
    </>
  );
}

export default EnforcedCommentsSection;
