import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import cx from "classnames";

interface DangerAlertProps {
  prefix: String | null;
  message: String | null;
  includeMarginTop?: boolean;
  extraClassNames?: string | undefined;
}

function DangerAlert({
  prefix,
  message,
  includeMarginTop,
  extraClassNames,
}: DangerAlertProps) {
  return (
    <div
      className={cx(
        "bg-rose-50 py-1 px-4 mb-2 text-rose-700 text-sm rounded-sm border-l-2 border-l-rose-800",
        includeMarginTop ? "mt-2" : "",
        extraClassNames
      )}
    >
      <div>
        <div className="fa-layers fa-fw mr-0.5">
          <FontAwesomeIcon icon={faExclamationCircle}></FontAwesomeIcon>
        </div>
        <span className="text-rose-800 font-medium">{prefix}</span> {message}
      </div>
    </div>
  );
}

export default DangerAlert;
