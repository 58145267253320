import React, { useEffect, useState } from "react";
import { MainContainer } from "../components/layout";
import {
  ModalPopup,
  AlertPopup,
  Tabs,
  Tooltip,
  RadioButtonGroup,
  CheckBoxList,
  Popover,
  Slider,
  Switch,
  Avatar,
  Label,
  AutoComplete,
  HtmlEditor,
  NumberInput,
  ColourPicker,
  SwitchAlternative,
  DateInput,
} from "../components/common";
import { TabDetails } from "../components/common/Tabs";
import { BaseSelectableItem } from "../types/forms";
import { AutoCompleteSelectedValue, KeyValuePair } from "../types/generic";
import AppContext from "../state/AppContext";
import produce from "immer";
import MultipleChoiceOptionNumericId from "../types/forms/MultipleChoiceOptions";
import SmartTextArea from "../components/common/SmartTextArea";

function ComponentsDemo() {
  const appContext = React.useContext(AppContext);

  useEffect(() => {
    appContext.setPageTitle("Components");
    appContext.setShowPageTitleAccent(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /* Modal */
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalDummyText, setModalDummyText] = useState("");

  const onModalClickPrimary = () => {
    alert("Text was: " + modalDummyText + ". Popup will now close");
    setModalIsOpen(false);
    setModalDummyText("");
  };

  const onModalClickSecondary = () => {
    setModalDummyText("");
  };

  /* Alert */
  const [oneButtonAlertIsOpen, setOneButtonAlertIsOpen] = useState(false);
  const [twoButtonAlertIsOpen, setTwoButtonAlertIsOpen] = useState(false);

  const alertPrimaryBtnClicked = () => {
    alert("Primary button clicked");
  };

  const alertSecondaryBtnClicked = () => {
    alert("Secondary button clicked");
  };

  /* Tabs */
  const demoTabs: Array<TabDetails> = [
    {
      title: "Tab One",
      content: <span>This is the content of tab one</span>,
      displayAlertIcon: false,
    },
    {
      title: "Tab Two",
      content: (
        <span className="text-blue-600">This is the content of tab two</span>
      ),
      displayAlertIcon: true,
    },
    {
      title: "Tab Three",
      content: (
        <span className="text-orange-600">
          This is the content of tab three
        </span>
      ),
      displayAlertIcon: false,
    },
    {
      title: "Tab Four",
      content: (
        <span className="text-red-600">This is the content of tab four</span>
      ),
      displayAlertIcon: false,
    },
    {
      title: "Tab Five",
      content: (
        <span className="text-green-600">This is the content of tab five</span>
      ),
      displayAlertIcon: true,
    },
  ];

  /* Popover */
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);

  /* Radio Button Group */
  const [radioGroupValues, setRadioGroupValues] = useState<
    Array<MultipleChoiceOptionNumericId>
  >([
    {
      text: "Apple",
      value: "apple",
      optionId: 1,
      isSelected: false,
      allowCustomText: false,
    },
    {
      text: "Orange",
      value: "orange",
      optionId: 2,
      isSelected: false,
      allowCustomText: false,
    },
    {
      text: "Pear",
      value: "pear",
      optionId: 3,
      isSelected: false,
      allowCustomText: false,
    },
    {
      text: "Strawberry",
      value: "strawberry",
      optionId: 4,
      isSelected: false,
      allowCustomText: false,
    },
  ]);

  const submitRadioButtonGroup = () => {
    const selectedValue = radioGroupValues.find((x) => x.isSelected)?.value;
    alert("You selected: " + (selectedValue ? selectedValue : "nothing"));
  };

  /* Check Box Lists */

  const [checkBoxListValues, setCheckBoxListValues] = useState<
    Array<MultipleChoiceOptionNumericId>
  >([
    {
      text: "Apple",
      value: "apple",
      isSelected: false,
      optionId: 1,
      allowCustomText: false,
    },
    {
      text: "Orange",
      value: "orange",
      isSelected: false,
      optionId: 2,
      allowCustomText: false,
    },
    {
      text: "Pear",
      value: "pear",
      isSelected: false,
      optionId: 3,
      allowCustomText: false,
    },
    {
      text: "Strawberry",
      value: "strawberry",
      isSelected: false,
      optionId: 4,
      allowCustomText: false,
    },
  ]);

  const submitCheckBoxList = () => {
    const selectedValues = checkBoxListValues.filter(
      (x) => x.isSelected === true
    ).length;
    alert("You selected " + selectedValues + " items");
  };

  /* Slider */

  const [sliderValues, setSliderValues] = useState<
    MultipleChoiceOptionNumericId[]
  >([
    {
      isSelected: false,
      optionId: 1,
      text: "Not at all",
      value: 0,
      allowCustomText: false,
    },
    {
      isSelected: false,
      optionId: 2,
      text: "",
      value: 1,
      allowCustomText: false,
    },
    {
      isSelected: false,
      optionId: 3,
      text: "",
      value: 2,
      allowCustomText: false,
    },
    {
      isSelected: false,
      optionId: 4,
      text: "",
      value: 3,
      allowCustomText: false,
    },
    {
      isSelected: false,
      optionId: 5,
      text: "",
      value: 4,
      allowCustomText: false,
    },
    {
      isSelected: false,
      optionId: 6,
      text: "",
      value: 5,
      allowCustomText: false,
    },
    {
      isSelected: false,
      optionId: 7,
      text: "",
      value: 6,
      allowCustomText: false,
    },
    {
      isSelected: false,
      optionId: 8,
      text: "",
      value: 7,
      allowCustomText: false,
    },
    {
      isSelected: false,
      optionId: 9,
      text: "",
      value: 8,
      allowCustomText: false,
    },
    {
      isSelected: false,
      optionId: 10,
      text: "",
      value: 9,
      allowCustomText: false,
    },
    {
      isSelected: false,
      optionId: 11,
      text: "Totally",
      value: 10,
      allowCustomText: false,
    },
  ]);

  const [sliderDisplayValue, setSliderDisplayValue] = useState<number>(0);

  const handleSliderValueChange = (items: BaseSelectableItem[]) => {
    const nextState = produce(sliderValues, (draft) => {
      items.forEach((item) => {
        const match = draft.find((x) => x.value === item.value);
        if (match !== undefined) {
          match.isSelected = item.isSelected;
        }
      });
    });
    setSliderValues(nextState);
  };

  useEffect(() => {
    const selectedSliderOption = sliderValues.find((x) => x.isSelected)
      ?.value as number;
    setSliderDisplayValue(selectedSliderOption);
  }, [sliderValues]);

  /* Switch */
  const [switchIsChecked, setSwitchIsChecked] = useState<boolean>(false);

  /* Switch Alternative */
  const [switchAlternativeIsChecked, setSwitchAlternativeIsChecked] =
    useState<boolean>(false);

  /* AutoComplete */

  const [autoCompleteValue, setAutoCompleteValue] = useState<
    string | undefined
  >();
  const autoCompleteSuggestions: KeyValuePair<string, string>[] = [
    { key: "AR", value: "Argentina" },
    { key: "AU", value: "Australia" },
    { key: "BE", value: "Belgium" },
    { key: "CA", value: "Canada" },
    { key: "CN", value: "China" },
    { key: "FR", value: "France" },
    { key: "DE", value: "Germany" },
    { key: "IT", value: "Italy" },
    { key: "UK", value: "United Kingdom" },
    { key: "US", value: "United States of America" },
  ];

  // Events
  const handleAutoCompleteValueSelected = (
    newValue: AutoCompleteSelectedValue | null
  ) => {
    if (newValue && newValue.isCustomValue) {
      setAutoCompleteValue(newValue.displayText);
    }
  };

  /* Html Editor */
  const [htmlEditorValue, setHtmlEditorValue] = useState<string>("");
  const maxWordCount = 500;
  const [introMessageWordCount, setIntroMessageWordCount] = useState<number>(0);

  const handleHtmlEditorChange = (selectedValue: string, wordCount: number) => {
    setHtmlEditorValue(selectedValue);
    setIntroMessageWordCount(wordCount);
  };

  /* Number Input */
  const [numberInputValue, setNumberInputValue] = useState<number>(1);

  /* Colour Picker */
  const [colourPickerValue, setColourPickerValue] = useState<string>("#EEEEEE");

  /* Next thing... */
  const [datePickerValue, setDatePickerValue] = useState<Date | null>(null);

  /* Next thing... */
  const [smartTextAreaValue, setSmartTextAreaValue] = useState<string>("");

  return (
    <MainContainer>
      <div className="my-5">
        <h3 className="text-xl font-bold mb-1">Modal Popup</h3>
        <p className="mb-2">
          The secondary button is optional in the popup. Don't pass in the
          secondary props, and it won't render
        </p>
        <button className="btn-primary" onClick={() => setModalIsOpen(true)}>
          Open Modal
        </button>
        <ModalPopup
          isOpen={modalIsOpen}
          onOpenChange={setModalIsOpen}
          onPrimaryButtonClick={onModalClickPrimary}
          onSecondaryButtonClick={onModalClickSecondary}
          primaryButtonText="Save"
          secondaryButtonText="Reset"
          title="Test Modal"
          titleDescription="You can give the modal a little subtitle, or a description, if you will"
        >
          <p>This is what a modal popup with a nested form looks like</p>
          <input
            type="text"
            onChange={(e) => setModalDummyText(e.target.value)}
            value={modalDummyText}
          />
        </ModalPopup>
      </div>
      <hr />
      <div className="my-5">
        <h3 className="text-xl font-bold mb-1">Alert Popup</h3>
        <p className="mb-2">
          Alerts can have one or two buttons. Don't pass in the secondary props,
          and the secondary button won't render
        </p>
        <button
          className="btn-primary"
          onClick={() => setOneButtonAlertIsOpen(true)}
        >
          Open Alert (single button)
        </button>
        <button
          className="btn-primary"
          onClick={() => setTwoButtonAlertIsOpen(true)}
        >
          Open Alert (dual button)
        </button>
        <AlertPopup
          isOpen={oneButtonAlertIsOpen}
          onOpenChange={setOneButtonAlertIsOpen}
          onPrimaryButtonClick={alertPrimaryBtnClicked}
          primaryButtonText="Guess I've got no choice"
          bodyText="This is irreversible. Only do this if you're sure!"
          title="Take a leap of faith?"
        />
        <AlertPopup
          isOpen={twoButtonAlertIsOpen}
          onOpenChange={setTwoButtonAlertIsOpen}
          onPrimaryButtonClick={alertPrimaryBtnClicked}
          onSecondaryButtonClick={alertSecondaryBtnClicked}
          primaryButtonText="That's fine"
          secondaryButtonText="Nope! Bail"
          bodyText="This is irreversible. Only do this if you're sure!"
          title="Take a leap of faith?"
        />
      </div>
      <hr />
      <div className="my-5">
        <h3 className="text-xl font-bold mb-1">Tooltip</h3>
        <p className="mb-2">
          To view the tooltip, just{" "}
          <Tooltip
            content="This is a tooltip"
            triggerElement={<span className="underline">hover here</span>}
          />{" "}
          and it will appear
        </p>
      </div>
      <hr />
      <div className="my-5">
        <h3 className="text-xl font-bold mb-1">Tabs</h3>
        <div className="p-5 bg-gray-200">
          <Tabs tabs={demoTabs} />
        </div>
      </div>
      <hr />
      <div className="my-5">
        <h3 className="text-xl font-bold mb-1">Radio Buttons</h3>
        <div className="p-5 bg-gray-200">
          <RadioButtonGroup<MultipleChoiceOptionNumericId>
            uniqueFieldName="question-123"
            fieldLabel="What is your favourite of these three fruits?"
            values={radioGroupValues}
            onChange={setRadioGroupValues}
          />
          <button className="btn-primary mt-4" onClick={submitRadioButtonGroup}>
            Submit
          </button>
        </div>
      </div>
      <hr />
      <div className="my-5">
        <h3 className="text-xl font-bold mb-1">Check Boxes</h3>
        <div className="p-5 bg-gray-200">
          <CheckBoxList<MultipleChoiceOptionNumericId>
            displayMode="grid"
            uniqueFieldName="question-234"
            fieldLabel="Which fruits do you eat on a regular basis?"
            values={checkBoxListValues}
            onChange={setCheckBoxListValues}
            selectMinCount={null}
            selectMaxCount={null}
          />
          <button className="btn-primary mt-4" onClick={submitCheckBoxList}>
            Submit
          </button>
        </div>
      </div>
      <hr />
      <div className="my-5">
        <h3 className="text-xl font-bold mb-1">Popover</h3>
        <p className="mb-2">
          There's some left padding on the demo button as the arrow point on the
          popover can move to not be centered if there's not enough room to
          place the content of the popover exactly where it should be. If you
          experience this when implementing, place the popover to the side using
          `placement="right"` etc to try to get around it
        </p>
        <div className="pl-10">
          <Popover
            openState={popoverIsOpen}
            onOpenStateChange={setPopoverIsOpen}
            triggerElement={
              <button className="btn-primary">Click to show popover</button>
            }
            title="Settings"
          >
            <ul>
              <li>Item 1</li>
              <li>Item 2</li>
              <li>Item 3</li>
              <li>Item 4</li>
              <li>Item 5</li>
            </ul>
          </Popover>
        </div>
      </div>
      <hr />
      <div className="my-5">
        <h3 className="text-xl font-bold mb-1">Slider</h3>
        <div className="m-2">
          <Slider
            scaleOptions={sliderValues}
            onChange={handleSliderValueChange}
            mainColourClassName="bg-green-600"
            selectedValueDisplayMode="NUMERIC"
          />
          <div className="my-2 italic text-sm">
            Current value: {sliderDisplayValue}
          </div>
        </div>
      </div>
      <hr />
      <div className="my-5">
        <h3 className="text-xl font-bold mb-1">Switch</h3>
        <div className="m-2">
          <Switch checked={switchIsChecked} onChange={setSwitchIsChecked} />
        </div>
      </div>
      <hr />
      <div className="my-5">
        <h3 className="text-xl font-bold mb-1">Switch Alternative</h3>
        <div className="m-2">
          <SwitchAlternative
            uncheckedText="Off"
            checkedText="On"
            checked={switchAlternativeIsChecked}
            onChange={setSwitchAlternativeIsChecked}
          />
        </div>
      </div>
      <hr />
      <div className="my-5">
        <h3 className="text-xl font-bold mb-1">Avatar</h3>
        <p>
          These can have a size applied to them, will fallback to display user
          initials if the image fails to load (or if `undefined` is passed), and
          can optionally show a status badge
        </p>
        <div className="my-2">
          <span className="px-2">
            <Avatar
              userInitials="LB"
              userFullName="Luke Bradley"
              imageUrl="https://www.some-address-that-doesnt-actually-exist.com/so-the-fallback-will-load-instead"
            />
          </span>
          <span className="px-2">
            <Avatar
              userInitials="TL"
              userFullName="Tom Lake"
              activeStatus="UNKNOWN"
              size={12}
              imageUrl="https://i1.sndcdn.com/avatars-AhoGRN6c9R8xYXwD-arRJIw-t240x240.jpg"
            />
          </span>
          <span className="px-2">
            <Avatar
              userInitials="SM"
              userFullName="Sean McSweeney"
              statusBadgeSize={4}
              size={16}
              activeStatus="INACTIVE"
              imageUrl="https://www.pinkvilla.com/files/styles/amp_metadata_content_image/public/zac_efrons_friend_confirms_his_breakup_with_vanessa_valladares.jpg"
            />
          </span>
          <span className="px-2">
            <Avatar
              userInitials="SY"
              userFullName="Sean Yearsley"
              activeStatus="ACTIVE"
              statusBadgeSize={5}
              size={20}
              imageUrl="https://pbs.twimg.com/profile_images/1084048112044396544/T-JC0gsu_400x400.jpg"
            />
          </span>
          <span className="px-2">
            <Avatar
              userInitials="SK"
              userFullName="Steve Kennaird"
              size={24}
              imageUrl="https://www.biography.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTUzMzQzOTkxMDAwMDgxNzA2/jason-statham-attends-the-press-conference-of-director-f-gary-grays-film-the-fate-of-the-furious-on-march-23-2017-in-beijing-china-photo-by-vcg_vcg-via-getty-images-square.jpg"
            />
          </span>
          <span className="px-2">
            <Avatar
              userInitials="JH"
              userFullName="Jo Harley"
              size={20}
              statusBadgeSize={5}
              activeStatus="ACTIVE"
              imageUrl={undefined}
              initialsTextClassName="text-2xl"
            />
          </span>
          <span className="px-2">
            <Avatar
              userInitials="JS"
              userFullName="Jane Sunley"
              size={16}
              statusBadgeSize={4}
              activeStatus="INACTIVE"
              imageUrl={undefined}
              initialsTextClassName="text-xl"
            />
          </span>
        </div>
      </div>
      <hr />
      <div className="my-5">
        <h3 className="text-xl font-bold mb-1">Label</h3>
        <p>
          An accessible label that we should use instead of a plain `label` tag.
          For more info see the{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.radix-ui.com/docs/primitives/components/label"
          >
            Radix docs
          </a>
        </p>
        <div className="my-2">
          <Label htmlFor="labelDemoInput" className="font-bold">
            First Name:
          </Label>
          <input
            id="labelDemoInput"
            defaultValue="Steve"
            type="text"
            className="py-1 px-2 border border-solid border-gray-400 ml-3"
          />
        </div>
      </div>
      <hr />
      <div className="my-5">
        <h3 className="text-xl font-bold mb-1">AutoComplete</h3>
        <Label text="Select a country:" htmlFor="autocomplete-input" />
        <AutoComplete
          selectedValue={autoCompleteValue}
          onValueSelected={handleAutoCompleteValueSelected}
          valuesList={autoCompleteSuggestions}
          placeholder="Type to search..."
          className="my-2 border"
          inputId="autocomplete-input"
          suggestionPropertyToDisplay="VALUE"
          allowCustomTypedValues={true}
        />
        <div className="mt-2 italic text-sm">
          Selected Value: {autoCompleteValue}
        </div>
      </div>
      <hr />
      <div className="my-5">
        <h3 className="text-xl font-bold mb-1">HTML Editor</h3>
        <p>
          Has two toolbar modes, one for simple formatting only, one for
          advanced (with more buttons). Word count in the bottom right is
          optional, to enable it you pass in the max word count, current word
          count and the onChange handler.
        </p>
        <div className="mt-4 pb-20">
          <Label
            text="Enter some formatted text, go ahead, go crazy, use italic:"
            htmlFor="html-input"
          />
          <HtmlEditor
            inputId="html-input"
            toolbarMode="ADVANCED"
            currentValue={htmlEditorValue}
            onValueChangeWithWordCount={handleHtmlEditorChange}
            maxWordCount={maxWordCount}
            currentWordCount={introMessageWordCount}
          />
        </div>
      </div>
      <hr />
      <div className="my-5">
        <h3 className="text-xl font-bold mb-1">Number Input</h3>
        <p>
          An input for numbers with +/- buttons. You can set a minimum, maximum
          and the steps in between.
        </p>
        <div className="mt-4">
          <Label text="" htmlFor="number-input" />
          <NumberInput
            className="text-base"
            onChange={setNumberInputValue}
            value={numberInputValue}
            minimum={1}
            maximum={100}
            step={1}
            inputId="number-input"
          />
        </div>
      </div>
      <hr />
      <div className="my-5">
        <h3 className="text-xl font-bold mb-1">Colour Picker</h3>
        <div className="mt-4">
          <Label text="" htmlFor="colour-picker-input" />
          <ColourPicker
            onChange={setColourPickerValue}
            value={colourPickerValue}
            inputId="colour-picker-input"
          />
        </div>
      </div>
      <hr />
      <div className="my-5">
        <h3 className="text-xl font-bold mb-1">Date Picker</h3>
        <div className="mt-4">
          <DateInput
            onChange={setDatePickerValue}
            inputId="DatePicker123"
            showValidationErrors={false}
            validationResult={null}
            value={datePickerValue}
            isReadOnly={false}
            showTimeSelect={false}
          />
        </div>
      </div>
      <hr />
      <div className="my-5">
        <h3 className="text-xl font-bold mb-1">
          Smart Text Area (supports pasting in links)
        </h3>
        <div className="mt-4">
          <SmartTextArea
            onChange={setSmartTextAreaValue}
            inputId="SmartTextArea1"
            showValidationErrors={false}
            validationResult={null}
            value={smartTextAreaValue}
            isReadOnly={false}
            className="border-gray-600 border rounded-md"
          />
        </div>
      </div>
      <hr />
    </MainContainer>
  );
}

export default ComponentsDemo;
