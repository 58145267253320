import { useTranslation } from "react-i18next";
import { endOfDay } from "date-fns";
import {
  Badge,
  ColourPicker,
  DateInput,
  GenericDropDownList,
  HtmlEditor,
  Label,
  ModalPopup,
  Switch,
  TextInput,
} from "../../common";
import { useEffect, useRef, useState } from "react";
import { colourHelper, dateHelper } from "../../../helpers";
import { ValidationResult } from "../../../types/forms";
import { ClientFormDto } from "../../../types/dtos/admin/ClientFormDto";
import { useNavigate } from "react-router-dom";
import { SendNowUserDto } from "../../../types/dtos/admin/SendNowUserDto";
import SectionSelection from "./SectionSelector/SectionSelection";
import WarningAlert from "../../alerts/WarningAlert";
import AudienceSelection from "./AudienceSelection/AudienceSelection";
import AudienceTabType from "../../../types/admin/AudienceTabType";
import FullScreenLoader from "../../loaders/FullScreenLoader";
import SendNowUserApiResponseDto from "../../../types/dtos/admin/SendNowUserApiResponseDto";
import SmallLoader from "../../loaders/SmallLoader";
import AppRoutes from "../../../routes/AppRoutes";
import DottedLoader from "../../loaders/DottedLoader";
import PeoplePickerUserDto from "../../../types/dtos/generic/PeoplePickerUserDto";
import SendNowSubmissionFormDto from "../../../types/dtos/admin/submission/SendNowSubmissionFormDto";
import { useAuth } from "react-oidc-context";
import adminApi from "../../../api/dashboard/adminApi";
import { SelectedSectionState } from "../../../state/admin/sentJourneys/SelectedSectionState";
import ValidationWarning from "../../common/ValidationWarning";
import InfoAlert from "../../alerts/InfoAlert";
import MultipleChoiceOptionNumericId from "../../../types/forms/MultipleChoiceOptions";
import TaskManagementReviewDateType from "../../../types/admin/TaskManagementReviewDateType";
import { ApprovalFlowDto } from "../../../types/dtos/admin/ApprovalFlowDto";
import { KeyValuePair } from "../../../types/generic";

interface SendNowFormProps {
  defaultIntroMessage: string;
  defaultThemeColour: string;
  availableSections: ClientFormDto[];
  selectedSections: ClientFormDto[];
  displayApprovalFlows: boolean;
  availableApprovalFlows: ApprovalFlowDto[];
  onUpdateAvailableSections(newState: ClientFormDto[] | undefined): void;
  onUpdateSelectedSections(newState: ClientFormDto[] | undefined): void;
}

function SendNowForm({
  defaultIntroMessage,
  defaultThemeColour,
  availableSections,
  selectedSections,
  displayApprovalFlows,
  availableApprovalFlows,
  onUpdateAvailableSections,
  onUpdateSelectedSections,
}: SendNowFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const admApi = new adminApi(auth.user?.access_token);
  const docTitleRef = useRef(null),
    audienceSelectionRef = useRef(null),
    introMessageRef = useRef(null),
    themeColourRef = useRef(null),
    selectSectionRef = useRef(null);
  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);
  const [showPageLoadingSpinner, setShowPageLoadingSpinner] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [confirmationModalMessage, setConfirmationModalMessage] =
    useState<string>("");
  const [isLoadingUserCount, setIsLoadingUserCount] = useState(false);
  const [confirmationModalUserCount, setConfirmationModalUserCount] = useState<
    number | undefined
  >(undefined);
  const [confirmationModalUsers, setConfirmationModalUsers] =
    useState<SendNowUserDto[]>();
  const [isConfirmationModalUsersLoading, setIsConfirmationModalUsersLoading] =
    useState(false);

  // Doc Title
  const docTitleInput = "doc-title-input";
  const deadlineInput = "deadline-input";
  const [docTitle, setDocTitle] = useState<string>("");
  const [docTitleValidation, setDocTitleValidation] =
    useState<ValidationResult | null>(null);

  const [deadlineValue, setDeadlineValue] = useState<Date | null>(null);
  const [deadlineValidation, setDeadlineValidation] =
    useState<ValidationResult | null>(null);

  const [taskMinimumDate, setTaskMinimumDate] = useState<Date | null>(null);
  const [taskDateType, setTaskDateType] =
    useState<TaskManagementReviewDateType | null>("DATE-CREATED");

  // Check if there are any selected sections that have the hasAdvancedTaskManagementQuestion prop as true
  const hasAdvancedTaskManagementQuestion = selectedSections.some(
    (x) => x.hasAdvancedTaskManagementQuestion
  );

  // Who Send To
  const [selectedAudienceTab, setSelectedAudienceTab] =
    useState<AudienceTabType>();
  const [
    audienceAppraisalLevelTreeChecked,
    setAudienceAppraisalLevelTreeChecked,
  ] = useState<string[]>([]);
  const [
    audienceAppraisalLevelTreeExpanded,
    setAudienceAppraisalLevelTreeExpanded,
  ] = useState<string[]>([]);
  const [audienceLocationTreeChecked, setAudienceLocationTreeChecked] =
    useState<string[]>([]);
  const [audienceLocationTreeExpanded, setAudienceLocationTreeExpanded] =
    useState<string[]>([]);
  const [audienceSpecificUsers, setAudienceSpecificUsers] = useState<
    PeoplePickerUserDto[]
  >([]);
  const [audienceUserIdSwitch, setAudienceUserIdSwitch] =
    useState<boolean>(false);
  const [audienceUserIds, setAudienceUserIds] = useState<string>("");
  const [audienceSelectionValidation, setAudienceSelectionValidation] =
    useState<ValidationResult | null>(null);
  const [audienceExcludeInductions, setAudienceExcludeInductions] =
    useState<boolean>(true);

  // Select Section
  const [selectedSectionState, setSelectedSectionState] =
    useState<SelectedSectionState>({
      validation: null,
      preventUsersThatRecentlyCompleted: false,
      completedInPastNumOfDays: 30,
      selectedPrefillOption: 0,
      prefillOptions: [
        {
          allowCustomText: false,
          isSelected: true,
          optionId: 0,
          text: t("No"),
          value: 0,
        },
        {
          allowCustomText: false,
          isSelected: false,
          optionId: 1,
          text: t("Yes"),
          value: 1,
        },
      ],
    });

  // Intro Message
  const introMessageInputId = "intro-mesage-editor";
  const maxWordCount = 500;
  const [showIntroWarningAlert, setShowIntroWarningAlert] =
    useState<boolean>(false);
  const [introMessage, setIntroMessage] = useState<string>(defaultIntroMessage);
  const [introMessageWordCount, setIntroMessageWordCount] = useState<number>(
    defaultIntroMessage.split(" ").length
  );
  const [introMessageValidation, setIntroMessageValidation] =
    useState<ValidationResult | null>(null);

  // Allow Admins To Send
  const [allowAdminUsersToSend, setAllowAdminUsersToSend] =
    useState<boolean>(true);

  // Theme Colour
  const themeColourInputId = "theme-colour-selector";
  const contrastCheckerFontHexCode = colourHelper.getDefaultTextColour();
  const [themeColour, setThemeColour] = useState<string>(defaultThemeColour);
  const [themeColourValidation, setThemeColourValidation] =
    useState<ValidationResult | null>(null);

  // Approval Flows
  const [approvalFlowDropdownOptions, setApprovalFlowDropdownOptions] =
    useState<KeyValuePair<number, string>[]>([]);
  const [selectedApprovalFlowId, setSelectedApprovalFlowId] =
    useState<string>("1");

  //  |--------------- HOOKS (BEGIN) ---------------|
  useEffect(() => {
    // Set the default approval selected approval flow
    const defaultApprovalFlow = availableApprovalFlows.find((x) => x.isDefault);
    if (defaultApprovalFlow) {
      setSelectedApprovalFlowId(defaultApprovalFlow.id.toString());
    }

    // Set the approval flow dropdown options
    const taskDateTypes = availableApprovalFlows.map((x) => {
      return {
        key: x.id,
        value: x.displayName + " (" + x.helpText + ")",
      };
    });
    setApprovalFlowDropdownOptions(taskDateTypes);
  }, [availableApprovalFlows]);

  useEffect(() => {
    // This is needed as for some reason the defaults given above return as empty strings.
    setIntroMessage(defaultIntroMessage);
    setIntroMessageWordCount(defaultIntroMessage.split(" ").length);
    setThemeColour(defaultThemeColour);
  }, [defaultIntroMessage, defaultThemeColour]);

  useEffect(() => {
    if (showValidationErrors) {
      validateDocTitle();
    }
  }, [docTitle]);

  useEffect(() => {
    if (showValidationErrors) {
      validateDeadline();
    }
  }, [deadlineValue]);

  useEffect(() => {
    if (showValidationErrors) {
      validateAudienceSelection();
    }
  }, [
    selectedAudienceTab,
    audienceAppraisalLevelTreeChecked,
    audienceLocationTreeChecked,
    audienceSpecificUsers,
    audienceUserIdSwitch,
    audienceUserIds,
  ]);

  useEffect(() => {
    if (showValidationErrors) {
      validateSelectedSections();
    }

    if (selectedSections.length > 1) {
      setShowIntroWarningAlert(true);
    } else {
      setShowIntroWarningAlert(false);
    }
  }, [selectedSections]);

  useEffect(() => {
    if (showValidationErrors) {
      validateIntroMessage();
    }
  }, [introMessage]);

  useEffect(() => {
    if (showValidationErrors) {
      validateThemeColour();
    }
  }, [themeColour]);
  //  |--------------- HOOKS (END) ---------------|

  //  |--------------- EVENTS (BEGIN) ---------------|
  const onSendNowClickHandler = () => {
    if (isFormValid()) {
      setConfirmationModalIsOpen(true);
      setIsLoadingUserCount(true);

      if (selectedSections.length === 1) {
        setConfirmationModalMessage(
          t("Pages.Admin.Common.YouAreAboutToSend").replace(
            "%SECTION_NAME%",
            t(selectedSections[0].titleTranslationKey)
          )
        );
      } else {
        setConfirmationModalMessage(
          t("Pages.Admin.Common.YouAreAboutToSendTheFollowing")
        );
      }

      const ids = retrieveAudienceIds();
      if (selectedAudienceTab !== undefined) {
        admApi.getUserCountForSendPageConfirmation(
          selectedAudienceTab,
          audienceUserIdSwitch,
          audienceExcludeInductions,
          ids,
          (json: number) => {
            setConfirmationModalUserCount(json);
            setIsLoadingUserCount(false);
          },
          (error: any) => {
            console.error(error);
          }
        );
      }

      setShowValidationErrors(false);
    } else {
      setShowValidationErrors(true); // Show errors
    }
  };

  const onConfirmationSendClick = () => {
    if (selectedAudienceTab !== undefined) {
      setConfirmationModalIsOpen(false);
      setShowPageLoadingSpinner(true);

      const sendNowFormDto: SendNowSubmissionFormDto = {
        title: docTitle,
        audienceTab: selectedAudienceTab,
        ids: retrieveAudienceIds(),
        sections: selectedSections,
        introMessage: introMessage,
        themeColour: themeColour,
        userIdsSwitchValue: audienceUserIdSwitch,
        excludeInductions: audienceExcludeInductions,
        prefillAnswerOption: selectedSectionState.selectedPrefillOption,
        deadlineDateString:
          dateHelper.convertDateObjectToServerString(deadlineValue),
        allowAdminUsersToSend: allowAdminUsersToSend,
        approvalFlowId: parseInt(selectedApprovalFlowId),
      };

      if (selectedSectionState.preventUsersThatRecentlyCompleted) {
        sendNowFormDto.skipIfCompletedWithin =
          selectedSectionState.completedInPastNumOfDays;
      }

      // Only if a selected section has the question type AND the user has selected a date + type do we
      // want to send these values to the server
      if (
        hasAdvancedTaskManagementQuestion &&
        taskMinimumDate &&
        taskDateType
      ) {
        sendNowFormDto.taskMinimumDateString =
          dateHelper.convertDateObjectToServerString(taskMinimumDate);
        sendNowFormDto.taskDateType = taskDateType;
      }

      admApi.submitSendPageForm(
        sendNowFormDto,
        (json: boolean) => {
          if (json) {
            setConfirmationModalIsOpen(false);
            setShowPageLoadingSpinner(false);
            navigate(AppRoutes.admin.journeys.pathRedirectFromSendAfterSuccess);
          }
        },
        (error: any) => {
          console.error(error);
        }
      );
    }
  };

  const onConfirmationCancelClick = () => {
    setConfirmationModalIsOpen(false);
    setConfirmationModalUserCount(undefined);
    setConfirmationModalUsers(undefined);
    setConfirmationModalMessage("");
    setIsConfirmationModalUsersLoading(false);
  };

  const onConfirmationModalLoadUsers = () => {
    if (confirmationModalUsers === undefined) {
      setIsConfirmationModalUsersLoading(true);

      const ids = retrieveAudienceIds();
      if (selectedAudienceTab !== undefined) {
        admApi.getUsersForSendPageConfirmation(
          selectedAudienceTab,
          audienceUserIdSwitch,
          audienceExcludeInductions,
          ids,
          (json: SendNowUserApiResponseDto) => {
            setConfirmationModalUsers(json.users);
            setIsConfirmationModalUsersLoading(false);
          },
          (error: any) => {
            console.error(error);
          }
        );
      }
    }
  };

  const handleHtmlEditorChange = (selectedValue: string, wordCount: number) => {
    setIntroMessage(selectedValue);
    setIntroMessageWordCount(wordCount);
  };

  const handleTogglePreventUsersThatRecentlyCompleted = (newValue: boolean) => {
    setSelectedSectionState({
      ...selectedSectionState,
      preventUsersThatRecentlyCompleted: newValue,
    });
  };

  const handleToggleAllowAdminUsersToSend = (newValue: boolean) => {
    setAllowAdminUsersToSend(newValue);
  };

  const onSelectedPrefillOptionChange = (
    newSelectedValue: number,
    newOptionsValue: MultipleChoiceOptionNumericId[]
  ) => {
    setSelectedSectionState({
      ...selectedSectionState,
      selectedPrefillOption: newSelectedValue,
      prefillOptions: newOptionsValue,
    });
  };

  const handleCompletedInPastNumOfDaysChange = (selectedValue: number) => {
    if (selectedValue !== null) {
      setSelectedSectionState({
        ...selectedSectionState,
        completedInPastNumOfDays: selectedValue,
      });
    }
  };

  function retrieveAudienceIds(): string[] {
    let ids: string[];

    switch (selectedAudienceTab) {
      case "APPRAISAL-LEVEL":
        ids = [...audienceAppraisalLevelTreeChecked];
        break;
      case "LOCATION":
        ids = [...audienceLocationTreeChecked];
        break;
      case "SPECIFIC-USER":
        ids = [...audienceSpecificUsers].map((x) => x.userId.toString());
        break;
      case "USER-IDS":
        const userIds = audienceUserIds.split(/\n/);
        ids = [...userIds];
        break;
      case "EVERYONE":
      default:
        ids = [];
        break;
    }

    return ids;
  }

  const onApprovalFlowChange = (selectedValue: string) => {
    setSelectedApprovalFlowId(selectedValue);
  };
  //  |--------------- EVENTS (END) ---------------|

  //  |------------- VALIDATION (BEGIN) -------------|
  const isFormValid = (): boolean => {
    const docTitleValidationResult = validateDocTitle();
    const deadlineIsValid = validateDeadline();
    const audienceSelectionValidationResult = validateAudienceSelection();
    const selectedSectionValidationResult = validateSelectedSections();
    const introMessageValidationResult = validateIntroMessage();
    const themeColourValidationResult = validateThemeColour();

    // Scroll to the first element that has an invalid message
    if (!docTitleValidationResult.isValid || !deadlineIsValid.isValid) {
      // @ts-ignore
      docTitleRef.current.scrollIntoView();
    } else if (!audienceSelectionValidationResult.isValid) {
      // @ts-ignore
      audienceSelectionRef.current.scrollIntoView();
    } else if (!selectedSectionValidationResult.isValid) {
      // @ts-ignore
      selectSectionRef.current.scrollIntoView();
    } else if (!introMessageValidationResult.isValid) {
      // @ts-ignore
      introMessageRef.current.scrollIntoView();
    } else if (!themeColourValidationResult.isValid) {
      // @ts-ignore
      themeColourRef.current.scrollIntoView();
    }

    return (
      docTitleValidationResult.isValid &&
      audienceSelectionValidationResult.isValid &&
      selectedSectionValidationResult.isValid &&
      introMessageValidationResult.isValid &&
      themeColourValidationResult.isValid
    );
  };

  const validateDocTitle = (): ValidationResult => {
    let validationResult: ValidationResult;

    if (docTitle.length === 0) {
      validationResult = new ValidationResult(false, [
        { errorType: "REQUIRED" },
      ]);
    } else {
      validationResult = new ValidationResult(true);
    }

    setDocTitleValidation(validationResult);
    return validationResult;
  };

  const validateDeadline = (): ValidationResult => {
    let validationResult = new ValidationResult(true);

    if (deadlineValue) {
      // Check if the deadline is in the past
      const now = new Date();
      if (deadlineValue < endOfDay(now)) {
        validationResult = new ValidationResult(false, [
          { errorType: "INVALID-DATE" },
        ]);
      }
    }

    setDeadlineValidation(validationResult);
    return validationResult;
  };

  const validateAudienceSelection = (): ValidationResult => {
    let validationResult: ValidationResult;

    if (selectedAudienceTab === undefined) {
      validationResult = new ValidationResult(false, [
        { errorType: "REQUIRED" },
      ]);
    } else if (
      selectedAudienceTab === "APPRAISAL-LEVEL" &&
      audienceAppraisalLevelTreeChecked.length === 0
    ) {
      validationResult = new ValidationResult(false, [
        { errorType: "MAX", min: 1 },
      ]);
    } else if (
      selectedAudienceTab === "LOCATION" &&
      audienceLocationTreeChecked.length === 0
    ) {
      validationResult = new ValidationResult(false, [
        { errorType: "MAX", min: 1 },
      ]);
    } else if (
      selectedAudienceTab === "SPECIFIC-USER" &&
      audienceSpecificUsers.length === 0
    ) {
      validationResult = new ValidationResult(false, [
        { errorType: "MAX", min: 1 },
      ]);
    } else if (selectedAudienceTab === "USER-IDS") {
      var userIds = audienceUserIds.split(/\n/);

      if (userIds.length === 1 && userIds[0] === "") {
        validationResult = new ValidationResult(false, [
          { errorType: "MAX", min: 1 },
        ]);
      } else {
        // If System User Ids is selected, we need to ensure all given userIds are numerical
        // @ts-ignore
        if (!audienceUserIdSwitch && userIds.some(isNaN)) {
          validationResult = new ValidationResult(false, [
            { errorType: "ILLEGAL-CHARACTER" },
          ]);
        } else {
          // Employee Number (External Reference) doesn't need the same validation as system ids, as possible they can contain numbers
          validationResult = new ValidationResult(true);
        }
      }
    } else {
      validationResult = new ValidationResult(true);
    }

    setAudienceSelectionValidation(validationResult);
    return validationResult;
  };

  const validateSelectedSections = (): ValidationResult => {
    let validationResult: ValidationResult;

    if (selectedSections.length === 0) {
      validationResult = new ValidationResult(false, [
        { errorType: "ATLEAST-ONE-SECTION" },
      ]);
    } else {
      validationResult = new ValidationResult(true);
    }

    setSelectedSectionState({
      ...selectedSectionState,
      validation: validationResult,
    });
    return validationResult;
  };

  const validateIntroMessage = (): ValidationResult => {
    let validationResult: ValidationResult;

    if (introMessageWordCount === 0) {
      validationResult = new ValidationResult(false, [
        { errorType: "REQUIRED" },
      ]);
    } else if (introMessageWordCount > maxWordCount) {
      validationResult = new ValidationResult(false, [
        {
          errorType: "TOO-LONG",
          max: maxWordCount,
          current: introMessageWordCount,
        },
      ]);
    } else {
      validationResult = new ValidationResult(true);
    }

    setIntroMessageValidation(validationResult);
    return validationResult;
  };

  //Allow hex colours to pass the accessibility check
  const allowableThemeColorOverrides = [
    //Buzzworks
    "#28AF9B",
  ];

  const validateThemeColour = (): ValidationResult => {
    let validationResult: ValidationResult;

    if (themeColour === "#" || themeColour.length === 0) {
      validationResult = new ValidationResult(false, [
        { errorType: "REQUIRED" },
      ]);
    } else if (!colourHelper.isValidHex(themeColour)) {
      validationResult = new ValidationResult(false, [
        { errorType: "INVALID-HEX" },
      ]);
    } else if (
      !colourHelper.doesColourPassContrastCheck(
        contrastCheckerFontHexCode,
        themeColour
      ) &&
      !allowableThemeColorOverrides.includes(themeColour.toUpperCase())
    ) {
      validationResult = new ValidationResult(false, [
        { errorType: "ACCESSIBILITY-ISSUE" },
      ]);
    } else {
      validationResult = new ValidationResult(true);
    }

    setThemeColourValidation(validationResult);
    return validationResult;
  };
  //  |------------- VALIDATION (END) -------------|

  return (
    <>
      {showPageLoadingSpinner && <FullScreenLoader />}
      {!showPageLoadingSpinner && (
        <>
          <div>
            <div ref={docTitleRef} className="mb-2 pt-4">
              <Label
                htmlFor={docTitleInput}
                text={t("Pages.SendNow.Fields.DocTitle")}
              />
              <TextInput
                inputId={docTitleInput}
                value={docTitle}
                onChange={setDocTitle}
                placeholder={t("Pages.SendNow.Placeholders.DocTitle")}
                className="w-full"
                maxLength={50}
                showValidationErrors={showValidationErrors}
                validationResult={docTitleValidation}
              />
            </div>
            <div className="mb-4 pt-4">
              <div>
                <Label htmlFor={deadlineInput} text={t("Common.Deadline")} />
                <Badge text={t("Common.Optional")} classNames="text-gray-600" />
              </div>
              <div className="my-1">
                <InfoAlert
                  message={t("Pages.Admin.Common.DeadlineInfoWarning")}
                  prefix={null}
                />
              </div>
              {showValidationErrors && (
                <ValidationWarning
                  errors={deadlineValidation ? deadlineValidation.errors : []}
                  isValid={
                    deadlineValidation ? deadlineValidation.isValid : true
                  }
                />
              )}
              <div className="inline-block max-w-[200px]">
                <DateInput
                  inputId={deadlineInput}
                  value={deadlineValue}
                  onChange={setDeadlineValue}
                  allowDatesInPast={false}
                  className="rounded-md"
                  isClearable={true}
                  placeholder={t("Common.Optional")}
                />
              </div>
            </div>
            <hr />
            <div ref={audienceSelectionRef} className="mb-4 pt-4">
              <Label text={t("Pages.SendNow.Fields.WhoDoYouWantToSend")} />
              <AudienceSelection
                onAudienceChange={setSelectedAudienceTab}
                appraisalLevelTreeChecked={audienceAppraisalLevelTreeChecked}
                appraisalLevelTreeExpanded={audienceAppraisalLevelTreeExpanded}
                onAppraisalLevelTreeChecked={
                  setAudienceAppraisalLevelTreeChecked
                }
                onAppraisalLevelTreeExpanded={
                  setAudienceAppraisalLevelTreeExpanded
                }
                locationTreeChecked={audienceLocationTreeChecked}
                locationTreeExpanded={audienceLocationTreeExpanded}
                onLocationTreeChecked={setAudienceLocationTreeChecked}
                onLocationTreeExpanded={setAudienceLocationTreeExpanded}
                onSpecificUserChange={setAudienceSpecificUsers}
                specificUserSelectedUsers={audienceSpecificUsers}
                userIdsTextAreaValue={audienceUserIds}
                userIdSwitch={audienceUserIdSwitch}
                onUserIdsTextAreaValueChange={setAudienceUserIds}
                onUserIdsSwitchChange={setAudienceUserIdSwitch}
                excludeInductions={audienceExcludeInductions}
                onExcludeInductionsChange={setAudienceExcludeInductions}
                showValidationErrors={showValidationErrors}
                validationResult={audienceSelectionValidation}
              />
            </div>
            <hr />
            <div ref={selectSectionRef} className="mb-4 pt-4">
              <div className="pb-2">
                <Label text={t("Pages.SendNow.Fields.WhichSections")} />
              </div>
              <SectionSelection
                availableSections={availableSections}
                selectedSections={selectedSections}
                preventUsersThatRecentlyCompleted={
                  selectedSectionState.preventUsersThatRecentlyCompleted
                }
                completedInPastNumOfDays={
                  selectedSectionState.completedInPastNumOfDays
                }
                prefillOptions={selectedSectionState.prefillOptions}
                displayTaskManagementFields={hasAdvancedTaskManagementQuestion}
                taskMinimumDate={taskMinimumDate}
                taskDateType={taskDateType}
                onUpdateAvailableSections={onUpdateAvailableSections}
                onUpdateSelectedSections={onUpdateSelectedSections}
                onTogglePreventUsersThatRecentlyCompleted={
                  handleTogglePreventUsersThatRecentlyCompleted
                }
                onNumOfDaysChange={handleCompletedInPastNumOfDaysChange}
                onSelectedPrefillOptionChange={onSelectedPrefillOptionChange}
                onTaskMinimumDateChange={setTaskMinimumDate}
                onTaskDateTypeChange={setTaskDateType}
                showValidationErrors={showValidationErrors}
                validationResult={selectedSectionState.validation}
              />
            </div>
            <hr />
            <div ref={introMessageRef} className="mb-2 pt-4">
              <Label
                htmlFor={introMessageInputId}
                text={t("Pages.SendNow.Fields.IntroMessage")}
              />
              {showIntroWarningAlert && (
                <WarningAlert
                  prefix=""
                  message={t(
                    "Common.Validation.Warning.ReviewAndAmendIntroMessage"
                  )}
                />
              )}
              <HtmlEditor
                inputId={introMessageInputId}
                toolbarMode="ADVANCED"
                currentValue={introMessage}
                onValueChangeWithWordCount={handleHtmlEditorChange}
                showValidationErrors={showValidationErrors}
                validationResult={introMessageValidation}
                currentWordCount={introMessageWordCount}
                maxWordCount={maxWordCount}
              />
            </div>
            <div className="mb-2 pt-2">
              <div className="flex flex-row gap-2">
                <div className="flex-initial">
                  <Switch
                    checked={allowAdminUsersToSend}
                    onChange={handleToggleAllowAdminUsersToSend}
                  />
                </div>
                <Label
                  text={t("Pages.Admin.SendNow.AllowSendingOfJourney")}
                  className="flex-grow"
                />
              </div>
              <div className="my-1 mt-2">
                <InfoAlert
                  message={t(
                    "Pages.Admin.SendNow.AllowSendingOfJourneyTooltip"
                  )}
                  prefix={null}
                />
              </div>
            </div>
            <div ref={themeColourRef} className="mb-2 mt-4">
              <Label
                htmlFor={themeColourInputId}
                text={t("Pages.Admin.Common.ThemeColour")}
              />
              <ColourPicker
                inputId={themeColourInputId}
                value={themeColour}
                fontColour={contrastCheckerFontHexCode}
                onChange={setThemeColour}
                showValidationErrors={showValidationErrors}
                validationResult={themeColourValidation}
              />
            </div>
            {displayApprovalFlows && approvalFlowDropdownOptions && (
              <div ref={themeColourRef} className="mb-2 mt-4">
                <Label
                  htmlFor={themeColourInputId}
                  text={t("Pages.SendNow.Fields.ApprovalFlows")}
                />
                <GenericDropDownList
                  currentValue={selectedApprovalFlowId}
                  items={approvalFlowDropdownOptions}
                  onChange={onApprovalFlowChange}
                  className="block w-full"
                  isReadOnly={false}
                  includeSelectOption={false}
                  applyBorder={true}
                />
              </div>
            )}

            <div className="flex flex-row py-3">
              <div className="grow">
                <button className="btn-primary" onClick={onSendNowClickHandler}>
                  {t("Pages.SendNow.Buttons.Send")}
                </button>
              </div>
            </div>
          </div>

          <ModalPopup
            isOpen={confirmationModalIsOpen}
            onOpenChange={setConfirmationModalIsOpen}
            onPrimaryButtonClick={onConfirmationSendClick}
            onSecondaryButtonClick={onConfirmationCancelClick}
            primaryButtonText={t("Common.YesSend")}
            secondaryButtonText={t("Common.NoCancel")}
            title={t("Pages.Admin.Common.AreYouSure")}
            isPrimaryButtonDisabled={
              confirmationModalUserCount === undefined ||
              confirmationModalUserCount === 0
            }
          >
            <p>
              {confirmationModalMessage}
              {isLoadingUserCount && (
                <span className="inline-block pl-1">
                  <DottedLoader />
                </span>
              )}
              {!isLoadingUserCount && (
                <span
                  className="underline font-bold cursor-pointer pl-1"
                  onClick={() => onConfirmationModalLoadUsers()}
                >
                  {confirmationModalUserCount}{" "}
                  {confirmationModalUserCount === 1
                    ? t("Common.User_LowerCase")
                    : t("Common.Users_LowerCase")}
                </span>
              )}
            </p>
            {selectedSections.length > 1 && (
              <ul>
                {selectedSections.map((ss) => {
                  return <li key={ss.id}>- {t(ss.titleTranslationKey)}</li>;
                })}
              </ul>
            )}
            {isConfirmationModalUsersLoading && (
              <div className="pt-2">
                <SmallLoader />
                <p className="text-center pt-1 text-[#959595] text-sm">
                  {t("Common.LoadingUsers")}...
                </p>
              </div>
            )}
            {!isConfirmationModalUsersLoading &&
              confirmationModalUsers !== undefined && (
                <div className="snap-y overflow-scroll overflow-x-hidden mb-4 max-h-60 border border-gray-200">
                  {confirmationModalUsers.map((user, ix) => (
                    <div className="pl-10 py-1.5 border-b border-b-gray-200 flex flex-row">
                      <p className="w-2/4">{user.name}</p>
                      <p className="w-2/4 text-sm text-right pr-6">
                        ({t(user.jobTitleTranslationKeyIdentifier)})
                      </p>
                    </div>
                  ))}
                </div>
              )}
            <p>{t("Pages.Admin.Common.AreYouSureProceed")}</p>
            {confirmationModalUserCount === 0 && (
              <div>
                <WarningAlert
                  prefix=""
                  message={t("Pages.SendNow.Warnings.NoUsersWouldReceiveThis")}
                />
              </div>
            )}
          </ModalPopup>
        </>
      )}
    </>
  );
}

export default SendNowForm;
