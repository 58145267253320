import { t } from "i18next";
import { JourneyExportRequestTabType } from "../types/analytics/custom-exports/JourneyExportRequestDetails";

const customExportTabHelper = {
  getTabTranslation: (
    tabType: JourneyExportRequestTabType,
    advancedTaskTypeName?: string
  ): string => {
    switch (tabType) {
      case "GENERIC":
        return t("Pages.Analytics.Exports.CustomPopup.TabTypes.Generic");
      case "BEHAVIOURS":
        return t("Pages.Analytics.Exports.CustomPopup.TabTypes.Behaviour");
      case "LEARNING":
        return t("Pages.Analytics.Exports.CustomPopup.TabTypes.Learning");
      case "CLASSIC-GOAL-SETTING":
        return t("Pages.Analytics.Exports.CustomPopup.TabTypes.ClassicGoalSetting");
      case "CLASSIC-GOAL-REVIEW":
        return t("Pages.Analytics.Exports.CustomPopup.TabTypes.ClassicGoalReview");
      case "ADVANCED-TASK-REVIEW":
        return t("Pages.Analytics.Exports.CustomPopup.TabTypes.TaskTypeReview", {
          taskType: advancedTaskTypeName
        });
      default:
        return "";
    }
  },
};

export default customExportTabHelper;
