import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { useContext } from "react";
import { Badge } from "../../../components/common";
import ExportableJourneyItem from "../../../types/analytics/custom-exports/ExportableJourneyItem";
import UserContext from "../../../state/UserContext";
import { t } from "i18next";

interface JourneyExportItemProps {
  item: ExportableJourneyItem,
  exportableJourneys: ExportableJourneyItem[];
  onExportClick(title: string, journeyRefs: string[] | undefined): void;
}

function JourneyExportItem({
  item,
  exportableJourneys,
  onExportClick
}: JourneyExportItemProps) {
  // Context/APIs
  const userContext = useContext(UserContext);

  const handleExportClick = (title: string, specificJourneyRef: string | undefined) => {
    // Get the guids to export data for
    // If no specific journey ref is defined, a button has been pressed for a Client Sent Journey,
    // which can have multiple journey refs
    const journeyRefs =
      specificJourneyRef !== undefined
        ? [specificJourneyRef]
        : exportableJourneys.find((x) => x.title === title)?.journeyRefs;

    onExportClick(title, journeyRefs);
  }

  return (
    <div
      key={item.title}
      className="basis-full md:basis-1/2"
    >
      <div className="px-4 p-2 bg-gray-50 flex flex-row w-full items-center rounded-md border border-gray-200">
        <div className="grow">
          <span className="block font-medium primary-text">
            {item.title}
          </span>
          <div>
            {item.journeyType === "CLIENT-SENT-JOURNEY" && (
              <Badge
                text={"Last sent on " + dayjs(item.mostRecentDate).format(userContext.user.i18n.dateFormat.toUpperCase())}
                textColourClassName="text-gray-500"
                marginClassName="mr-2"
              />
            )}
          </div>
        </div>
        <div className="flex-none px-2">
          <button
            className="disabled:cursor-not-allowed px-2 bg-gray-200 rounded py-1 hover:bg-gray-300 text-center"
            onClick={() =>
              item.journeyType === "CLIENT-SENT-JOURNEY"
                ? handleExportClick(item.title, undefined)
                : handleExportClick(item.title, item.journeyRefs[0])
            }
          >
            <FontAwesomeIcon icon={faDownload} size="sm" />
            <span className="ml-1 text-sm">{t("Pages.Analytics.Exports.CustomPopup.Export")}</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default JourneyExportItem;
