import { faGripDotsVertical, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { JourneyExportRequestQuestion } from "../../types/analytics/custom-exports/JourneyExportRequestDetails";
import React from "react";
import cx from "classnames";

interface CustomExportDraggableQuestionProps {
  item: JourneyExportRequestQuestion;
  itemSelected: number;
  dragHandleProps: object;
  commonProps(item: JourneyExportRequestQuestion): void;
}

class CustomExportDraggableQuestion extends React.Component<CustomExportDraggableQuestionProps> {
  constructor(props: any) {
    super(props);
  }
  render() {
    const scale = this.props.itemSelected * 0.05 + 1;
    const shadow = this.props.itemSelected * 15 + 1;
    const dragged = this.props.itemSelected !== 0;

    return (
      <div
        className={cx('item text-sm rounded-md border border-gray-300 bg-white', { dragged })}
        style={{
          transform: `scale(${scale})`,
          boxShadow: dragged ? `rgba(0, 0, 0, 0.3) 0px ${shadow}px ${2 * shadow}px 0px` : '',
        }}
      >
        <div className="p-2 w-full">
          <div className="flex flex-row">
            <div className="basis-11/12">
              <FontAwesomeIcon icon={faGripDotsVertical} className="px-2 dragHandle cursor-pointer" {...this.props.dragHandleProps} ></FontAwesomeIcon>
              <span>{this.props.item.text}</span>
            </div>
            <div className="basis-1/12 text-right pr-2">
              <span className="text-rose-500" onClick={() => this.props.commonProps(this.props.item)}>
                <FontAwesomeIcon className="hover:cursor-pointer" icon={faTrashAlt}></FontAwesomeIcon>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CustomExportDraggableQuestion;
