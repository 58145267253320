import { t } from "i18next";
import { UserContextInterface } from "../state/UserContext";
import { BaseUserDetailsDto } from "../types/dtos/generic";
import { ActorQuestionText, FormQuestion, FormType } from "../types/forms";
import TextToken from "../types/generic/TextToken";
import { get } from "http";

const subjectUserToken: TextToken = "#SUBJECT_NAME#";
const otherParticipantToken: TextToken = "#OTHER_PARTICIPANT_NAME#";
const clientNameToken: TextToken = "#CLIENT#";

export const questionTextHelper = {
  /** Get the translated question text for the logged in user, with the subject name and client name tokens replaced for the appropriate values */
  getQuestionText: function (
    question: FormQuestion,
    subjectUser: BaseUserDetailsDto,
    participants: BaseUserDetailsDto[],
    loggedInUser: UserContextInterface
  ): string {
    if (question.questionType === "BEHAVIOUR") {
      if (question.behaviourOptions) {
        return t(question.behaviourOptions!.behaviour.value);
      }
    }

    return this.getActorText(
      question.questionText,
      subjectUser,
      participants,
      loggedInUser
    );
  },
  /** Get the correct, translated text for the logged in user, with the subject name and client name tokens replaced for the appropriate values */
  getActorText: function (
    actorText: ActorQuestionText,
    subjectUser: BaseUserDetailsDto,
    participants: BaseUserDetailsDto[],
    loggedInUser: UserContextInterface
  ) {
    if (!participants || participants.length === 0) {
      return actorText.employee;
    }

    const otherUser = participants.find((x) => x.userId !== subjectUser.userId);

    const untranslatedText =
      subjectUser!.userId === loggedInUser.user.id
        ? actorText.employee
        : actorText.manager;

    let output = t(untranslatedText);
    output = output.replace(subjectUserToken, subjectUser!.firstName);
    output = output.replace(clientNameToken, loggedInUser.user.client.name);

    if (otherUser) {
      output = output.replace(otherParticipantToken, otherUser.firstName);
    }

    return output;
  },
  /** Replace any tokens within the form intro message text */
  replaceTokensInFormIntros: function (
    text: string,
    loggedInUser: UserContextInterface,
    otherUser: BaseUserDetailsDto | null | undefined
  ) {
    let output = text;
    output = output.replace(subjectUserToken, loggedInUser.user.firstName);
    output = output.replace(clientNameToken, loggedInUser.user.client.name);
    if (otherUser) {
      output = output.replace(otherParticipantToken, otherUser.firstName);
    }
    return output;
  },

  /** Pulls out the header which is merged into the question text via use of a pipe character, if there is one.
   * Note that this is only supported in journeys, as collab docs tend to not need headers,
   * as individual forms within a collab doc have their own headers, whereas journeys don't,
   * they just have the title of the journey as a header throughout, even if there are multiple forms involved.
   */
  getQuestionTextParts: function (
    inputValue: string,
    formType: FormType
  ): QuestionTextParts {
    if (!inputValue || inputValue.trim().length === 0) {
      return {
        header: undefined,
        label: "",
      };
    }
    const questionHeader: string | undefined =
      formType === "JOURNEY" && inputValue.indexOf("|") > 0
        ? inputValue.split("|")[0].trim()
        : undefined;
    const questionLabel: string | undefined = (
      inputValue.indexOf("|") > 0 ? inputValue.split("|")[1] : inputValue
    ).trim();
    return {
      header: questionHeader,
      label: questionLabel,
    };
  },
};

interface QuestionTextParts {
  header: string | undefined;
  label: string;
}

export default questionTextHelper;
