const _focusedJourneyClassName = "focus-journey";

/** A set of helper functions for changing the UI - shouldn't be any business logic in here */
const interactionHelper = {
  deFocusJourney: function () {
    document.body.classList.remove(_focusedJourneyClassName);
  },
  focusJourney: function () {
    document.body.classList.add(_focusedJourneyClassName);
  },
  scrollMainContainerToTop: function () {
    const container = document.getElementById("main-container");
    if (container) {
      container.scroll({
        left: 0,
        top: 0,
        behavior: "smooth",
      });
    }
  },
  scrollToFirstInstanceOfElementByClassName: function (className: string) {
    const element = document.querySelector(`.${className}`);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  },
};

export default interactionHelper;
