
import { FormType } from "../../../../types/forms";
import cx from "classnames";
import { ClientTaskType } from "../../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import TaskManagementConfigDto from "../../../../types/dtos/forms/TaskManagementConfigDto";
import { advancedTaskHelper } from "../../../../helpers";
import { t } from "i18next";

interface EnforcedCommentsValidityBannerProps {
  config: TaskManagementConfigDto;
  taskType: ClientTaskType;
  formType: FormType;
}

function EnforcedCommentsValidityBanner({
  config,
  taskType,
  formType,
}: EnforcedCommentsValidityBannerProps) {
  const taskCount = config.liveAndNewTasks.length;
  const taskName = advancedTaskHelper.ToLowerCase(taskType.pluralNameTranslationKeyIdentifier);
  const areEmployeeCommentsOptional = config.liveAndNewTasks.some(x => x.enforcedComments.skipEmployeeRequiredComments);

  const validTaskCount =
    formType === "JOURNEY"
      // Journey - Only need to take into consideration owner count conditions
      ? config.liveAndNewTasks.filter(x => x.enforcedComments.areOwnerCountConditionsSatisfied).length
      // Collab Doc - Need to check if the taskType has a review form
      : taskType.reviewFormId != null
        // It it does then count newly created in this journet
        // OR count non-completed tasks that satisfy both owner and manager role conditions 
        // OR completed tasks that satisfy manager role conitionds and have a review form response
        ? config.liveAndNewTasks.filter(x =>
          x.isNewlyCreatedInThisJourney
          || x.status !== "COMPLETED" && x.enforcedComments.areOwnerCountConditionsSatisfied && x.enforcedComments.areManagerRoleCountConditionsSatisfied
          || x.status === "COMPLETED" && x.enforcedComments.areManagerRoleCountConditionsSatisfied && x.hasReviewFormResponse
        ).length
        // If it doesn't then we count tasks that satisfy both owner and manager role conditions
        : config.liveAndNewTasks.filter(x => x.enforcedComments.areOwnerCountConditionsSatisfied && x.enforcedComments.areManagerRoleCountConditionsSatisfied).length;

  const isInReviewModeWithEnforcedComments = config.enforceComments === true
    && (config.questionMode == "TASK-REVIEW-AND-CREATION" || config.questionMode == "TASK-REVIEW-ONLY");

  const bannerText = isInReviewModeWithEnforcedComments
    ? t("TaskType.EnforceComments.ReviewOnly.ValidityBannerText", {
      validTaskCount: validTaskCount,
      taskCount: taskCount,
      taskName: taskName
    })
    : t("TaskType.EnforceComments.ValidityBannerText", {
      validTaskCount: validTaskCount,
      taskCount: taskCount,
      taskName: taskName
    });

  return (
    <>
      {!(formType === "JOURNEY" && areEmployeeCommentsOptional) && (
        <p className={cx(
          "text-right mt-1",
          formType === "COLLAB-DOC" ? "text-gray-700" : "text-white"
        )}>
          {bannerText}
        </p>
      )}
    </>
  );
}

export default EnforcedCommentsValidityBanner;
