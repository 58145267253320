import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import DangerAlert from "../alerts/DangerAlert";
import { DateInput, Label, ModalPopup } from "../common";
import JourneyExportRequestDetails, {
  JourneyExportRequestQuestion,
  JourneyExportRequestTab,
  JourneyExportRequestTabType,
} from "../../types/analytics/custom-exports/JourneyExportRequestDetails";
import CustomExportTab from "./CustomExportTab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { faCircleCheck, faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import JourneyExportAvailabileOptions, {
  JourneyExportAvailableQuestionGroup,
} from "../../types/analytics/custom-exports/JourneyExportAvailabileOptions";
import { useAuth } from "react-oidc-context";
import { KeyValuePair } from "../../types/generic";
import customExportTabHelper from "../../helpers/customExportTabHelper";
import ExportDisplayStatus from "../../types/analytics/custom-exports/ExportDisplayStatus";
import IsExportReadyResponseDto from "../../types/exports/IsExportReadyResponseDto";
import { fileHelper } from "../../helpers";
import exportsApi from "../../api/analytics/exportsApi";

const maxNumberOfTabs = 10;

interface CustomExportRequestPopupProps {
  isOpen: boolean;
  journeyTitle: string;
  journeyRefs: string[] | undefined;
  onOpenChange(open: boolean): void;
}

const CustomExportRequestPopup = ({
  isOpen,
  journeyTitle,
  journeyRefs,
  onOpenChange,
}: CustomExportRequestPopupProps) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const api = new exportsApi(auth.user?.access_token);

  const [displayStatus, setDisplayStatus] =
    useState<ExportDisplayStatus>("FORM");
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [isUserTryingToLeave, setIsUserTryingToLeave] =
    useState<boolean>(false);

  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [tabs, setTabs] = useState<JourneyExportRequestTab[]>([]);
  const [availableOptions, setAvailableOptions] =
    useState<JourneyExportAvailabileOptions>();
  const [tabTypes, setTabTypes] = useState<KeyValuePair<string, string>[]>([]);
  const [tabQuestions, setTabQuestions] = useState<
    JourneyExportAvailableQuestionGroup[]
  >([]);

  const [pollForExport, setPollForExport] = useState<boolean>(false);
  const [exportId, setExportId] = useState<string | null>(null);
  const [pollIntervalId, setPollIntervalId] = useState<NodeJS.Timer | null>(
    null
  );
  const pollIntervalIdRef = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (isOpen) {
      // When opened we need to reset the state
      setIsFormDirty(false);
      setIsUserTryingToLeave(false);
      setDateFrom(null);
      setDateTo(null);
      setTabs([getBlankTabDetails(0)]);
      setDisplayStatus("FORM");

      // Load the export available options for tab types and questions
      if (journeyRefs) {
        api.getAvailableOptionsForCustomExport(
          journeyRefs,
          (data: JourneyExportAvailabileOptions) => {
            setAvailableOptions(data);
            formatAvailableDropDownOptions(data);
          },
          (error: any) => {
            console.error(error);
          }
        );
      }
    }

    // If there is an interval running, clear it before opening/closing popup
    if (pollIntervalId) {
      clearInterval(pollIntervalId);
    }
  }, [isOpen]);

  useEffect(() => {
    if (pollForExport == true) {
      // Start polling at 6 second intervals to check if export is ready to download
      var interval = setInterval(() => {
        isExportReadyToDownload();
      }, 6000);
      setPollIntervalId(interval);
      pollIntervalIdRef.current = interval;
    } else {
      if (pollIntervalId) {
        clearInterval(pollIntervalId);
      }
    }
    //Clean up method to remove any intervals before unmounting
    return () => {
      if (pollIntervalIdRef != null && pollIntervalIdRef.current != null) {
        clearInterval(pollIntervalIdRef.current);
      }
    };
  }, [pollForExport]);

  const formatAvailableDropDownOptions = (
    options: JourneyExportAvailabileOptions
  ) => {
    const tabTypes = options.tabTypes.map((tabType) => {
      const tabTypeKey =
        tabType.type === "ADVANCED-TASK-REVIEW" && tabType.advancedTaskTypeId
          ? `${tabType.type}_${tabType.advancedTaskTypeId}`
          : tabType.type;
      return {
        key: tabTypeKey,
        value: t(
          customExportTabHelper.getTabTranslation(
            tabType.type,
            tabType.advancedTaskTypeName
          )
        ),
      };
    });
    setTabTypes(tabTypes);
    setTabQuestions(options.questionGroups);
  };

  const checkFormIsDirtyBeforeClosing = (open: boolean) => {
    // If the form is dirty, we need to prompt the user to confirm before closing
    if (isFormDirty) {
      setIsUserTryingToLeave(true);
    } else {
      // Else we can just close the form
      onOpenChange(open);
    }
  };

  const getBlankTabDetails = (index: number): JourneyExportRequestTab => {
    return {
      name: `Tab-${index + 1}`,
      type: null, //  null as it needs selecting
      advancedTaskTypeId: null,
      sequence: index,
      questions: [],
    };
  };

  const setTabTitle = (index: number, title: string) => {
    const newState = tabs.map((tab, i) => {
      if (i === index) {
        return { ...tab, name: title };
      } else {
        return tab;
      }
    });
    setTabs(newState);
    setIsFormDirty(true);
  };

  const setTabType = (
    index: number,
    type: JourneyExportRequestTabType | null,
    taskTypeId: string | null
  ) => {
    const newState = tabs.map((tab, i) => {
      if (i === index) {
        return {
          ...tab,
          type: type,
          advancedTaskTypeId: taskTypeId,
          questions: [],
        };
      } else {
        return tab;
      }
    });
    setTabs(newState);
    setIsFormDirty(true);
  };

  const addQuestion = (
    tabIndex: number,
    questionId: string,
    questionText: string
  ) => {
    const newState = tabs.map((tab, i) => {
      if (i === tabIndex) {
        const newQuestions = [...tab.questions];
        newQuestions.push({
          id: questionId,
          sequence: newQuestions.length,
          text: questionText,
        });
        return { ...tab, questions: newQuestions };
      } else {
        return tab;
      }
    });
    setTabs(newState);
    setIsFormDirty(true);
  };

  const removeQuestion = (tabIndex: number, questionId: string) => {
    const newState = tabs.map((tab, i) => {
      if (i === tabIndex) {
        const newQuestions = [...tab.questions];
        const questionIndex = newQuestions.findIndex(
          (question) => question.id === questionId
        );
        newQuestions.splice(questionIndex, 1);
        return { ...tab, questions: newQuestions };
      } else {
        return tab;
      }
    });
    setTabs(newState);
    setIsFormDirty(true);
  };

  const updateQuestionSequences = (
    tabIndex: number,
    questions: JourneyExportRequestQuestion[]
  ) => {
    const newState = tabs.map((tab, i) => {
      if (i === tabIndex) {
        const newQuestions = questions.map((question, index) => {
          return { ...question, sequence: index };
        });
        return { ...tab, questions: newQuestions };
      } else {
        return tab;
      }
    });
    setTabs(newState);
    setIsFormDirty(true);
  };

  const addNewBlankTab = () => {
    const newTabs = [...tabs];
    newTabs.push(getBlankTabDetails(newTabs.length));
    setTabs(newTabs);
    setIsFormDirty(true);
  };

  const deleteExistingTab = (index: number) => {
    const newTabs = [...tabs];
    newTabs.splice(index, 1);
    setTabs(newTabs);
    setIsFormDirty(true);
  };

  const onIncreaseTabSequence = (index: number) => {
    const newTabs = [...tabs];
    if (index > 0) {
      const temp = newTabs[index - 1];
      newTabs[index - 1] = newTabs[index];
      newTabs[index - 1].sequence = index - 1;
      newTabs[index] = temp;
      newTabs[index].sequence = index;
      setTabs(newTabs);
      setIsFormDirty(true);
    }
  };

  const onDecreaseTabSequence = (index: number) => {
    const newTabs = [...tabs];
    if (index < newTabs.length - 1) {
      const temp = newTabs[index + 1];
      newTabs[index + 1] = newTabs[index];
      newTabs[index + 1].sequence = index + 1;
      newTabs[index] = temp;
      newTabs[index].sequence = index;
      setTabs(newTabs);
      setIsFormDirty(true);
    }
  };

  const onGenerateButtonClick = () => {
    const request: JourneyExportRequestDetails = {
      journeyReferences: journeyRefs!,
      filters: {
        startDate: dateFrom,
        endDate: dateTo,
      },
      tabs: tabs,
    };

    // Post the request to the API
    api.queueCustomExportForGeneration(
      request,
      (data: string) => {
        // Set the exportId so we can poll for the download
        setExportId(data);
        setDisplayStatus("GENERATING");
        setPollForExport(true);
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  function isExportReadyToDownload() {
    api.isExportReadyToDownload(
      exportId!,
      // onSuccess
      (data: IsExportReadyResponseDto) => {
        switch (data.status) {
          case "READY-TO-DOWNLOAD":
            // Stop the polling and start download of export
            setPollForExport(false);
            downloadExport();
            break;
          case "FAILED":
            // Show error message, stop the polling process
            setDisplayStatus("ERROR");
            setPollForExport(false);
            break;
          default:
            // Do nothing, keep polling.
            break;
        }
      },
      // onError
      (error: any) => {
        console.log("Error checking export", error);
        // Show error message, stop the polling process
        setDisplayStatus("ERROR");
        setPollForExport(false);
      }
    );
  }

  function downloadExport() {
    const filenameWithoutExtension = fileHelper.createFilenameFromString(
      journeyTitle.replace(" ", "-").toLowerCase(),
      true
    );

    api.downloadExport(
      exportId!,
      filenameWithoutExtension,
      () => {
        // onSuccess
        // Show downloaded message and clear dirty flag
        setDisplayStatus("DOWNLOADED");
        setIsFormDirty(false);
      },
      (error: any) => {
        // onError
        setDisplayStatus("ERROR");
      }
    );
  }

  const onReturnToForm = () => {
    setDisplayStatus("FORM");
  };

  const isExportValid = () => {
    if (
      (dateFrom != null && dateTo != null && dateTo < dateFrom) || // To date must be after from date
      tabs.length === 0 || // Needs to be at least one tab
      tabs.some((tab) => tab.name.length === 0) || // All tabs need a name
      tabs.some((tab) => tab.type === null) || // All tabs need a type
      tabs.some((tab) => tab.type === "GENERIC" && tab.questions.length === 0)
    )
      // All generic tabs need at least one question
      return false;

    return true;
  };

  const modalTitle = !isUserTryingToLeave
    ? t(
        "Pages.Analytics.Exports.CustomPopup.CustomiseYourExport.CustomiseYourExport"
      )
    : t("Pages.Analytics.Exports.CustomPopup.AreYouSure");

  return (
    <ModalPopup
      isOpen={isOpen}
      onOpenChange={() => {}}
      title={modalTitle}
      onAlternativeOpenChange={checkFormIsDirtyBeforeClosing}
      showCloseIcon={!isUserTryingToLeave}
      preventInitialAutoFocus
      width={displayStatus === "FORM" ? "NORMAL" : "SMALL"}
    >
      {!isUserTryingToLeave && (
        <>
          {displayStatus === "FORM" && (
            <div>
              <p className="text-sm">
                {t("Pages.Analytics.Exports.CustomPopup.Intro")}
              </p>
              <div className="pt-2 pb-2">
                <Label
                  htmlFor="journey-title"
                  text={
                    t("Pages.Analytics.Exports.CustomPopup.Fields.Journey") +
                    ":"
                  }
                />
                <span id="journey-title" className="pl-1">
                  {journeyTitle}
                </span>
              </div>
              <div className="flex flex-row gap-4 pb-2">
                <div className="flex flex-row">
                  <Label
                    htmlFor="from-date"
                    text={
                      t("Pages.Analytics.Exports.CustomPopup.Fields.DateFrom") +
                      ":"
                    }
                  />
                  <DateInput
                    onChange={setDateFrom}
                    inputId="from-date"
                    value={dateFrom}
                    showTimeSelect={false}
                    placeholder=""
                    allowDatesInPast={true}
                    className="ml-1 !pl-1 !p-0 block w-full cursor-pointer border-0 bg-gray-200 rounded-md focus:outline-0 focus:ring-0"
                    isClearable={true}
                  />
                </div>
                <div className="flex flex-row">
                  <Label
                    htmlFor="to-date"
                    text={
                      t("Pages.Analytics.Exports.CustomPopup.Fields.DateTo") +
                      ":"
                    }
                  />
                  <DateInput
                    onChange={setDateTo}
                    inputId="to-date"
                    value={dateTo}
                    showTimeSelect={false}
                    placeholder=""
                    allowDatesInPast={true}
                    className="ml-1 !pl-1 !p-0 block w-full cursor-pointer border-0 bg-gray-200 rounded-md focus:outline-0 focus:ring-0"
                    isClearable={true}
                  />
                </div>
              </div>
              <div>
                <Label
                  text={
                    t("Pages.Analytics.Exports.CustomPopup.Fields.Tabs") + ":"
                  }
                />
                {tabs.map((tab, index) => (
                  <CustomExportTab
                    key={index}
                    index={index}
                    tab={tab}
                    allowSequenceChange={tabs.length > 1}
                    availableOptions={availableOptions}
                    tabTypeOptions={tabTypes}
                    setTabTitle={setTabTitle}
                    setTabType={setTabType}
                    onDeleteTab={deleteExistingTab}
                    onIncreaseTabSequence={onIncreaseTabSequence}
                    onDecreaseTabSequence={onDecreaseTabSequence}
                    onAddQuestion={addQuestion}
                    onRemoveQuestion={removeQuestion}
                    onQuestionListChange={updateQuestionSequences}
                  />
                ))}
                {tabs.length < maxNumberOfTabs && (
                  <div className="mt-2 px-4 p-2 bg-gray-50 items-center rounded-md border border-gray-200">
                    <div
                      className="hover:font-bold hover:cursor-pointer"
                      onClick={() => addNewBlankTab()}
                    >
                      <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                      <span className="pl-1">
                        {t("Pages.Analytics.Exports.CustomPopup.AddAnotherTab")}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              {/* Generate Button */}
              <div className="text-right mt-2">
                <button
                  className="btn-primary"
                  onClick={onGenerateButtonClick}
                  disabled={!isExportValid()}
                >
                  <span className="ml-1">
                    {t("Pages.Analytics.Exports.CustomPopup.GenerateExport")}
                  </span>
                </button>
              </div>
            </div>
          )}
          {displayStatus === "GENERATING" && (
            <div>
              <div className="mb-2 py-1 px-4 pb-2 bg-blue-50 text-blue-500 rounded-y-md border-l-2 border-l-blue-500">
                <div className="flex items-center justify-center pt-4">
                  <div className="text-center">
                    <div>
                      <FontAwesomeIcon
                        icon={faSpinnerThird}
                        size="4x"
                        spin
                        className="text-blue-500"
                      />
                    </div>
                    <div className="mt-2 font-bold">
                      {t(
                        "Pages.Analytics.Exports.CustomPopup.GeneratingScreen.Subtitle"
                      ) + "..."}
                    </div>
                  </div>
                </div>
                <p className="text-sm mt-2 text-center">
                  {t(
                    "Pages.Analytics.Exports.CustomPopup.GeneratingScreen.InfoOne"
                  )}
                </p>
                <p className="text-sm mt-2 text-center">
                  {t(
                    "Pages.Analytics.Exports.CustomPopup.GeneratingScreen.InfoTwo"
                  )}
                </p>
              </div>
            </div>
          )}
          {displayStatus === "DOWNLOADED" && (
            <div>
              <div className="mb-2 py-1 px-4 pb-2 bg-green-50 text-green-700 rounded-y-md border-l-2 border-l-green-800">
                <div className="flex items-center justify-center pt-4">
                  <div className="text-center">
                    <div>
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        size="4x"
                        className="text-green-700"
                      />
                    </div>
                    <div className="mt-2 font-bold">
                      {t(
                        "Pages.Analytics.Exports.CustomPopup.DownloadedScreen.Subtitle"
                      )}
                    </div>
                  </div>
                </div>
                <p className="text-sm mt-2 text-center">
                  {t(
                    "Pages.Analytics.Exports.CustomPopup.DownloadedScreen.Info"
                  )}{" "}
                  <span
                    className="underline hover:font-bold hover:cursor-pointer"
                    onClick={onReturnToForm}
                  >
                    {t(
                      "Pages.Analytics.Exports.CustomPopup.DownloadedScreen.InfoHere"
                    )}
                  </span>
                  .
                </p>
              </div>
            </div>
          )}
          {displayStatus === "ERROR" && (
            <div>
              <div className="mb-2 py-1 px-4 pb-2 bg-rose-50 text-rose-700 rounded-y-md border-l-2 border-l-rose-800">
                <div className="flex items-center justify-center pt-4">
                  <div className="text-center">
                    <div>
                      <FontAwesomeIcon
                        icon={faCircleXmark}
                        size="4x"
                        className="text-rose-700"
                      />
                    </div>
                    <div className="mt-2 font-bold">
                      {t(
                        "Pages.Analytics.Exports.CustomPopup.ErrorScreen.Subtitle"
                      )}
                    </div>
                  </div>
                </div>
                <p className="text-sm mt-2 text-center">
                  {t("Pages.Analytics.Exports.CustomPopup.ErrorScreen.Please")}{" "}
                  <span
                    className="underline hover:font-bold hover:cursor-pointer"
                    onClick={onReturnToForm}
                  >
                    {t(
                      "Pages.Analytics.Exports.CustomPopup.ErrorScreen.TryAgain"
                    )}
                  </span>
                  ,{" "}
                  {t(
                    "Pages.Analytics.Exports.CustomPopup.ErrorScreen.IfPersists"
                  )}
                </p>
              </div>
            </div>
          )}
        </>
      )}
      {isUserTryingToLeave && (
        <>
          <DangerAlert
            prefix=""
            message={t("Pages.Analytics.Exports.CustomPopup.DetailsWillBeLost")}
          />
          <div className="pt-2 pb-2">
            <div className="flex flex-row gap-2 justify-end">
              <button
                className="btn-secondary"
                onClick={() => setIsUserTryingToLeave(false)}
              >
                <span className="ml-1">
                  {t("TaskType.Popup.Buttons.NoAndGoBack")}
                </span>
              </button>

              <button
                className="btn-primary"
                onClick={() => onOpenChange(false)}
              >
                <span className="ml-1">
                  {t("TaskType.Popup.Buttons.YesImSure")}
                </span>
              </button>
            </div>
          </div>
        </>
      )}
    </ModalPopup>
  );
};

export default CustomExportRequestPopup;
