import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faInfoCircle,
  faRadar,
} from "@fortawesome/pro-solid-svg-icons";
import { ClientFormDto } from "../../../../types/dtos/admin/ClientFormDto";
import { ValidationResult } from "../../../../types/forms";
import ValidationWarning from "../../../common/ValidationWarning";
import DraggableList from "react-draggable-list";
import SectionItem from "./SectionItem";
import { t } from "i18next";
import { useEffect, useState } from "react";
import {
  AlertPopup,
  DateInput,
  GenericDropDownList,
  Label,
  NumberInput,
  RadioButtonGroup,
  Switch,
} from "../../../common";
import MultipleChoiceOptionNumericId from "../../../../types/forms/MultipleChoiceOptions";
import InfoAlert from "../../../alerts/InfoAlert";
import TaskManagementReviewDateType from "../../../../types/admin/TaskManagementReviewDateType";
import { KeyValuePair } from "../../../../types/generic";

interface SectionSelectionProps {
  availableSections: ClientFormDto[];
  selectedSections: ClientFormDto[];
  preventUsersThatRecentlyCompleted: boolean;
  completedInPastNumOfDays: number;
  prefillOptions: MultipleChoiceOptionNumericId[];
  displayTaskManagementFields: boolean;
  taskMinimumDate: Date | null;
  taskDateType: TaskManagementReviewDateType | null;
  onUpdateAvailableSections(newState: ClientFormDto[] | undefined): void;
  onUpdateSelectedSections(newState: ClientFormDto[] | undefined): void;
  onTogglePreventUsersThatRecentlyCompleted(newValue: boolean): void;
  onNumOfDaysChange(newValue: number): void;
  onSelectedPrefillOptionChange(
    newSelectedValue: number,
    newOptionsValue: MultipleChoiceOptionNumericId[]
  ): void;
  onTaskMinimumDateChange(newValue: Date | null): void;
  onTaskDateTypeChange(newValue: TaskManagementReviewDateType | null): void;
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
}

/** A text input field, with common styling already applied
 */
const SectionSelection = ({
  availableSections,
  selectedSections,
  preventUsersThatRecentlyCompleted = false,
  completedInPastNumOfDays = 30,
  prefillOptions,
  displayTaskManagementFields,
  taskMinimumDate,
  taskDateType,
  onUpdateAvailableSections,
  onUpdateSelectedSections,
  onTogglePreventUsersThatRecentlyCompleted,
  onNumOfDaysChange,
  onSelectedPrefillOptionChange,
  onTaskMinimumDateChange,
  onTaskDateTypeChange,
  showValidationErrors = false,
  validationResult = null,
}: SectionSelectionProps) => {
  const [showSelectedSectionsTooltip, setShowSelectedSectionsTooltip] =
    useState(false);
  const [
    showCompletedInPastNumOfDaysTooltip,
    setShowCompletedInPastNumOfDaysTooltip,
  ] = useState(false);
  const [
    selectedSectionsWithRelevantOverrides,
    setselectedSectionsWithRelevantOverrides,
  ] = useState<ClientFormDto[]>();
  const [automatedModePostSuffix, setAutomatedModePostSuffix] =
    useState<string>(t("Pages.SendNow.Warnings.WithinXDays"));

  let taskDateTypes = [
    {
      key: "DATE-CREATED",
      value: t("Common.DateCreated"),
    },
    {
      key: "DUE-DATE",
      value: t("Common.DueDate"),
    },
  ] as KeyValuePair<TaskManagementReviewDateType, string>[];

  const _onAddToSelectedSection = (item: ClientFormDto) => {
    // Copy and then remove item from Available.
    let newAvailableState = [...availableSections];
    let index = newAvailableState.findIndex((x) => x.id == item.id);
    newAvailableState.splice(index, 1);

    // Copy and push item to Selected.
    let newSelectedState = [...selectedSections];
    newSelectedState.push(item);

    onUpdateAvailableSections(newAvailableState);
    onUpdateSelectedSections(newSelectedState);
  };

  const _onListChange = (newList: ClientFormDto[]) => {
    onUpdateSelectedSections(newList);
  };

  const _onDelete = (item: ClientFormDto) => {
    var newSelectedState = selectedSections.filter(function (ss) {
      return ss !== item;
    });

    let newAvailableState = [...availableSections];
    newAvailableState.push(item);

    onUpdateSelectedSections(newSelectedState);
    onUpdateAvailableSections(newAvailableState);
  };

  useEffect(() => {
    // Retrieve any selected sections that have overrides.
    var selectedSectionsWithRelevantOverrides = selectedSections.filter(
      function (ss) {
        return ss.overrides.length > 0;
      }
    );

    // Loop over and remove any non-relevant overrides e.g. manual only
    selectedSectionsWithRelevantOverrides.forEach(function (ss) {
      var overrides = ss.overrides.filter(function (override) {
        return override.mode !== "MANUAL-ONLY";
      });
      ss.overrides = overrides;
    });

    setselectedSectionsWithRelevantOverrides(
      selectedSectionsWithRelevantOverrides
    );
  }, [selectedSections]);

  useEffect(() => {
    let postSuffix = t("Pages.SendNow.Warnings.WithinXDays");
    postSuffix = postSuffix.replace(
      "%NUM_DAYS%",
      completedInPastNumOfDays.toString()
    );

    setAutomatedModePostSuffix(postSuffix);
  }, [completedInPastNumOfDays]);

  const handleSelectedPrefillOptionChange = (
    options: MultipleChoiceOptionNumericId[]
  ): void => {
    const selectedValue = options.find((x) => x.isSelected)?.value;
    onSelectedPrefillOptionChange(selectedValue as number, options);
  };

  return (
    <>
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      <div className="grid grid-cols-1 md:grid-cols-12 gap-10">
        <div className="col-span-1 md:col-span-6">
          <h4>{t("Pages.SendNow.Other.AvailableSections")}:</h4>
          <div className="mt-1">
            {availableSections.length > 0 &&
              availableSections.map((as) => {
                return (
                  <div
                    key={as.id}
                    className="p-2 border shadow-md mb-3 grid grid-cols-12"
                  >
                    <p className="col-span-11 pl-3">
                      {t(as.titleTranslationKey)}
                    </p>
                    <div>
                      <FontAwesomeIcon
                        icon={faPlus}
                        onClick={() => _onAddToSelectedSection(as)}
                        className="text-green-700 cursor-pointer col-span-1"
                      ></FontAwesomeIcon>
                    </div>
                  </div>
                );
              })}
            {availableSections.length === 0 && (
              <div className="p-2 border-t border-t-gray-500 border-b border-b-gray-500 text-center text-sm">
                <span>
                  {t("Pages.SendNow.Other.NoAvailableSections")}
                  <FontAwesomeIcon
                    icon={faRadar}
                    className="pl-2"
                  ></FontAwesomeIcon>
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="col-span-1 md:col-span-6">
          <h4>
            {t("Pages.SendNow.Other.SelectedSections")}:
            <FontAwesomeIcon
              onClick={() => setShowSelectedSectionsTooltip(true)}
              icon={faInfoCircle}
              size="sm"
              className="text-gray-600 pl-1 hover:cursor-pointer"
            />
          </h4>
          <AlertPopup
            isOpen={showSelectedSectionsTooltip}
            onOpenChange={setShowSelectedSectionsTooltip}
            onPrimaryButtonClick={() => console.log("Ok")}
            primaryButtonText={t("Pages.SendNow.Tooltips.Ok")}
            bodyText={t("Pages.SendNow.Tooltips.SelectedSections")}
            title={t("Pages.SendNow.Tooltips.Information")}
          />
          <div className="mt-1">
            {selectedSections.length > 0 && (
              <DraggableList
                list={selectedSections}
                itemKey="id"
                template={SectionItem}
                onMoveEnd={(newList: any) => _onListChange(newList)}
                commonProps={_onDelete}
              />
            )}
            {selectedSections.length === 0 && (
              <div className="p-2 border-t border-t-gray-500 border-b border-b-gray-500 text-center text-sm">
                <span>
                  {t("Pages.SendNow.Other.NoSelectedSections")}
                  <FontAwesomeIcon icon={faRadar} className="pl-2" />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="mt-4 flex flex-row gap-2">
          <div className="flex-initial">
            <Switch
              checked={preventUsersThatRecentlyCompleted}
              onChange={onTogglePreventUsersThatRecentlyCompleted}
            />
          </div>
          <Label
            text={t("Pages.SendNow.Fields.DoNotSendIfCompletedRecently")}
            className="flex-grow"
          />
        </div>
      </div>
      {preventUsersThatRecentlyCompleted && (
        <div className="grid grid-cols-1 md:grid-cols-12 pt-1">
          <div className="col-span-1 md:col-span-1 pt-2">&nbsp;</div>
          <div className="col-span-1 md:col-span-8 pt-2">
            <h4>{t("Pages.SendNow.Fields.DoNotSendIfCompletedInPast")}</h4>
          </div>
          <div className="col-span-1 md:col-span-2">
            <NumberInput
              className="w-full text-base"
              onChange={onNumOfDaysChange}
              value={completedInPastNumOfDays}
              minimum={1}
              maximum={365}
              step={1}
              inputId="number-input-id"
            />
          </div>
          <div className="col-span-1 md:col-span-1 pt-2 md:pl-3">
            <h4>
              {t("Common.Days_LowerCase")}
              <FontAwesomeIcon
                onClick={() => setShowCompletedInPastNumOfDaysTooltip(true)}
                icon={faInfoCircle}
                size="sm"
                className="text-gray-600 pl-1"
              />
            </h4>
            <AlertPopup
              isOpen={showCompletedInPastNumOfDaysTooltip}
              onOpenChange={setShowCompletedInPastNumOfDaysTooltip}
              onPrimaryButtonClick={() => console.log("Ok")}
              primaryButtonText={t("Pages.SendNow.Tooltips.Ok")}
              bodyText={t("Pages.SendNow.Tooltips.CompletedInPastNumOfDays")}
              title={t("Pages.SendNow.Tooltips.Information")}
            />
          </div>
        </div>
      )}
      <div className="mb-2 pt-4">
        <Label text={t("Pages.SendNow.Fields.DoYouWantToPrefillAnswers")} />
        <RadioButtonGroup
          values={prefillOptions}
          onChange={handleSelectedPrefillOptionChange}
          uniqueFieldName="section-prefill"
          layout="HORIZONTAL"
          containerClassNames="mt-2"
        />
      </div>
      {displayTaskManagementFields && (
        <div>
          <hr />
          <div className="mt-2 mb-4">
            <div className="my-1">
              <p>
                {t(
                  "Pages.SendNow.Fields.SelectedSectionsContainTaskManagement"
                )}
              </p>
              <InfoAlert
                message={t("Pages.SendNow.Tooltips.IfLeftBlank")}
                prefix={null}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
              <div className="mt-2 col-span-1 md:col-span-3">
                <Label text={t("Common.Date") + ":"} />
                <DateInput
                  value={taskMinimumDate}
                  onChange={onTaskMinimumDateChange}
                  allowDatesInPast={true}
                  className="rounded-md w-full"
                  isClearable={true}
                  placeholder={t("Common.Optional")}
                />
              </div>
              <div className="mt-2 col-span-1 md:col-span-3">
                <Label text={t("Common.IncludeBy") + ":"} />
                <GenericDropDownList
                  currentValue={taskDateType ? taskDateType : null}
                  items={taskDateTypes}
                  onChange={onTaskDateTypeChange}
                  className="block w-full"
                  isReadOnly={false}
                  includeSelectOption={false}
                  applyBorder={true}
                />
              </div>
            </div>
          </div>
          <hr />
        </div>
      )}
      {selectedSectionsWithRelevantOverrides &&
        selectedSectionsWithRelevantOverrides.length > 0 && (
          <div className="bg-gray-50 py-1 px-4 mb-2 text-gray-700 text-sm rounded-sm border-l-2 border-l-gray-800 mt-4">
            <div>
              <div className="fa-layers fa-fw mr-0.5">
                <FontAwesomeIcon icon={faInfoCircle} />
              </div>
              {t("Pages.SendNow.Warnings.DueToOverrides")}:
              <div>
                {selectedSectionsWithRelevantOverrides.map((section) => (
                  <div key={section.id}>
                    <div className="pt-1">
                      <span className="font-bold">
                        {t(section.titleTranslationKey)}
                      </span>{" "}
                      {t("Pages.SendNow.Warnings.WontBeSent")}:
                    </div>
                    <div>
                      <ul>
                        {section.overrides.map((override) => (
                          <li key={`${section.id}_${override.overrideId}`}>
                            - {t(override.appraisalLevelName)}{" "}
                            {override.mode === "AUTOMATED" &&
                              preventUsersThatRecentlyCompleted && (
                                <span>{automatedModePostSuffix}</span>
                              )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default SectionSelection;
