import { FormQuestionDto } from "../types/dtos/forms";
import {
  MultipleChoiceQuestionAnswerValue,
  QuestionAnswer,
  QuestionAnswerValue,
} from "../types/forms";
import FormQuestion from "../types/forms/FormQuestion";

/** Logic shared between journeys and collab docs question sequencing */
const shared = {
  /** Get the questionId for the selected optionId, based on the sequenceMap */
  getQuestionForSelectedOptionId(
    question: FormQuestion,
    answer: QuestionAnswerValue
  ): string | null {
    const options = answer as MultipleChoiceQuestionAnswerValue[];
    if (
      question.sequenceMap.options &&
      options &&
      options.length === 1 &&
      options[0].optionId
    ) {
      const matchingMapOption = question.sequenceMap.options.find(
        (x) => x.optionId === options[0].optionId
      );
      if (matchingMapOption) {
        return matchingMapOption.nextQuestionId;
      }
    }
    return null;
  },
};

export const journeyQuestionSequenceHelper = {
  /** Get the next question - either conditional based on the selected value
   * (if this is a multiple choice question) - or just the next in the sequence of questions
   */
  getNextQuestionId(
    currentQuestion: FormQuestion,
    answer: QuestionAnswerValue | null
  ): string | null {
    const defaultNextQuestion =
      currentQuestion.sequenceMap.defaultNextQuestionId || null;

    // Only look in the map for conditional routing if this is a multiple choice question,
    // and only one answer is allowed (we can't do conditionals for checklists, for example - unless all options trigger the same conditional question)

    if (
      answer != null &&
      currentQuestion.isMultiChoiceQuestion() &&
      (!currentQuestion.multipleValuesAllowed() ||
        currentQuestion.allOptionsTriggerSameConditional()) &&
      currentQuestion.nextQuestionIsConditional()
    ) {
      return shared.getQuestionForSelectedOptionId(currentQuestion, answer);
    }

    return defaultNextQuestion;
  },

  /** Calculate the previous question to answer, which appeared to the user before this one did */
  getPreviousQuestionId(
    currentQuestion: FormQuestion,
    /** The questions in the form */
    formQuestions: FormQuestionDto[],
    /** The answers so far */
    formAnswers: QuestionAnswer[]
  ): string | null {
    // If there aren't any questions, or if this question is the first question, return null
    if (
      formQuestions.length === 0 ||
      currentQuestion.questionId === formQuestions[0].questionId
    ) {
      return null;
    }

    // If there's only one question with the current question marked as the "next" one, return that question to shortcut the logic
    const questionsWithThisQuestionNext = formQuestions.filter(
      (x) =>
        x.sequenceMap.defaultNextQuestionId !== null &&
        x.sequenceMap.defaultNextQuestionId === currentQuestion.questionId &&
        (x.sequenceMap.options === undefined ||
          x.sequenceMap.options === null ||
          x.sequenceMap.options.length === 0)
    );
    if (questionsWithThisQuestionNext.length === 1) {
      const proposedNextQuestion = questionsWithThisQuestionNext[0];
      if (
        !proposedNextQuestion.sequenceMap ||
        !proposedNextQuestion.sequenceMap.conditionallyTriggered
      ) {
        return proposedNextQuestion.questionId;
      }
    }

    // Start from the beginning and go through finding the next question based on the answers given,
    // until we end up at this question. The question prior was the previous question
    let outputQuestionId: string | null = null;
    let questionDto: FormQuestionDto | null = formQuestions[0];
    while (outputQuestionId === null && questionDto !== null) {
      const answer = formAnswers.find(
        (x) => x.questionId === questionDto!.questionId
      );
      // Find a question with an answer which triggers the current question next. That question (if found)
      // is the previous question
      const thisQuestion = new FormQuestion(questionDto);
      const calculatedNextQuestion =
        journeyQuestionSequenceHelper.getNextQuestionId(
          thisQuestion,
          answer ? answer.answer : null
        );
      if (calculatedNextQuestion === currentQuestion.questionId) {
        outputQuestionId = questionDto.questionId;
      } else {
        if (calculatedNextQuestion === null) {
          questionDto = null;
        } else {
          const foundQuestion = formQuestions.find(
            (x) => x.questionId === calculatedNextQuestion
          );
          questionDto = foundQuestion ? foundQuestion : null;
        }
      }
    }

    return outputQuestionId;
  },
};

export const collabDocQuestionSequenceHelper = {
  getNextQuestionToDisplay(
    currentQuestion: FormQuestion,
    currentQuestionAnswer: QuestionAnswerValue | null,
    /** The questions in the form */
    formQuestions: FormQuestionDto[]
  ): string | null {
    if (
      !currentQuestion.sequenceMap.options ||
      currentQuestion.sequenceMap.options.length === 0
    ) {
      // No conditional mappings (e.g. a text question) so can just return
      // the default next question
      return currentQuestion.sequenceMap.defaultNextQuestionId;
    }

    // Must be a conditional question
    if (
      currentQuestionAnswer !== null &&
      currentQuestion.isMultiChoiceQuestion() &&
      !currentQuestion.multipleValuesAllowed()
    ) {
      // Valid conditional question with an answer, find the next
      // question for the selected option
      return shared.getQuestionForSelectedOptionId(
        currentQuestion,
        currentQuestionAnswer
      );
    } else {
      // Conditional and no answer
      // So get the next question which isn't conditional after this one
      // by using the question sequences in the form
      const currentQuestionIndex = formQuestions.findIndex(
        (x) => x.questionId === currentQuestion.questionId
      );
      if (currentQuestionIndex < formQuestions.length) {
        const questionsAfter = formQuestions.slice(currentQuestionIndex + 1);
        if (questionsAfter.length > 0) {
          // Loop over the next questions and find the first non-conditional question
          for (let i = 0; i < questionsAfter.length; i++) {
            const loopQuestion = questionsAfter[i];
            if (!loopQuestion.sequenceMap.conditionallyTriggered) {
              return loopQuestion.questionId;
            }
          }
        }
      }
    }

    return null;
  },
};

const questionSequenceHelpers = {
  collabDocs: collabDocQuestionSequenceHelper,
  journeys: journeyQuestionSequenceHelper,
};

export default questionSequenceHelpers;
