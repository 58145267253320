import { memo, useContext, useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { t } from "i18next";
import UserContext from "../../state/UserContext";
import { dateHelper } from "../../helpers";

dayjs.extend(relativeTime);

export type DateDisplayMode = "DATE-ONLY" | "DATE-AND-TIME" | "FROM-NOW";

interface FormattedDateProps {
  date: Date;
  displayMode: DateDisplayMode;
  /** Whether or not to convert the date to the local (browser) date. Use if the date supplied is UTC */
  convertFromUtc?: boolean;
  useTwentyFourHourTimeFormat?: boolean;
}

/** A component for consistent date formatting, including the ability for a "2 days from now" style value */
const FormattedDate = ({
  date,
  displayMode,
  convertFromUtc = false,
  useTwentyFourHourTimeFormat = false,
}: FormattedDateProps) => {
  // Context
  const userContext = useContext(UserContext);

  // State
  const [showUnderlyingDate, setShowUnderlyingDate] = useState<boolean>(false);

  // Variables
  const now = !convertFromUtc 
    ? dayjs().utc()
    : dayjs();

  const dateToUse = convertFromUtc
    ? dateHelper.convertUtcDateToLocal(date)
    : date;
  const dateObject = dayjs(dateToUse);

  // The "from now" span allows clicking to toggle displaying the underlying date
  const toggleDisplay = () => {
    setShowUnderlyingDate(!showUnderlyingDate);
  };

  if (!dateObject.isValid()) {
    return <span>{t("Common.InvalidDate")}</span>;
  } else {
    const formattedDate = dateObject.format(
      // Format for dayjs is different to most other things, e.g. the date picker component
      // e.g. what is normally dd/MM/yyyy is DD/MM/YYYY in dayJs
      // https://day.js.org/docs/en/display/format
      userContext.user.i18n.dateFormat.toUpperCase()
    );

    switch (displayMode) {
      case "FROM-NOW":
        const valueToDisplay = showUnderlyingDate
          ? formattedDate
          : dateObject.from(now);
        return (
          <span className="cursor-pointer select-none" onClick={toggleDisplay}>
            {valueToDisplay}
          </span>
        );
      case "DATE-AND-TIME":
        const timeFormat = useTwentyFourHourTimeFormat ? "HH:mm" : "h:mm A";
        return (
          <span>
            {formattedDate} {dateObject.format(timeFormat)}
          </span>
        );
      case "DATE-ONLY":
      default:
        return <span>{formattedDate}</span>;
    }
  }
};

export default memo(FormattedDate);
