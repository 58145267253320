import { useContext } from "react";
import UserContext from "../../../../state/UserContext";
import { dateHelper } from "../../../../helpers";
import { t } from "i18next";
import InfoAlert from "../../../alerts/InfoAlert";

interface DraftResponseBannerProps {
  dateLastModified?: Date | null;
  lastModifiedByEmployeeId?: number | null;
  lastModifiedByEmployeeName?: string | null;
}

const DraftResponseBanner = ({
  dateLastModified,
  lastModifiedByEmployeeId,
  lastModifiedByEmployeeName,
}: DraftResponseBannerProps) => {
  const userContext = useContext(UserContext);

  // If there are no last modified properties, return null
  if (!dateLastModified || !lastModifiedByEmployeeId || !lastModifiedByEmployeeName) {
    return null;
  }

  const loggedInUserId = userContext.user.id;
  const lastModifiedDate = dateHelper.convertUtcDateToLocal(dateLastModified).toLocaleDateString(undefined);


  return (
    <div className="text-sm flex flex-col">
      {lastModifiedByEmployeeId === loggedInUserId ? (
        <InfoAlert
          prefix=""
          message={t("TaskType.Popup.Banner.DraftByYou", {
            dateModified: lastModifiedDate,
          })}
        />
      ) : (
        <InfoAlert
          prefix=""
          message={t("TaskType.Popup.Banner.DraftByOther", {
            dateModified: lastModifiedDate,
            otherEmployee: lastModifiedByEmployeeName
          })}
        />
      )}
    </div>
  );
};

export default DraftResponseBanner;
