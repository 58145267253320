import { fileHelper, taskTypeHelper } from "../../helpers";
import {
  AnswerSetApprovalStatus,
  ModifyMeetingDto,
  NewCollabDocRequest,
} from "../../types/collab-docs";
import {
  CollabDocCommentUpsertResponseDto,
  CollabDocLoadResponseDto,
  CollabDocSubmitResponseDto,
} from "../../types/dtos/collab-docs";
import {
  NewBehaviourAnswerDto,
  NewGoalReviewAnswerDto,
  NewMultiChoiceAnswerDto,
  NewTextAnswerDto,
} from "../../types/dtos/collab-docs/answers";
import NewDevelopmentAnswerDto from "../../types/dtos/collab-docs/answers/NewDevelopmentAnswerDto";
import ModifyTaskResponseDto from "../../types/dtos/tasks/ModifyTaskResponseDto";
import { NewCommentDto, SavedCommentDto } from "../../types/dtos/forms";
import { EditableTask } from "../../types/tasks/EditableTasks";
import { apiClient } from "../apiClient";
import CatchUpDto from "../../types/catch-ups/CatchUpDto";
import BatchTaskEditResponseDto from "../../types/dtos/tasks/BatchTaskEditResponseDto";
import { QuestionType } from "../../types/forms";
import PlanningResponsesApiResponseDto from "../../types/dtos/collab-docs/PlanningResponsesApiResponseDto";
import NewCollabDocCreationResponse from "../../types/collab-docs/NewCollabDocCreationResponse";
import download from "downloadjs";
import CollabDocLockedDto from "../../types/forms/CollabDocLockedDto";

class collabDocApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  /** Loads the data for a collaborative document editor */
  loadCollabDocForEditor(
    answerSetGuid: string,
    singleFormId: number | undefined,
    onSuccess: (apiData: CollabDocLoadResponseDto) => void,
    onError: (error: any) => void
  ) {
    let apiUrl =
      "dashboards/collab-doc/get-doc-for-collab-doc-editor?id=" + answerSetGuid;
    if (singleFormId && singleFormId > 0) {
      apiUrl += "&clientFormId=" + singleFormId;
    }

    return apiClient(this.accessToken, apiUrl).then(
      (data) => {
        const apiData = data as CollabDocLoadResponseDto;
        onSuccess(apiData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Loads the data for a collaborative document editor */
  loadCollabDocForViewer(
    answerSetGuid: string,
    onSuccess: (apiData: CollabDocLoadResponseDto) => void,
    onError: (error: any) => void
  ) {
    let apiUrl =
      "dashboards/collab-doc/get-doc-for-collab-doc-viewer?id=" + answerSetGuid;

    return apiClient(this.accessToken, apiUrl).then(
      (data) => {
        const apiData = data as CollabDocLoadResponseDto;
        onSuccess(apiData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Save a new comment against a collab doc */
  submitNewComment(
    /** The answer set to save this comment against */
    answerSetUniqueId: string,
    /** The data being submitted to the server */
    comment: NewCommentDto,
    onSuccess: (data: SavedCommentDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/collab-doc/add-comment?doc=" + answerSetUniqueId,
      {
        body: JSON.stringify(comment),
      }
    ).then(
      (data) => {
        const responseData = data as SavedCommentDto;
        onSuccess(responseData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Delete a comment from a collab doc */
  deleteComment(
    commentId: string,
    answerSetGuid: string,
    onSuccess: () => void,
    onError: () => void
  ) {
    return apiClient(
      this.accessToken,
      `dashboards/collab-doc/delete-comment?doc=${answerSetGuid}&commentId=${commentId}`
    ).then(
      (data) => {
        onSuccess();
      },
      (error) => {
        onError();
      }
    );
  }

  /** Mark a comment as having been seen/read */
  markQuestionCommentsAsSeen(
    questionId: string,
    answerSetGuid: string,
    onSuccess: () => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      `dashboards/collab-doc/mark-comments-seen?doc=${answerSetGuid}&qId=${questionId}`
    ).then(
      (data) => {
        onSuccess();
      },
      (error) => {
        onError(error);
      }
    );
  }

  updateEnforcedComment(
    answerSetUniqueId: string,
    comment: SavedCommentDto,
    onSuccess: (newComment: SavedCommentDto | null | undefined) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/collab-doc/upsert-enforced-comment?doc=" + answerSetUniqueId,
      {
        body: JSON.stringify(comment),
      }
    ).then(
      (data) => {
        // If the comment text was blank, the original comment should be deleted, so no `updatedComment` will be returned
        const responseData = data as CollabDocCommentUpsertResponseDto;
        onSuccess(responseData.updatedComment);
      },
      (error) => {
        onError(error);
      }
    );
  }

  saveBehaviourAnswer(
    answerDto: NewBehaviourAnswerDto,
    onSuccess: () => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/collab-doc/save-behaviour-answer",
      {
        body: JSON.stringify(answerDto),
      }
    ).then(
      (data) => {
        onSuccess();
      },
      (error) => {
        onError(error);
      }
    );
  }

  saveGoalReviewAnswer(
    answerDto: NewGoalReviewAnswerDto,
    onSuccess: () => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/collab-doc/save-goal-review-answer",
      {
        body: JSON.stringify(answerDto),
      }
    ).then(
      (data) => {
        onSuccess();
      },
      (error) => {
        onError(error);
      }
    );
  }

  saveTextAnswer(
    answerDto: NewTextAnswerDto,
    onSuccess: () => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/collab-doc/save-text-answer",
      {
        body: JSON.stringify(answerDto),
      }
    ).then(
      (data) => {
        onSuccess();
      },
      (error) => {
        onError(error);
      }
    );
  }

  saveMultiChoiceAnswer(
    answerDto: NewMultiChoiceAnswerDto,
    onSuccess: () => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/collab-doc/save-multi-choice-answer",
      {
        body: JSON.stringify(answerDto),
      }
    ).then(
      (data) => {
        onSuccess();
      },
      (error) => {
        onError(error);
      }
    );
  }

  saveDevelopmentAnswer(
    answerDto: NewDevelopmentAnswerDto,
    onSuccess: () => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/collab-doc/save-development-answer",
      {
        body: JSON.stringify(answerDto),
      }
    ).then(
      (data) => {
        onSuccess();
      },
      (error) => {
        onError(error);
      }
    );
  }

  saveFormTask(
    taskDto: EditableTask<string>,
    answerSetUniqueId: string,
    formQuestionId: string,
    clientFormId: number,
    onSuccess: (data: ModifyTaskResponseDto<string>) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/collab-doc/modify-task-as-requested?answerSetUniqueId=" +
        answerSetUniqueId +
        "&formQuestionId=" +
        formQuestionId +
        "&clientFormId=" +
        clientFormId,
      {
        body: JSON.stringify(taskDto),
      }
    ).then(
      (data) => {
        const responseData = data as ModifyTaskResponseDto<string>;

        // If the response has a TaskDto then we need to be sure to convert that into its relevant EditableTask object
        if (responseData.doesResponseContainsNewTaskDto) {
          responseData.task = taskTypeHelper.getTaskObject(
            responseData.taskDbObject
          );
        }

        onSuccess(responseData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  saveModifiedTasksBatch(
    tasks: EditableTask<string>[],
    answerSetUniqueId: string,
    formQuestionId: string,
    clientFormId: number,
    onSuccess: (data: EditableTask<string>[]) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/collab-doc/batch-save-modified-tasks?answerSetUniqueId=" +
        answerSetUniqueId +
        "&formQuestionId=" +
        formQuestionId +
        "&clientFormId=" +
        clientFormId,
      {
        body: JSON.stringify(tasks),
      }
    ).then(
      (data) => {
        const responseData = data as BatchTaskEditResponseDto;

        // If the response has TaskDtos then we need to be sure to convert them into EditableTask objects
        let output: EditableTask<string>[] = [];
        if (responseData.latestTasks && responseData.latestTasks.length > 0) {
          output = responseData.latestTasks.map((x) =>
            taskTypeHelper.getTaskObject(x)
          );
        }

        onSuccess(output);
      },
      (error) => {
        onError(error);
      }
    );
  }

  submitCollabDoc(
    answerSetUniqueId: string,
    proposedStatus: AnswerSetApprovalStatus,
    onSuccess: (data: CollabDocSubmitResponseDto) => void,
    onError: (error: any) => void,
    isInstantlySigningOff?: boolean
  ) {
    let url = `dashboards/collab-doc/submit?doc=${answerSetUniqueId}&status=${encodeURIComponent(
      proposedStatus
    )}`;

    if (isInstantlySigningOff) {
      url += "&isInstantlySigningOff=" + isInstantlySigningOff;
    }

    return apiClient(this.accessToken, url).then(
      (data) => {
        const responseData = data as CollabDocSubmitResponseDto;
        onSuccess(responseData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Loads the data for a collaborative document */
  getPlanningResponse(
    answerSetGuidId: string,
    subjectUserId: number,
    questionId: string,
    questionType: QuestionType,
    behaviourId: number | null,
    goalId: number | null,
    onSuccess: (responseData: PlanningResponsesApiResponseDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/collab-doc/get-planning-response?id=" +
        answerSetGuidId +
        "&subjectEmployeeId=" +
        subjectUserId +
        "&questionId=" +
        questionId +
        "&questionType=" +
        questionType +
        "&behaviourId=" +
        behaviourId +
        "&goalId=" +
        goalId
    ).then(
      (data) => {
        const responseData = data as PlanningResponsesApiResponseDto;
        onSuccess(responseData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  CreateNewOrUpdateMeeting(
    createMeetingDto: CatchUpDto,
    onSuccess: (data: CatchUpDto | null) => void,
    onError: (error: any) => void
  ) {
    const details: ModifyMeetingDto = {
      answerSetUniqueId: createMeetingDto.answerSetUniqueId!,
      participantEmployeeId: createMeetingDto.participantEmployeeId!,
      scheduledDate: createMeetingDto.scheduledDate!,
    };
    return apiClient(
      this.accessToken,
      "dashboards/collab-doc/create-new-or-update-meeting",
      {
        body: JSON.stringify(details),
      }
    ).then(
      (data) => {
        const meetingData = data as CatchUpDto;
        onSuccess(meetingData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  shouldCollabDocBeLocked(
    answerSetGuid: string,
    onSuccess: (data: CollabDocLockedDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/collab-doc/should-collab-doc-be-locked?id=" + answerSetGuid
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  swapCollabDocLockAccess(
    answerSetGuid: string,
    onSuccess: (data: any) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/collab-doc/swap-collab-doc-lock-access?id=" + answerSetGuid
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  clearLockedFieldsWhenLoggedInUserIsLockedByUser(
    answerSetGuid: string,
    onSuccess: (data: boolean) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/collab-doc/clear-locked-fields-when-logged-in-user-is-locked-by-user?id=" +
        answerSetGuid
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Enter edit mode to create a clone of a collab doc */
  cloneDoc(
    requestDetails: NewCollabDocRequest,
    onSuccess: (data: NewCollabDocCreationResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(this.accessToken, "dashboards/collab-doc/clone-doc", {
      body: JSON.stringify(requestDetails),
    }).then(
      (data) => {
        const responseData = data as NewCollabDocCreationResponse;
        onSuccess(responseData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  discardCollabDoc(
    answerSetGuid: string,
    onSuccess: () => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/collab-doc/discard?id=" + answerSetGuid
    ).then(
      () => {
        onSuccess();
      },
      (error) => {
        onError(error);
      }
    );
  }

  downloadPdf(
    answerSetGuid: string,
    answerSetTitle: string,
    onSuccess: () => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/collab-doc/export-pdf?id=" + answerSetGuid,
      undefined,
      "BLOB"
    ).then(
      (blob) => {
        onSuccess();
        // Create a filename from the answerSetTitle (removing any invalid characters)
        let filename = fileHelper.createFilenameFromString(
          answerSetTitle,
          true
        );
        // Remove any triple dashes from the filename (common in collab doc titles after running through the filename creation function)
        filename = filename.replace("---", "-");
        const filenameWithExtension = filename + ".pdf";
        download(blob, filenameWithExtension, "application/pdf");
      },
      (error) => {
        onError(error);
        console.log(error);
      }
    );
  }
}

export default collabDocApi;
