import { t } from "i18next";
import {
  ValidationError,
  ValidationErrorType,
} from "../types/forms/ValidationResult";
import {
  SimpleFormQuestion,
  SimpleFormValidationError,
} from "../types/dtos/simple-forms";

const replaceToken = (
  input: string,
  replaceValue: number | string,
  token: string
): string => {
  let output = input;
  if (input && input.length > 0) {
    output = output.replace(token, replaceValue.toString());
  }
  return output;
};

const replaceMinToken = (input: string, replaceValue: number): string => {
  return replaceToken(input, replaceValue, "#MIN#");
};

const replaceMaxToken = (input: string, replaceValue: number): string => {
  return replaceToken(input, replaceValue, "#MAX#");
};

const replaceCurrentToken = (input: string, replaceValue: number): string => {
  return replaceToken(input, replaceValue, "#CURRENT#");
};

const replaceExpectedValueToken = (
  input: string,
  replaceValue: string
): string => {
  return replaceToken(input, replaceValue, "#EXPECTED_VALUE#");
};

export const formValidationHelper = {
  /** Get the validation message with numeric values (e.g. for min and max) substituted in where necessary */
  getMessageText: (error: ValidationError): string => {
    const subTypeText =
      error.subType && error.subType.length > 0 ? `.${error.subType}.` : ".";
    let translationKey = "Common.Validation.Errors" + subTypeText;
    switch (error.errorType) {
      case "MAX":
        translationKey += "Min";
        break;
      case "MIN":
        translationKey += "Max";
        break;
      case "MIN-AND-MAX":
        translationKey += "MinAndMax";
        break;
      case "SELECT-EXACT":
        translationKey += "SelectExact";
        break;
      case "REQUIRED":
        translationKey += "Required";
        break;
      case "TOO-LONG":
        translationKey += "TooLong";
        break;
      case "INVALID-HEX":
        translationKey += "InvalidHex";
        break;
      case "INVALID-DATE":
        translationKey += "InvalidDate";
        break;
      case "ACCESSIBILITY-ISSUE":
        translationKey += "NotAvailableDueToAccessibility";
        break;
      case "NON-UNIQUE_APP_LEVEL_OVERRIDE":
        translationKey += "NonUniqueAppLevelOverride";
        break;
      case "NUMBER-MISMATCH":
        translationKey += "NumberMismatch";
        break;
      case "ATLEAST-ONE-SECTION":
        translationKey += "AtleastOneSection";
        break;
      case "ILLEGAL-CHARACTER":
        translationKey += "IllegalCharacters";
        break;
      case "CUSTOM-TEXT-REQUIRED":
        translationKey += "CustomTextRequired";
        break;
      case "TASKS-REMAIN-OPEN":
        translationKey += "TasksRemainOpen";
        break;
      case "FAILED-TASK-RESTRICTIONS":
        translationKey += "FailedTaskRestrictions";
        break;
      case "FAILED-ENFORCED-TASK-COMMENT":
        translationKey += "FailedEnforcedTaskComment";
        break;
      case "TASK-REQUIRES-REVIEW-FORM-RESPONSE":
        translationKey += "TaskRequiresReviewFormResponse";
        break;
      case "CUSTOM-TEXT-FOR-SELECTED-OPTION-REQUIRED":
        translationKey += "CustomTextForSelectedOptionRequired";
        break;
      case "OVERDUE-TASKS":
        translationKey += "OverdueTasks";
        break;
      case "EXPECTED-SUBMIT-VALUE-NOT-SET":
        translationKey += "ExpectedSubmitValueNotSet.";
        translationKey += error.expectedValue ? "WithToken" : "WithoutToken";
        break;
    }

    // Translate the value
    let output = t(translationKey);

    // Replace tokens
    if (error.min) {
      output = replaceMinToken(output, error.min);
    }

    if (error.max) {
      output = replaceMaxToken(output, error.max);
    }

    if (error.max && error.current) {
      output = replaceMaxToken(output, error.max);
      output = replaceCurrentToken(output, error.current - error.max);
    }

    if (error.expectedValue) {
      const expectedValueText = error.expectedValue.translate
        ? t(error.expectedValue.value)
        : error.expectedValue.value;
      output = replaceExpectedValueToken(output, expectedValueText);
    }

    return output;
  },
  /** Get instructions for the user on how many items they should select */
  getMultiChoiceMinMaxSelectionMessage: (
    min: number | null,
    max: number | null
  ): string | null => {
    if (min === null && max === null) return null;
    if (min === 0) return null;

    let output = "";
    if (min === null && max !== null) {
      output = t("Common.Validation.Errors.Max", {
        opts:
          max === 1
            ? t("Common.Option").toLowerCase()
            : t("Common.Options").toLowerCase(),
      });
    } else if (min !== null && max === null) {
      output = t("Common.Validation.Errors.Min", {
        opts:
          min === 1
            ? t("Common.Option").toLowerCase()
            : t("Common.Options").toLowerCase(),
      });
    } else if (min === max) {
      output = t("Common.Validation.Errors.SelectExact", {
        opts:
          min === 1
            ? t("Common.Option").toLowerCase()
            : t("Common.Options").toLowerCase(),
      });
    } else {
      // Both numbers not null
      output = t("Common.Validation.Errors.MinAndMax");
    }

    // Replace tokens
    if (min) {
      output = replaceMinToken(output, min);
    }

    if (max) {
      output = replaceMaxToken(output, max);
    }

    return output;
  },

  convertValidationErrorType: function (
    inputError: SimpleFormValidationError,
    question: SimpleFormQuestion
  ): ValidationError {
    let errorType: ValidationErrorType;
    switch (inputError.errorType) {
      case "FAILED-MIN-VALIDATION":
        errorType = "MIN";
        break;
      case "FAILED-MAX-VALIDATION":
        errorType = "MAX";
        break;
      case "FAILED-TO-PROVIDE-CUSTOM-TEXT":
        errorType = "CUSTOM-TEXT-FOR-SELECTED-OPTION-REQUIRED";
        break;
      case "FAILED-REQUIRED-VALIDATION":
      default:
        errorType = "REQUIRED";
        break;
    }

    return {
      errorType: errorType,
      subType: undefined, // Not used in this context
      min: question.validationMin ? question.validationMin : undefined,
      max: question.validationMax ? question.validationMax : undefined,
      current: undefined, // Not used in this context
    };
  },
};

export default formValidationHelper;
