import React, { useRef } from "react";
import cx from "classnames";
import UserTaskComment from "../../../../types/dtos/tasks/advanced-tasks/comments/UserTaskCommentDto";
import AddUserTaskCommentForm from "./AddUserTaskComment";
import { ReactComponent as NoFilesImage } from "../../../../images/noFiles.svg";
import { useTranslation } from "react-i18next";
import IndividualUserTaskComment from "./IndividualUserTaskComment";
import UserContext from "../../../../state/UserContext";
import { advancedTaskHelper } from "../../../../helpers";

interface TaskCommentSectionProps {
  taskTypeName: string;
  clientColor: string;
  isReadOnly: boolean;
  comments: UserTaskComment[] | null;
  onCommentAdded(
    comment: string,
    successCallback: () => void,
    errorCallback: () => void
  ): void;
  onCommentEdited(
    commentId: string,
    comment: string,
    successCallback: () => void,
    errorCallback: () => void
  ): void;
  onCommentDeleted(commentId: string): void;
  /** A ref for calling methods on the textarea element, e.g. to focus it */
  commentFormInputRef?: React.RefObject<HTMLTextAreaElement> | undefined;
  setCommentFormIsDirty(): void;
  unsavedNewCommentTextValue: string;
  setUnsavedNewCommentTextValue: (value: string) => void;
  showAddCommentInFormLikeStyle?: boolean;
  subjectUserName?: string | null;
}

export const newCommentFieldElementId = "new-comment-input";

function TaskCommentSection({
  taskTypeName,
  clientColor,
  isReadOnly,
  comments,
  onCommentAdded,
  onCommentEdited,
  onCommentDeleted,
  commentFormInputRef = undefined,
  setCommentFormIsDirty,
  unsavedNewCommentTextValue,
  setUnsavedNewCommentTextValue,
  showAddCommentInFormLikeStyle = false,
  subjectUserName = null
}: TaskCommentSectionProps) {
  const { t } = useTranslation();
  const userContext = React.useContext(UserContext);
  const translatedTaskName = advancedTaskHelper.ToLowerCase(taskTypeName);

  // Refs
  const commentsListBottomRef = useRef<null | HTMLDivElement>(null);

  const handleCommentAdded = (
    comment: string,
    successCallback: () => void,
    errorCallback: () => void
  ) => {
    // Call the successCallback and then scroll to the bottom of the comments list
    const onSuccessCallback = () => {
      successCallback();
      commentsListBottomRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    onCommentAdded(comment, onSuccessCallback, errorCallback);
  };

  return (
    <div>
      {comments && comments.length === 0 && (
        <p className="block text-center text-sm py-6">
          {!showAddCommentInFormLikeStyle && (
            <div className="mb-4 flex justify-center">
              <NoFilesImage
                className="justify-center w-1/3"
                width={"6rem"}
                height={"6rem"}
              />
            </div>
          )}
          {t("TaskType.EmptyState.NoCommentsForX").replace(
            "#TASK_TYPE#",
            translatedTaskName
          )}
        </p>
      )}
      {comments && comments.length > 0 && (
        <div className={cx("mt-2 pr-2")}>
          {comments.map((comment) => {
            const allowEditAndDelete =
              !isReadOnly &&
              !comment.disableDeleteByAuthor &&
              comment.employeeId === userContext.user.id;
            return (
              <IndividualUserTaskComment
                key={comment.commentId}
                commentId={comment.commentId}
                comment={comment.comment}
                commentTimestamp={comment.dateCreated}
                authorId={comment.employeeId}
                authorProfilePicUrl={comment.employeeProfilePic}
                authorFullName={
                  comment.employeeFirstName + " " + comment.employeeLastName
                }
                authorInitials={comment.employeeInitials}
                allowDelete={allowEditAndDelete}
                disableDeleteButton={false}
                onDeleteClick={onCommentDeleted}
                allowEdit={allowEditAndDelete}
                disableEditButton={false}
                onEditClick={onCommentEdited}
              />
            );
          })}
          <div ref={commentsListBottomRef} />
        </div>
      )}
      {!isReadOnly && (
        <div className="mt-2">
          <AddUserTaskCommentForm
            clientColor={clientColor}
            onSubmit={handleCommentAdded}
            inputRef={commentFormInputRef}
            setFormIsDirty={setCommentFormIsDirty}
            unsavedNewCommentTextValue={unsavedNewCommentTextValue}
            setUnsavedNewCommentTextValue={setUnsavedNewCommentTextValue}
            showAddCommentInFormLikeStyle={showAddCommentInFormLikeStyle}
            taskTypeName={taskTypeName}
            subjectUserName={subjectUserName}
          />
        </div>
      )}
    </div>
  );
}

export default TaskCommentSection;
