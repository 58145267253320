import { useEffect, useState } from "react";
import { JourneyExportRequestQuestion } from "../../types/analytics/custom-exports/JourneyExportRequestDetails";
import { JourneyExportAvailableQuestionGroup } from "../../types/analytics/custom-exports/JourneyExportAvailabileOptions";
import { t } from "i18next";

interface CustomExportAddQuestionProps {
  tabIndex: number;
  questionGroups: JourneyExportAvailableQuestionGroup[]
  selectedQuestions: JourneyExportRequestQuestion[];
  onAddQuestion(tabIndex: number, questionId: string, questionText: string): void;
}

const CustomExportAddQuestion = ({
  tabIndex,
  questionGroups,
  selectedQuestions,
  onAddQuestion
}: CustomExportAddQuestionProps) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [availableDropdownOptions, setAvailableDropdownOptions] = useState<JourneyExportAvailableQuestionGroup[]>(questionGroups);

  useEffect(() => {
    // Remove any selected questions from the groups, then filter out any that have no questions left
    let newState = questionGroups.map(group => ({
      name: group.name,
      questions: group.questions.filter(x => !selectedQuestions.some(y => y.id === x.id))
    })).filter(x => x.questions.length > 0);
    setAvailableDropdownOptions(newState);
  }, [selectedQuestions]);

  const handleQuestionChange = (selectedValue: string | null) => {
    if (selectedValue && selectedValue != "default") {
      // Find the selected question within questions 
      const question = questionGroups.flatMap((x) => x.questions).find(x => x.id === selectedValue);
      if (question) {
        onAddQuestion(tabIndex, question.id, question.text);
        setSelectedValue("default"); // Reset the dropdown
      }
    }
  }

  return (
    <>
      {/* Only render if we have available options to select from */}
      {availableDropdownOptions.length > 0 && (
        <select
          className="block w-full text-sm rounded-md focus:outline-none focus:ring-0 border-1"
          value={selectedValue ? selectedValue : undefined}
          onChange={(e) => handleQuestionChange(e.target.value)}
        >
          <option className="text-gray-600" value="default">
            {t("Common.DropdownDefaultItem")}
          </option>

          {/* Only one group so don't need to render the optgroups */}
          {availableDropdownOptions.length === 1 && (
            <>
              {availableDropdownOptions.map((group, ix) => (
                <>
                  {group.questions.map((question, ix) => (
                    <option
                      className="text-gray-600"
                      key={`opt_${question.id}_${ix}`}
                      value={question.id}
                    >
                      {question.text}
                    </option>
                  ))}
                </>
              ))}
            </>
          )}

          {/* Multiple question groups so render the optgroups */}
          {availableDropdownOptions.length > 1 && (
            <>
              {availableDropdownOptions.map((group, ix) => (
                <optgroup key={`group_${group.name}_${ix}`} label={group.name}>
                  {group.questions.map((question, ix) => (
                    <option
                      className="text-gray-600"
                      key={`opt_${question.id}_${ix}`}
                      value={question.id}
                    >
                      {question.text}
                    </option>
                  ))}
                </optgroup>
              ))}
            </>
          )}
        </select>
      )}
    </>
  );
};

export default CustomExportAddQuestion;
