import { useTranslation } from "react-i18next";
import { GenericDropDownList, Label, TextInput } from "../common";
import { JourneyExportRequestQuestion, JourneyExportRequestTab, JourneyExportRequestTabType } from "../../types/analytics/custom-exports/JourneyExportRequestDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { KeyValuePair } from "../../types/generic";
import JourneyExportAvailabileOptions, { JourneyExportAvailableQuestionGroup } from "../../types/analytics/custom-exports/JourneyExportAvailabileOptions";
import CustomExportAddQuestion from "./CustomExportAddQuestion";
import DraggableList from "react-draggable-list";
import CustomExportDraggableQuestion from "./CustomExportDraggableQuestion";
import { useEffect, useState } from "react";

const maxTabNameLength = 31;
const ALPHA_NUMERIC_DASH_SPACE_REGEX = /^[a-zA-Z0-9-+/& ]+$/;

interface CustomExportTabProps {
  index: number;
  tab: JourneyExportRequestTab;
  allowSequenceChange: boolean;
  availableOptions: JourneyExportAvailabileOptions | undefined;
  tabTypeOptions: KeyValuePair<string, string>[];
  setTabTitle(index: number, title: string): void;
  setTabType(index: number, tabType: JourneyExportRequestTabType | null, taskTypeId: string | null): void;
  onDeleteTab(index: number): void;
  onIncreaseTabSequence(index: number): void;
  onDecreaseTabSequence(index: number): void;
  onAddQuestion(tabIndex: number, questionId: string, questionText: string): void;
  onRemoveQuestion(tabIndex: number, questionId: string): void;
  onQuestionListChange(tabIndex: number, newList: JourneyExportRequestQuestion[]): void;
}

const CustomExportTab = ({
  index,
  tab,
  allowSequenceChange,
  availableOptions,
  tabTypeOptions,
  setTabTitle,
  setTabType,
  onDeleteTab,
  onIncreaseTabSequence,
  onDecreaseTabSequence,
  onAddQuestion,
  onRemoveQuestion,
  onQuestionListChange
}: CustomExportTabProps) => {
  const { t } = useTranslation();

  const [selectedTabType, setSelectedTabType] = useState<string>("default");

  useEffect(() => {
    if (tab.type) {
      if (tab.type === "ADVANCED-TASK-REVIEW") {
        setSelectedTabType(`${tab.type}_${tab.advancedTaskTypeId}`);
      } else {
        setSelectedTabType(tab.type);
      }
    }
  }, [tab]);

  const onChevronUpClick = () => {
    onIncreaseTabSequence(index);
  };

  const onChevronDownClick = () => {
    onDecreaseTabSequence(index);
  };

  const checkInputForAlphaNumeric = (index: number, text: string) => {
    // If length is 0 or the text is alpha numeric, dash or space then set the title
    if (text.length === 0 || ALPHA_NUMERIC_DASH_SPACE_REGEX.test(text)) {
      setTabTitle(index, text);
    }
  }

  const handleTabTypeChange = (index: number, selectedValue: string | null) => {
    if (selectedValue) {
      if (selectedValue === "default") {
        setTabType(index, null, null);
      } else {
        var selectedValueParts = selectedValue.split("_");

        // Pull out the selectedType... handle slightly different if its an Advanced Task Review
        const selectedType = selectedValueParts[0] === "ADVANCED-TASK-REVIEW"
          ? availableOptions?.tabTypes.find((x) => x.type === selectedValueParts[0] && x.advancedTaskTypeId === selectedValueParts[1])
          : availableOptions?.tabTypes.find((x) => x.type === selectedValue);
        if (selectedType) {
          setTabType(index, selectedType.type, selectedType.advancedTaskTypeId ?? null);
        }
      }
    }
  }

  const handleRemoveQuestionClick = (question: JourneyExportRequestQuestion) => {
    onRemoveQuestion(index, question.id);
  };

  const handleQuestionListChange = (questions: JourneyExportRequestQuestion[]) => {
    onQuestionListChange(index, questions);
  };

  return (
    <div className="px-4 p-2 pb-4 mb-2 bg-gray-50 items-center rounded-md border border-gray-200">
      {/* Delete Tab Icon */}
      <div className="text-right">
        <span className="text-rose-500" onClick={() => onDeleteTab(index)}>
          <FontAwesomeIcon className="hover:cursor-pointer" icon={faTrashAlt}></FontAwesomeIcon>
        </span>
      </div>
      <div className="flex flex-row">
        {/* Sequence Icons */}
        {allowSequenceChange && (
          <div className="flex flex-col pr-4">
            <FontAwesomeIcon
              className="hover:cursor-pointer"
              icon={faChevronUp}
              onClick={onChevronUpClick}></FontAwesomeIcon>
            <span className="text-center">{index + 1}</span>
            <FontAwesomeIcon
              className="hover:cursor-pointer"
              icon={faChevronDown}
              onClick={onChevronDownClick}></FontAwesomeIcon>
          </div>
        )}

        {/* Tab Content */}
        <div className="grow pl-2">
          <div className="flex flex-row gap-4">
            <div className="basis-1/2">
              <Label
                text={t("Pages.Analytics.Exports.CustomPopup.Fields.Title") + ":"}
              />
              <TextInput
                value={tab.name}
                onChange={(e) => checkInputForAlphaNumeric(index, e)}
                className="p-2 w-full text-sm rounded-md border border-gray-400"
                maxLength={maxTabNameLength}
              />
            </div>
            <div className="basis-1/2">
              <Label
                text={t("Pages.Analytics.Exports.CustomPopup.Fields.Type") + ":"}
              />
              <GenericDropDownList
                currentValue={selectedTabType}
                items={tabTypeOptions}
                onChange={(e) => handleTabTypeChange(index, e)}
                className="block w-full text-sm"
                isReadOnly={false}
                includeSelectOptionWithStringDefaultValue={true}
                applyBorder={true}
              />
            </div>
          </div>
          {tab.type === "GENERIC" && (
            <div className="w-full pt-1.5">
              <Label
                text={t("Pages.Analytics.Exports.CustomPopup.Fields.Questions") + ":"}
              />
              <div>
                {tab.questions.length > 0 && (
                  <DraggableList
                    list={tab.questions}
                    itemKey="id"
                    template={CustomExportDraggableQuestion}
                    onMoveEnd={(newList: any) => handleQuestionListChange(newList)}
                    commonProps={handleRemoveQuestionClick}
                  />
                )}
                <CustomExportAddQuestion
                  tabIndex={index}
                  questionGroups={availableOptions?.questionGroups ?? []}
                  selectedQuestions={tab.questions}
                  onAddQuestion={onAddQuestion}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomExportTab;
