import cx from "classnames";

interface ScrollableModalContentTemplateProps {
  /** The body element to show in a scrollable container */
  children: JSX.Element;
  /** The header element, to display before the scrollable area */
  header?: JSX.Element | undefined;
  /** The footer element, to display below the scrollable area, usually buttons */
  footer?: JSX.Element | undefined;
  /** Pass a ref object if you want to scroll to top on failed submit click (e.g. validation errors) etc */
  bodyRef?: React.RefObject<HTMLDivElement> | undefined;
  containerClassName?: string;
}

/** A container with an optional footer for displaying scrollable popup content */
export const ScrollableModalContentTemplate = ({
  children,
  header = undefined,
  footer = undefined,
  bodyRef = undefined,
  containerClassName = undefined
}: ScrollableModalContentTemplateProps) => {
  return (
    <div className={
      cx(containerClassName != undefined ? containerClassName : "flex flex-col gap-3 md:gap-4")
    }>
      {header}
      <div
        className="overflow-y-auto pr-2"
        ref={bodyRef}
      >
        {children}
      </div>
      {footer}
    </div>
  );
};

export default ScrollableModalContentTemplate;
