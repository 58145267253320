import { ValidationResult } from "../../../../types/forms";
import ValidationWarning from "../../../common/ValidationWarning";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { t } from "i18next";
import Tabs, { TabDetails } from "../../../common/Tabs";
import { useAuth } from "react-oidc-context";
import AppraisalLevelSelection from "./AppraisalLevelSelection";
import LocationSelection from "./LocationSelection";
import SpecificUserSelection from "./SpecificUserSelection";
import UserIdsSelection from "./UserIdsSelection";
import { useState } from "react";
import CheckboxTreeNodeDto from "../../../../types/dtos/generic/CheckboxTreeNodeDto";
import CheckboxTreeApiResponseDto from "../../../../types/dtos/admin/CheckboxTreeApiResponseDto";
import AudienceTabType from "../../../../types/admin/AudienceTabType";
import PeoplePickerUserDto from "../../../../types/dtos/generic/PeoplePickerUserDto";
import InfoAlert from "../../../alerts/InfoAlert";
import appraisalLevelApi from "../../../../api/dashboard/appraisalLevelApi";
import locationApi from "../../../../api/dashboard/locationApi";
import { Label, Switch } from "../../../common";

interface AudienceSelectionProps {
  onAudienceChange(newState: AudienceTabType): void;
  appraisalLevelTreeChecked: string[];
  appraisalLevelTreeExpanded: string[];
  onAppraisalLevelTreeChecked(newState: string[]): void;
  onAppraisalLevelTreeExpanded(newState: string[]): void;
  locationTreeChecked: string[];
  locationTreeExpanded: string[];
  onLocationTreeChecked(newState: string[]): void;
  onLocationTreeExpanded(newState: string[]): void;
  onSpecificUserChange(newState: PeoplePickerUserDto[]): void;
  specificUserSelectedUsers: PeoplePickerUserDto[];
  userIdSwitch: boolean;
  userIdsTextAreaValue: string;
  onUserIdsSwitchChange(newState: boolean): void;
  onUserIdsTextAreaValueChange(newState: string): void;
  excludeInductions: boolean;
  onExcludeInductionsChange(value: boolean): void;

  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
}

const AudienceSelection = ({
  onAudienceChange,
  appraisalLevelTreeChecked,
  appraisalLevelTreeExpanded,
  onAppraisalLevelTreeChecked,
  onAppraisalLevelTreeExpanded,
  locationTreeChecked,
  locationTreeExpanded,
  onLocationTreeChecked,
  onLocationTreeExpanded,
  onSpecificUserChange,
  specificUserSelectedUsers,
  userIdSwitch,
  userIdsTextAreaValue,
  onUserIdsSwitchChange,
  onUserIdsTextAreaValueChange,
  excludeInductions,
  onExcludeInductionsChange,
  showValidationErrors = false,
  validationResult = null,
}: AudienceSelectionProps) => {
  const [tabHasBeenClicked, setTabHasBeenClicked] = useState<boolean>(false);
  const [appraisalLevels, setappraisalLevels] =
    useState<CheckboxTreeNodeDto[]>();
  const [isAppraisalLevelsLoading, setIsAppraisalLevelsLoading] =
    useState<boolean>(false);
  const [locations, setLocations] = useState<CheckboxTreeNodeDto[]>();
  const [isLocationsLoading, setIsLocationsLoading] = useState<boolean>(false);
  const auth = useAuth();
  const appLevelApi = new appraisalLevelApi(auth.user?.access_token);
  const locApi = new locationApi(auth.user?.access_token);

  const handleAudienceChange = (newState: AudienceTabType) => {
    setTabHasBeenClicked(true);
    onAudienceChange(newState);
  };

  const onAppraisalLevelTabClick = (): void => {
    handleAudienceChange("APPRAISAL-LEVEL");
    if (appraisalLevels === undefined) {
      setIsAppraisalLevelsLoading(true);

      // Call the API to load the necessary state
      const successCallback = (data: CheckboxTreeApiResponseDto) => {
        translateNodes(data.nodes);
        setappraisalLevels(data.nodes);
        setIsAppraisalLevelsLoading(false);
      };

      const errorCallback = (error: any) => {
        console.error(error);
      };

      appLevelApi.getAppraisalLevelCheckboxTreeItemsByClientId(
        successCallback,
        errorCallback
      );
    }
  };

  const onLocationTabClick = (): void => {
    handleAudienceChange("LOCATION");
    if (locations === undefined) {
      setIsLocationsLoading(true);

      // Call the API to load the necessary state
      const successCallback = (data: CheckboxTreeApiResponseDto) => {
        setLocations(data.nodes);
        setIsLocationsLoading(false);
      };

      const errorCallback = (error: any) => {
        console.error(error);
      };

      locApi.getLocationCheckboxTreeItemsByClientId(
        successCallback,
        errorCallback
      );
    }
  };

  const translateNodes = (nodes: CheckboxTreeNodeDto[]) => {
    nodes.forEach((node) => {
      node.label = t(node.label);
      if (node.children !== undefined && node.children !== null) {
        translateNodes(node.children);
      }
    });
  };

  const tabs: Array<TabDetails> = [
    {
      title: t("Common.AppraisalLevels"),
      content: (
        <AppraisalLevelSelection
          nodes={appraisalLevels}
          treeChecked={appraisalLevelTreeChecked}
          treeExpanded={appraisalLevelTreeExpanded}
          onTreeChecked={onAppraisalLevelTreeChecked}
          onTreeExpanded={onAppraisalLevelTreeExpanded}
          isLoading={isAppraisalLevelsLoading}
          labelTranslationKey="Pages.SendNow.Fields.SelectAppraisalLevel"
        />
      ),
      displayAlertIcon: false,
      onClickEvent: onAppraisalLevelTabClick,
    },
    {
      title: t("Common.Locations"),
      content: (
        <LocationSelection
          nodes={locations}
          treeChecked={locationTreeChecked}
          treeExpanded={locationTreeExpanded}
          onTreeChecked={onLocationTreeChecked}
          onTreeExpanded={onLocationTreeExpanded}
          isLoading={isLocationsLoading}
          labelTranslationKey="Pages.SendNow.Fields.SelectLocation"
        />
      ),
      displayAlertIcon: false,
      onClickEvent: onLocationTabClick,
    },
    {
      title: t("Common.SpecificUsers"),
      content: (
        <SpecificUserSelection
          onPeoplePickerChange={onSpecificUserChange}
          peoplePickerSelectedUsers={specificUserSelectedUsers}
        />
      ),
      displayAlertIcon: false,
      onClickEvent: () => handleAudienceChange("SPECIFIC-USER"),
    },
    {
      title: t("Common.UserIds"),
      content: (
        <UserIdsSelection
          checked={userIdSwitch}
          onSwitchChange={onUserIdsSwitchChange}
          textAreaValue={userIdsTextAreaValue}
          onTextAreaValueChange={onUserIdsTextAreaValueChange}
        />
      ),
      displayAlertIcon: false,
      onClickEvent: () => handleAudienceChange("USER-IDS"),
    },
    {
      title: t("Common.Everyone"),
      content: (
        <div className="pt-2">
          <InfoAlert
            prefix={""}
            message={t("Pages.SendNow.Fields.SelectEveryone")}
          />
        </div>
      ),
      displayAlertIcon: false,
      onClickEvent: () => handleAudienceChange("EVERYONE"),
    },
  ];

  return (
    <>
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      <Tabs
        tabs={tabs}
        selectedTabClassNames="radix-state-active: bg-[#EFEFF0] hover:!bg-[#EFEFF0] rounded-md"
        selectFirstTabByDefault={false}
      />
      {tabHasBeenClicked && (
        <div>
          <div className="mt-2 flex flex-row gap-2">
            <div className="flex-initial">
              <Switch
                checked={excludeInductions}
                onChange={onExcludeInductionsChange}
              />
            </div>
            <Label
              text={t("Pages.SendNow.Fields.ExcludeOnInduction")}
              className="flex-grow"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AudienceSelection;
