import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { advancedTaskHelper, userDetailsHelper } from "../../helpers";
import ManagerDashboardPersonDto from "../../types/dtos/dashboards/ManagerDashboardPersonDto";
import ManagerDashboardUpdateDto from "../../types/dtos/dashboards/ManagerDashboardUpdateDto";
import { Badge, FormattedDate } from "../common";
import { ClientTaskType } from "../../types/dtos/tasks/advanced-tasks/ClientTaskType";

interface IndividualUpdateProps {
  users: ManagerDashboardPersonDto[];
  update: ManagerDashboardUpdateDto;
  taskType?: ClientTaskType | undefined;
}

function IndividualUpdate({
  users,
  update,
  taskType = undefined,
}: IndividualUpdateProps) {
  const { t } = useTranslation();

  const updateTimestamp = dayjs(update.timestamp);
  const todaysDate = dayjs().startOf("day");
  const weekAfterDate = todaysDate.add(7, "day");

  const displayName = userDetailsHelper.getDisplayName(update.userId, users);

  let descriptorBadgeText: string = "";
  let taskTypePluralName: string = "";

  switch (update.updateType) {
    case "COLLAB-DOC":
    case "DUAL-PREP-MEETING":
      descriptorBadgeText = "Meeting";
      break;
    case "DUAL-PREP-PLANNING":
      descriptorBadgeText = "Planning";
      break;
    case "GENERAL-CATCH-UP":
      descriptorBadgeText = "Catch Up";
      break;
    case "ADVANCED-TASKS-UPDATED-BY-EMPLOYEE":
      if (update.advancedTaskTypeId && taskType) {
        taskTypePluralName = advancedTaskHelper.ToLowerCase(
          taskType.pluralNameTranslationKeyIdentifier
        );
        descriptorBadgeText = t(taskType.pluralNameTranslationKeyIdentifier);
      }
      break;
    default:
      break;
  }

  const isManagerOnlyApprovalFlow =
    update.approvalFlow === "MANAGER-ONLY-APPROVAL";

  return (
    <div className="flex flex-col gap-y-1 lg:gap-y-0 lg:flex-row w-full">
      <div className="lg:mr-2">
        <Badge
          text={descriptorBadgeText}
          textColourClassName="text-gray-400"
          marginClassName=""
          classNames="!inline-block"
        />
      </div>
      {update.updateType === "COLLAB-DOC" && update.needsManagerAttention && (
        <>
          {update.status === "SAVED-BY-MANAGER-WITHOUT-APPROVING" ||
          (isManagerOnlyApprovalFlow &&
            update.status === "RETURNED-BY-MANAGER") ||
          (update.approvalFlow === "HIDDEN-TO-EMPLOYEE" &&
            update.status === "INITIAL-SAVE-FORM-INCOMPLETE") ? (
            <>
              <div>
                {" "}
                You need to complete and sign-off your updates on the{" "}
                <span className="font-semibold">
                  {t(update.clientFormTitle)}
                </span>{" "}
                Journey for <span className="font-semibold">{displayName}</span>
              </div>
              <BadgesAndGoButton
                update={update}
                updateTimestamp={updateTimestamp}
                todaysDate={todaysDate}
                weekAfterDate={weekAfterDate}
                buttonText="View updates"
              />
            </>
          ) : (
            <>
              <div>
                <span className="font-semibold">{displayName}</span> has updated
                their{" "}
                <span className="font-semibold">
                  {t(update.clientFormTitle)}
                </span>
              </div>
              <BadgesAndGoButton
                update={update}
                updateTimestamp={updateTimestamp}
                todaysDate={todaysDate}
                weekAfterDate={weekAfterDate}
                buttonText="Review their answers"
              />
            </>
          )}
        </>
      )}
      {update.updateType === "COLLAB-DOC" && !update.needsManagerAttention && (
        <>
          <div>
            <span className="font-semibold">{displayName}</span> needs to review
            changes made to{" "}
            <span className="font-semibold">{t(update.clientFormTitle)}</span>
          </div>
          <BadgesAndGoButton
            update={update}
            updateTimestamp={updateTimestamp}
            todaysDate={todaysDate}
            weekAfterDate={weekAfterDate}
          />
        </>
      )}
      {/* General Catch-up - Not Started */}
      {update.updateType === "GENERAL-CATCH-UP" &&
        update.status === "INITIAL-SAVE-FORM-INCOMPLETE" && (
          <>
            <div>
              Catch up between you and{" "}
              <span className="font-semibold">{displayName}</span> with the
              subject{" "}
              <span className="font-semibold">{t(update.clientFormTitle)}</span>{" "}
              on{" "}
              <FormattedDate
                displayMode="DATE-AND-TIME"
                convertFromUtc={true}
                date={update.timestamp}
              />
            </div>
            <BadgesAndGoButton
              update={update}
              updateTimestamp={updateTimestamp}
              todaysDate={todaysDate}
              weekAfterDate={weekAfterDate}
              buttonText="Start catch-up"
            />
          </>
        )}
      {/* General Catch-up - In Progress - Needs manager attention */}
      {update.updateType === "GENERAL-CATCH-UP" &&
        update.status !== "INITIAL-SAVE-FORM-INCOMPLETE" && (
          <>
            <div>
              Catch up updated:{" "}
              <span className="font-semibold">{t(update.clientFormTitle)}</span>{" "}
              with <span className="font-semibold">{displayName}</span> awaiting
              approval from you
            </div>
            <BadgesAndGoButton
              update={update}
              updateTimestamp={updateTimestamp}
              todaysDate={todaysDate}
              weekAfterDate={weekAfterDate}
              buttonText="View details"
            />
          </>
        )}
      {update.updateType === "DUAL-PREP-PLANNING" && (
        <>
          <div>
            {" "}
            <span className="font-semibold">
              {t(update.clientFormTitle)}
            </span>{" "}
            planning needed for{" "}
            <span className="font-semibold">{displayName}</span>
          </div>
          <BadgesAndGoButton
            update={update}
            updateTimestamp={updateTimestamp}
            todaysDate={todaysDate}
            weekAfterDate={weekAfterDate}
            buttonText="Complete planning"
          />
        </>
      )}
      {update.updateType === "DUAL-PREP-MEETING" && (
        <>
          <div>
            <span className="font-semibold">{t(update.clientFormTitle)}</span>{" "}
            meeting can now be completed for{" "}
            <span className="font-semibold">{displayName}</span>
          </div>
          <BadgesAndGoButton
            update={update}
            updateTimestamp={updateTimestamp}
            todaysDate={todaysDate}
            weekAfterDate={weekAfterDate}
            buttonText="Complete meeting"
          />
        </>
      )}
      {update.updateType === "ADVANCED-TASKS-UPDATED-BY-EMPLOYEE" && (
        <>
          <div>
            <span className="font-semibold">{displayName}</span> has updated
            their {taskTypePluralName}
          </div>
          <BadgesAndGoButton
            update={update}
            updateTimestamp={updateTimestamp}
            todaysDate={todaysDate}
            weekAfterDate={weekAfterDate}
            buttonText={`View ${taskTypePluralName}`}
          />
        </>
      )}
    </div>
  );
}

const BadgesAndGoButton = ({
  buttonText = undefined,
  update,
  updateTimestamp,
  todaysDate,
  weekAfterDate,
}: {
  update: ManagerDashboardUpdateDto;
  updateTimestamp: dayjs.Dayjs;
  todaysDate: dayjs.Dayjs;
  weekAfterDate: dayjs.Dayjs;
  buttonText?: string | undefined;
}) => {
  return (
    <>
      <TimeBadge
        update={update}
        updateTimestamp={updateTimestamp}
        todaysDate={todaysDate}
        weekAfterDate={weekAfterDate}
      />
      {buttonText ? (
        <div className="flex-grow text-right">
          <span className="hover:cursor-pointer group primary-bg text-white px-2 py-1 rounded-sm hover:opacity-90 hover:drop-shadow-sm">
            {buttonText}
            <FontAwesomeIcon icon={faArrowRight} size="xs" className="ml-1" />
          </span>
        </div>
      ) : null}
    </>
  );
};

const TimeBadge = ({
  update,
  updateTimestamp,
  todaysDate,
  weekAfterDate,
}: {
  update: ManagerDashboardUpdateDto;
  updateTimestamp: dayjs.Dayjs;
  todaysDate: dayjs.Dayjs;
  weekAfterDate: dayjs.Dayjs;
}) => {
  const marginClassNames = "lg:mx-2";
  return (
    <div>
      {update.updateType === "GENERAL-CATCH-UP" && (
        <>
          {" "}
          {updateTimestamp < todaysDate ? (
            <Badge
              text="Overdue"
              backgroundColourClassName="bg-rose-100"
              textColourClassName="text-rose-700"
              marginClassName={marginClassNames}
            />
          ) : updateTimestamp > todaysDate &&
            updateTimestamp < weekAfterDate ? (
            <Badge
              text="Due Soon"
              backgroundColourClassName="bg-amber-100"
              textColourClassName="text-amber-700"
              marginClassName={marginClassNames}
            />
          ) : (
            <Badge
              text="New"
              backgroundColourClassName="bg-green-100"
              textColourClassName="text-green-700"
              marginClassName={marginClassNames}
            />
          )}
        </>
      )}
      {update.updateType !== "GENERAL-CATCH-UP" && (
        <>
          {" "}
          <Badge
            text={updateTimestamp.startOf("day").from(todaysDate)}
            backgroundColourClassName="bg-transparent"
            textColourClassName="text-slate-400"
            borderRadiusClassName="rounded"
            marginClassName={marginClassNames}
            classNames="!px-0"
          />
        </>
      )}
    </div>
  );
};

export default IndividualUpdate;
