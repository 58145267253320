import { useContext, useEffect, useState } from "react";
import UserContext from "../../../../state/UserContext";
import CollabDocFlaggedChangeDto from "../../../../types/dtos/collab-docs/CollabDocFlaggedChangeDto";
import { BaseCommentDto } from "../../../../types/dtos/forms";
import {
  BaseUserDetailsDto,
  UserBasicDetailsDto,
} from "../../../../types/dtos/generic";
import {
  FormQuestion,
  FormType,
  GoalReviewQuestionAnswerValue,
  GoalReviewStatusCommentValidationResult,
  QuestionAnswer,
} from "../../../../types/forms";
import GoalReviewAccordion from "./GoalReviewAccordion";

interface GoalReviewQuestionStatusAndCommentProps {
  question: FormQuestion;
  currentValues: GoalReviewQuestionAnswerValue[] | null;
  isReadOnly: boolean;
  formType: FormType;
  /** Who the form is regarding. Used to determine attribute text, e.g. "How is Chris doing?" before a slider */
  subjectUser: BaseUserDetailsDto;
  /** Whether or not to display the validation warnings */
  showValidationErrors: boolean;
  /** For persisting the value in the parent state */
  onValueChange(newValue: GoalReviewQuestionAnswerValue[]): void;
  /** For saving via the API */
  onValueSave(
    goalAnswer: GoalReviewQuestionAnswerValue[],
    nonStateValue?: QuestionAnswer | null
  ): void;
  /** The values for the enforced comments, if there are any */
  enforcedComments: BaseCommentDto[];
  /** Update the state with the new comment value */
  onEnforcedCommentChange(
    goalId: number,
    newValue: string | null,
    clientFormId: number
  ): void;
  /** Persist the value for the goal comment via the API */
  onEnforcedCommentBlur(goalId: number, newValue: string | null): void;
  /** Recent form answer change to highlight to the user, if there is one */
  flaggedChanges?: CollabDocFlaggedChangeDto[] | undefined;
  /** Participant details are used in collab docs for flagged changes */
  participants?: Array<UserBasicDetailsDto> | undefined;
  showPlanningResponses: boolean | null;
  onPlanningResponseCopyClick?(newValue: string, goalId: number): void;
  isPrinting: boolean;
}

/** A goal status review question, with support for a comment as well */
function GoalReviewQuestionStatusAndComment({
  question,
  isReadOnly,
  currentValues,
  onValueChange,
  onValueSave,
  enforcedComments,
  onEnforcedCommentChange,
  onEnforcedCommentBlur,
  subjectUser,
  showValidationErrors,
  formType,
  flaggedChanges,
  participants,
  showPlanningResponses,
  isPrinting,
  onPlanningResponseCopyClick,
}: GoalReviewQuestionStatusAndCommentProps) {
  // Context
  const userContext = useContext(UserContext);

  // State
  const [reviewedGoalIds, setReviewedGoalIds] = useState<number[]>([]);

  // Side effects
  useEffect(() => {
    // Run the validation to get the reviewed goal ids
    const validationResult = question.validate(
      currentValues,
      null,
      null,
      enforcedComments,
      userContext.user.id,
      subjectUser.userId,
      formType,
      false
    ) as GoalReviewStatusCommentValidationResult;
    const validGoalIds = validationResult.validGoalIds
      ? validationResult.validGoalIds
      : [];
    setReviewedGoalIds(validGoalIds);
  }, [question, currentValues, enforcedComments]);

  // Pre-render logic checks
  if (
    !question.goalReviewOptions ||
    !question.goalReviewOptions.goals ||
    !question.goalReviewOptions.goalStatusOptions ||
    question.goalReviewOptions.goals.length === 0 ||
    question.goalReviewOptions.goalStatusOptions.length === 0
  ) {
    return null;
  }

  return (
    <GoalReviewAccordion
      question={question}
      reviewedGoalIds={reviewedGoalIds}
      goalReviewType={"STATUS-AND-COMMENT"}
      isReadOnly={isReadOnly}
      showValidationErrors={showValidationErrors}
      formType={formType}
      currentValues={currentValues}
      onValueChange={onValueChange}
      onValueBlur={onValueSave}
      enforcedComments={enforcedComments}
      onEnforcedCommentChange={onEnforcedCommentChange}
      onEnforcedCommentBlur={onEnforcedCommentBlur}
      flaggedChanges={flaggedChanges}
      participants={participants}
      subjectUser={subjectUser}
      showPlanningResponses={showPlanningResponses}
      onPlanningResponseCopyClick={onPlanningResponseCopyClick}
      isPrinting={isPrinting}
    />
  );
}

export default GoalReviewQuestionStatusAndComment;
