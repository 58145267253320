import download from "downloadjs";
import ExportableJourneyItem from "../../types/analytics/custom-exports/ExportableJourneyItem";
import { apiClient } from "../apiClient";
import JourneyExportAvailabileOptions from "../../types/analytics/custom-exports/JourneyExportAvailabileOptions";
import JourneyExportRequestDetails from "../../types/analytics/custom-exports/JourneyExportRequestDetails";
import IsExportReadyResponseDto from "../../types/exports/IsExportReadyResponseDto";
import ExportGenerationRequest from "../../types/exports/ExportGenerationRequest";

class exportsApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  /** Get the filter values available to the logged in user */
  getExportableJourneys(
    onSuccess: (data: ExportableJourneyItem[]) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "analytics/exports/exportable-journeys"
    ).then(
      (data) => {
        const filterValues = data as ExportableJourneyItem[];
        onSuccess(filterValues);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Gets the available dropdown options for custom exports (e.g. available sections and questions) */
  getAvailableOptionsForCustomExport(
    journeyRefs: string[],
    onSuccess: (data: JourneyExportAvailabileOptions) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "analytics/exports/get-available-custom-export-options",
      {
        body: JSON.stringify(journeyRefs),
      }
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  queueCustomExportForGeneration(
    request: JourneyExportRequestDetails,
    onSuccess: (data: string) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "analytics/exports/queue-custom-export-for-generation", 
      {
        body: JSON.stringify(request),
      },
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  queueAdvancedTaskDetailExportForGeneration(
    request: ExportGenerationRequest,
    onSuccess: (data: string) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "analytics/exports/queue-advanced-task-detail-export-for-generation", 
      {
        body: JSON.stringify(request),
      },
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  isExportReadyToDownload(
    exportId: string,
    onSuccess: (data: IsExportReadyResponseDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "analytics/exports/is-export-ready-to-download?exportId=" + exportId
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  downloadExport(
    exportId: string,
    filenameWithoutExtension: string,
    onSuccess: () => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "analytics/exports/download-export?exportId=" + exportId,
      undefined,
      "BLOB"
    ).then(
      (blob) => {
        onSuccess();
        download(
          blob,
          `${filenameWithoutExtension}.xlsx`,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      },
      (error) => {
        onError(error);
        console.log(error);
      }
    );
  }
}

export default exportsApi;
