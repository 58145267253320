export type ValidationErrorType =
  | "REQUIRED"
  | "MIN"
  | "MAX"
  | "MIN-AND-MAX"
  | "SELECT-EXACT"
  | "TOO-LONG"
  | "INVALID-HEX"
  | "ACCESSIBILITY-ISSUE"
  | "NON-UNIQUE_APP_LEVEL_OVERRIDE"
  | "NUMBER-MISMATCH"
  | "ATLEAST-ONE-SECTION"
  | "ILLEGAL-CHARACTER"
  | "CUSTOM-TEXT-REQUIRED"
  | "INVALID-DATE"
  | "TASKS-REMAIN-OPEN"
  | "FAILED-TASK-RESTRICTIONS"
  | "FAILED-ENFORCED-TASK-COMMENT"
  | "TASK-REQUIRES-REVIEW-FORM-RESPONSE"
  | "CUSTOM-TEXT-FOR-SELECTED-OPTION-REQUIRED"
  | "OVERDUE-TASKS"
  | "EXPECTED-SUBMIT-VALUE-NOT-SET";

export interface ValidationError {
  errorType: ValidationErrorType;
  subType?: undefined | "Goals";
  min?: number | undefined;
  max?: number | undefined;
  current?: number | undefined;
  expectedValue?: ExpectedValueValidationTokenDetails | undefined;
}

export class ValidationResult {
  constructor(isValid: boolean, errors: Array<ValidationError> = []) {
    this.isValid = isValid;
    this.errors = errors;
  }

  isValid: boolean;
  errors: Array<ValidationError>;
}

export interface ExpectedValueValidationTokenDetails {
  translate: boolean;
  value: string;
}

export default ValidationResult;
