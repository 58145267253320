import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { UserTaskDetail } from "../../../../types/dtos/tasks/advanced-tasks/UserTaskDetail";
import AdvancedTaskCommonFieldSection from "../AdvancedTaskCommonFieldSection";
import ScrollableModalContentTemplate from "../../../common/ScrollableModalContentTemplate";
import UserContext from "../../../../state/UserContext";
import {
  SimpleFormAnswer,
  SimpleFormValidationError,
} from "../../../../types/dtos/simple-forms";
import { UserTaskSaveResponse } from "../../../../types/dtos/tasks/advanced-tasks/api-response/UserTaskSaveResponse";
import { ClientTaskTypeCategory } from "../../../../types/dtos/tasks/advanced-tasks/ClientTaskTypeCategory";
import { ClientTaskType } from "../../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import { advancedTaskHelper, simpleFormHelper } from "../../../../helpers";
import DangerAlert from "../../../alerts/DangerAlert";
import { AdvancedTaskMainDetailsValidationResult } from "../../../../types/tasks/AdvancedTaskMainDetailsValidationResult";
import SimpleForm from "../../../simple-forms/editable/SimpleForm";
import { TaskEditPopupUsageScenario } from "../../../../types/tasks/TaskEditPopupUsageScenario";

interface AdvancedTaskBodyFormViewProps {
  taskType: ClientTaskType | null;
  details: UserTaskDetail;
  isReadOnly: boolean;
  categories: ClientTaskTypeCategory[];
  /** The state variable containing the current values for body form answers */
  bodyFormAnswers: SimpleFormAnswer[];
  taskIsSaving: boolean;
  showValidationErrors: boolean;
  showRestrictionError: boolean;
  bodyFormValidationErrors: SimpleFormValidationError[] | null;
  mainDetailsValidationResult: AdvancedTaskMainDetailsValidationResult;
  scrollableContainerRef: React.RefObject<HTMLDivElement>;
  setMainDetailsValidationResult: (
    validationResult: AdvancedTaskMainDetailsValidationResult
  ) => void;
  setDetails(details: UserTaskDetail): void;
  onTaskSave(
    successCallback: (data: UserTaskSaveResponse) => void,
    errorCallback: (error: any) => void
  ): void;
  onSuccessfulUpdate(): void;
  onBodyFormAnswerChange(newAnswer: SimpleFormAnswer): void;
  usageScenario: TaskEditPopupUsageScenario;
}

export const AdvancedTaskBodyFormView = ({
  taskType,
  details,
  isReadOnly,
  categories,
  bodyFormAnswers,
  taskIsSaving,
  showValidationErrors,
  mainDetailsValidationResult,
  scrollableContainerRef,
  showRestrictionError,
  bodyFormValidationErrors,
  setMainDetailsValidationResult,
  setDetails,
  onSuccessfulUpdate,
  onBodyFormAnswerChange,
  onTaskSave,
  usageScenario,
}: AdvancedTaskBodyFormViewProps) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);

  const [showBodyFormForClosedTask, setShowBodyFormForClosedTask] =
    useState<boolean>(false);

  useEffect(() => {
    // Always expand the details section for JOURNEY-COLLAB-DOC
    if (usageScenario === "JOURNEY-COLLAB-DOC") {
      setShowBodyFormForClosedTask(true);
    }
  }, [usageScenario]);

  const onCategoryChange = (value: string) => {
    if (details != null) {
      const newDetails: UserTaskDetail = { ...details };
      newDetails.categoryId = value;
      setDetails(newDetails);
    }
  };

  const onTargetDateChange = (value: Date) => {
    if (details != null) {
      const newDetails: UserTaskDetail = { ...details };
      newDetails.targetDate = value;
      setDetails(newDetails);
    }
  };

  const onTitleChange = (value: string) => {
    if (details != null) {
      const newDetails: UserTaskDetail = { ...details };
      newDetails.title = value;
      setDetails(newDetails);
    }
  };

  const toggleShowBodyFormForClosedTask = () => {
    setShowBodyFormForClosedTask(!showBodyFormForClosedTask);
  };

  const saveTaskChanges = () => {
    const onError = (error: any) => {
      console.error("Error updating task", error);
    };

    // Call the API
    onTaskSave(onSuccessfulUpdate, onError);
  };

  const footerButtons = (
    <>
      {!isReadOnly && (
        <div className="flex flex-row justify-end">
          <button
            className="btn-primary"
            onClick={saveTaskChanges}
            disabled={taskIsSaving}
          >
            {t("TaskType.Popup.Buttons.SaveChanges")}
          </button>
        </div>
      )}
    </>
  );

  const bodyForm = (
    <SimpleForm
      answers={bodyFormAnswers}
      formContent={details.bodyFormInstance.form}
      loggedInUser={userContext.user}
      subjectUser={details.ownerEmployee}
      validationErrors={bodyFormValidationErrors}
      onChange={onBodyFormAnswerChange}
      requiredBadgeMode="SHOW-OPTIONAL"
      isReadOnly={isReadOnly}
    />
  );

  const singularTaskTypeName = taskType
    ? advancedTaskHelper.ToLowerCase(
      taskType.singularNameTranslationKeyIdentifier
    )
    : "";

  return (
    <ScrollableModalContentTemplate
      footer={footerButtons}
      bodyRef={scrollableContainerRef}
    >
      <>
        {showRestrictionError && (
          <DangerAlert
            prefix={t("Common.Validation.Oops")}
            message={t(
              "TaskType.Popup.Validation.UnableToUpdateDueToRestrictions"
            ).replace("#TASK_TYPE#", singularTaskTypeName)}
          />
        )}
        <AdvancedTaskCommonFieldSection
          isReadOnly={isReadOnly}
          categories={categories}
          categoryId={details.categoryId}
          setCategoryId={onCategoryChange}
          taskType={taskType!}
          validationResults={mainDetailsValidationResult}
          setValidationResults={setMainDetailsValidationResult}
          targetDate={details.targetDate}
          setTargetDate={onTargetDateChange}
          title={details.title}
          setTitle={onTitleChange}
          showValidationErrors={showValidationErrors}
          bodyFormContent={details.bodyFormInstance?.form}
        />
        {details.bodyFormInstance && (
          <>
            {details.taskStatus === "COMPLETED" ? (
              <div className="my-1">
                {!showBodyFormForClosedTask && (
                  <div className="flex flex-row mt-2">
                    <div className="w-3/12 md:w-1/3">
                      <hr className="mt-3" />
                    </div>
                    <div className="w-6/12 md:w-1/3 text-center">
                      <button
                        onClick={toggleShowBodyFormForClosedTask}
                        className="show-more-btn text-xs md:text-sm font-semibold p-1"
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          size="xs"
                          className="pr-1"
                        />
                        {t("TaskType.Popup.CloseView.SeeFullTaskDetails")}
                      </button>
                    </div>
                    <div className="w-3/12 md:w-1/3">
                      <hr className="mt-3" />
                    </div>
                  </div>
                )}

                {showBodyFormForClosedTask && <div>{bodyForm}</div>}
              </div>
            ) : (
              bodyForm
            )}
          </>
        )}
        {details.taskStatus === "COMPLETED" && details.closeFormInstance && (
          <SimpleForm
            answers={simpleFormHelper.prepServerAnswersForDisplay(
              details.closeFormInstance.response?.answers
            )}
            formContent={details.closeFormInstance.form}
            loggedInUser={userContext.user}
            subjectUser={details.ownerEmployee}
            validationErrors={[]}
            onChange={() => { }}
            requiredBadgeMode="SHOW-OPTIONAL"
            isReadOnly
          />
        )}
      </>
    </ScrollableModalContentTemplate>
  );
};

export default AdvancedTaskBodyFormView;
