import { useTranslation } from "react-i18next";
import cx from "classnames";
import { faPencil, faPaperPlaneAlt } from "@fortawesome/pro-regular-svg-icons";
import AutomationDisplay from "../../common/AutomationDisplay";
import ClientFormModeDisplay from "../../common/ClientFormModeDisplay";
import ClientFormWindowStatusDisplay from "../../common/ClientFormWindowStatusDisplay";
import { FormattedDate } from "../../common";
import ClientFormLoadingCard from "./ClientFormLoadingCard";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../../routes/AppRoutes";
import { AdminJourneyFormDto } from "../../../types/dtos/admin/AdminJourneyFormDto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import adminApi from "../../../api/dashboard/adminApi";

interface ClientFormCardProps {
  clientForm: AdminJourneyFormDto | null;
}

function ClientFormCard({ clientForm }: ClientFormCardProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  let configRoute = clientForm?.clientFormId
    ? AppRoutes.admin.journeys.config.generateRoute(
      clientForm?.clientFormId.toString()
    )
    : "";
  let sendNowRoute = clientForm?.clientFormId
    ? AppRoutes.admin.journeys.send.generateRoute(clientForm?.clientFormId.toString())
    : "";

  return (
    <div className="basis-full md:basis-1/2">
      <div className="px-4 border-2 border-[#E5E7EB] rounded p-2 bg-white">
        {!clientForm && <ClientFormLoadingCard />}

        {clientForm && (
          <>
            <div className="flex flex-col pt-2">
              <div className="flex flex-row">
                <div
                  className={cx(
                    "flex flex-col",
                    clientForm.mode === "HIDDEN-ON-DASHBOARD"
                      ? "w-full"
                      : "w-3/4"
                  )}
                >
                  <h4 className="coloured-heading !mb-0">
                    {t(clientForm.clientFormTitle)}
                  </h4>
                  <div className="flex flex-col gap-1">
                    <div className="text-sm">
                      <label className="font-semibold">
                        {t("Pages.Admin.Common.Mode")}:{" "}
                      </label>
                      <ClientFormModeDisplay value={clientForm.mode} />
                      {clientForm.mode === "INDUCTION" && (
                        <p className="pl-1 inline-block">
                          ({t("Pages.Admin.Common.Between")} {clientForm.inductionDaysFrom} {t("Common.And_LowerCase")} {clientForm.inductionDaysTo} {t("Common.Days")})
                        </p>
                      )}
                      {clientForm.mode === "AUTOMATED" &&
                        clientForm.automationAmount &&
                        clientForm.automationUnit && (
                          <p className="pl-1 inline-block">
                            (<AutomationDisplay
                              value={clientForm.automationAmount}
                              type={clientForm.automationUnit}
                            />)
                          </p>
                        )}
                      {clientForm.mode === "WINDOWED" &&
                        clientForm.windowStatus && (
                          <>
                            <div>
                              <label className="font-semibold">
                                {t("Pages.Admin.Common.WindowStatus")}:{" "}
                              </label>
                              <ClientFormWindowStatusDisplay
                                value={clientForm.windowStatus}
                              />
                            </div>
                            {clientForm.windowStatus === "CLOSED" &&
                              clientForm.nextWindowStartDate &&
                              clientForm.nextWindowEndDate && (
                                <div>
                                  <label className="font-semibold">
                                    {t("Pages.Admin.Common.NextWindow")}:{" "}
                                  </label>
                                  <span>
                                    <FormattedDate
                                      displayMode="DATE-ONLY"
                                      date={clientForm.nextWindowStartDate}
                                    />{" "}
                                    -{" "}
                                    <FormattedDate
                                      displayMode="DATE-ONLY"
                                      date={clientForm.nextWindowEndDate}
                                    />
                                  </span>
                                </div>
                              )}
                            {clientForm.windowStatus === "OPEN" &&
                              clientForm.windowClosingDate && (
                                <div>
                                  <label className="font-semibold">
                                    {t("Pages.Admin.Common.ClosingDate")}:{" "}
                                  </label>
                                  <span>
                                    <FormattedDate
                                      displayMode="DATE-ONLY"
                                      date={clientForm.windowClosingDate}
                                    />
                                  </span>
                                </div>
                              )}
                          </>
                        )}
                    </div>
                  </div>
                </div>
                {clientForm.mode !== "HIDDEN-ON-DASHBOARD" && (
                  <div className="w-1/4">
                    <div className="flex flex-col text-center">
                      <div className="border rounded-lg rounded-bl-none rounded-br-none">
                        <h4 className="coloured-heading !mb-1 !text-sm">
                          {clientForm.completed} {t("Pages.Admin.Common.Updates_Lower")}
                        </h4>
                      </div>
                      <div className="border rounded-lg border-t-0 rounded-tl-none rounded-tr-none">
                        <p className="text-xs">
                          {t("Pages.Admin.Common.InTheLast30Days_Lower")}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="border-t-[1px] mt-2 pt-2 text-gray-800 text-sm text-right">
              <div className="inline-block ml-2">
                <button
                  onClick={() => navigate(configRoute)}
                  className="hover:underline hover:text-gray-900"
                >
                  <FontAwesomeIcon icon={faPencil} /> Edit Configuration |
                </button>
              </div>
              {clientForm.mode !== "WINDOWED" &&
                clientForm.mode !== "HIDDEN-ON-DASHBOARD" &&
                clientForm.mode !== "INDUCTION" &&
                clientForm.mode !== "EXIT" && (
                  <div className="inline-block ml-2">
                    <button
                      onClick={() => navigate(sendNowRoute)}
                      className="hover:underline hover:text-gray-900"
                    >
                      <FontAwesomeIcon icon={faPaperPlaneAlt} /> Send
                    </button>
                  </div>
                )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ClientFormCard;
