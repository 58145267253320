import * as DialogPrimitive from "@radix-ui/react-dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird, faTimes } from "@fortawesome/pro-regular-svg-icons";
import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../state/AppContext";

interface SidebarProps {
  children: React.ReactNode;
  isOpen: boolean;
  onOpenChange(open: boolean): void;
  /** Buttons appearing for the popup are optional. To display the primary/secondary buttons make sure to set the relevant button text and click events */
  onPrimaryButtonClick?(): void; // Optional - See above
  onSecondaryButtonClick?(): void; // Optional - See above
  primaryButtonText?: string; // Optional - See above
  /**Optional. Will show a loading spinner in the primary button if the modal is saving*/
  isLoading?: boolean;
  secondaryButtonText?: string; // Optional - See above
  /** Pass a null title to not show the title bar */
  title: string | null;
  titleDescription?: string;
  /** Whether or not to display the `x` button to close the modal */
  showCloseIcon?: boolean;
  allowYOverflow?: boolean;
  bodyRef?: React.LegacyRef<HTMLDivElement> | undefined;
  isPrimaryButtonDisabled?: boolean;
  /** Optional - Essentially used in place of the primary/secondary buttons for more required scenarios */
  footerComponent?: JSX.Element;
  alertOpenedWithinModal?: boolean;
  preventInitialAutoFocus?: boolean;
  width?: "NARROW" | "MEDIUM" | "WIDE";

  /** To prevent the modal from closing when you click outside the box we need to:
   * - provide: onOpenChange as () => {}
   * - Set onAlternativeOpenChange to what you want the 'X' icon to do instead
   */
  onAlternativeOpenChange?(open: boolean): void;
}

const Sidebar = ({
  children,
  isOpen,
  onOpenChange,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonText,
  secondaryButtonText,
  title,
  titleDescription,
  showCloseIcon = true,
  allowYOverflow = true,
  bodyRef = undefined,
  isPrimaryButtonDisabled = false,
  footerComponent,
  alertOpenedWithinModal = false,
  isLoading,
  preventInitialAutoFocus = false,
  onAlternativeOpenChange = undefined,
  width = "NARROW",
}: SidebarProps) => {
  const appContext = useContext(AppContext);
  const [displaySidebar, setDisplaySidebar] = useState<boolean>(isOpen);
  const [triggerAnimateMode, setTriggerAnimateMode] = useState<
    "IN" | "OUT" | undefined
  >(undefined);

  useEffect(() => {
    appContext.setSideBarIsOpen(isOpen);
    if (isOpen) {
      setTriggerAnimateMode(undefined);
      setTimeout(() => {
        setDisplaySidebar(true);
        setTriggerAnimateMode("IN");
      }, 100);
    } else {
      setTriggerAnimateMode("OUT");
      setTimeout(() => {
        setDisplaySidebar(false);
        setTriggerAnimateMode(undefined);
      }, 300);
    }
  }, [isOpen]);

  let zIndexClassName = "z-50";
  if (alertOpenedWithinModal) {
    zIndexClassName = "z-100";
  }

  const onOpenAutoFocus = (e: any) => {
    if (preventInitialAutoFocus) {
      e.preventDefault();
    }
  };

  const widthCssClass =
    width === "NARROW"
      ? "w-[100vw] md:w-[70vw] lg:w-[50vw] xl:w-[40vw]"
      : width === "MEDIUM"
        ? "w-[100vw] md:w-[80vw] lg:w-[60vw] xl:w-[50vw]"
        : "w-[100vw] md:w-[90vw]";

  const closeIcon = (
    <FontAwesomeIcon
      icon={faTimes}
      size="lg"
      className="text-gray-500 hover:text-gray-700"
    />
  );

  return (
    <DialogPrimitive.Root
      open={displaySidebar}
      onOpenChange={onOpenChange}
      modal
    >
      <DialogPrimitive.Portal container={document.body}>
        <DialogPrimitive.Overlay
          forceMount
          className={cx(
            "fixed inset-0 z-20 bg-black",
            "transition-opacity duration-200",
            triggerAnimateMode === "IN" ? "opacity-50" : "opacity-0"
          )}
        />
        <DialogPrimitive.Content
          onOpenAutoFocus={onOpenAutoFocus}
          onPointerDownOutside={(e) => e.preventDefault()}
          onInteractOutside={(e) => e.preventDefault()}
          forceMount
          className={cx(
            `fixed ${zIndexClassName}`,
            widthCssClass,
            "right-0 top-0",
            "h-screen",
            "bg-white",
            "focus:outline-none",
            "border-l border-gray-200 shadow-lg",
            "transition-transform duration-300",
            triggerAnimateMode === "IN" ? "translate-x-0" : "translate-x-full"
          )}
        >
          <div className="h-full px-3 py-4 flex flex-col flex-1 gap-1">
            {title !== null && title.length > 0 && (
              <div className="flex-none">
                <DialogPrimitive.Title className="text-2xl font-semibold text-gray-700 border-b border-gray-200 text-center py-4 truncate">
                  {title}
                </DialogPrimitive.Title>
                {titleDescription && (
                  <DialogPrimitive.Description className="mt-2 text-sm font-normal text-gray-700 ">
                    {titleDescription}
                  </DialogPrimitive.Description>
                )}
              </div>
            )}

            <div
              className={cx(
                "grow px-2 pb-4  text-gray-500",
                allowYOverflow ? "overflow-y-auto" : ""
              )}
              ref={bodyRef}
            >
              {children}
            </div>

            {footerComponent && (
              <div className="flex-none pt-4 pr-4 text-right border-t border-gray-200">
                {footerComponent}
              </div>
            )}

            {/* Renders primary or/and secondary button as long as their text + click events are given */}
            {((secondaryButtonText && onSecondaryButtonClick) ||
              (primaryButtonText && onPrimaryButtonClick)) && (
              <div className="flex-none pt-4 pr-6 text-right border-t border-gray-200">
                {secondaryButtonText && onSecondaryButtonClick && (
                  <button
                    className="inline-block btn-secondary m-2"
                    onClick={onSecondaryButtonClick}
                  >
                    {secondaryButtonText}
                  </button>
                )}
                {primaryButtonText && onPrimaryButtonClick && (
                  <button
                    className="inline-block btn-primary m-2"
                    onClick={onPrimaryButtonClick}
                    disabled={isPrimaryButtonDisabled}
                  >
                    {primaryButtonText}
                    {isLoading === true && (
                      <FontAwesomeIcon
                        icon={faSpinnerThird}
                        spin
                        className="loader-icon"
                        style={{ color: "#fff" }}
                      />
                    )}
                  </button>
                )}
              </div>
            )}
          </div>

          {showCloseIcon && (
            <>
              {!onAlternativeOpenChange && (
                <DialogPrimitive.Close
                  className={cx(
                    "absolute top-3.5 right-6 inline-flex items-center justify-center rounded-full p-1",
                    "focus:outline-none"
                  )}
                >
                  {closeIcon}
                </DialogPrimitive.Close>
              )}
              {onAlternativeOpenChange && (
                <div
                  className="absolute top-3.5 right-6 inline-flex cursor-pointer items-center justify-center rounded-full p-1 focus:outline-none"
                  onClick={() => onAlternativeOpenChange(false)}
                >
                  {closeIcon}
                </div>
              )}
            </>
          )}
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
};

export default Sidebar;
