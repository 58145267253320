import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import collabDocApi from "../../api/forms/collabDocApi";
import { CollabDocState } from "../../state/CollabDoc/CollabDocState";
import { t } from "i18next";

interface CollabDocReleaseAccessBannerProps {
  viewingUsersName: string;
  api: collabDocApi;
  answerSetUniqueId: string;
  reloadData(): void;
}

function CollabDocReleaseAccessBanner({
  viewingUsersName,
  api,
  answerSetUniqueId,
  reloadData,
}: CollabDocReleaseAccessBannerProps) {
  const [isMultipleUserBannerExpanded, setIsMultipleUserBannerExpanded] = useState<boolean>(true);
  const [giveAccessClicked, setGiveAccessClicked] = useState<boolean>(false);

  const onGiveAccessButtonClick = () => {
    setGiveAccessClicked(true);
    api.swapCollabDocLockAccess(
      answerSetUniqueId!,
      (data: any) => {
        // Release has successfully occured, reload to prevent user having access to make changes
        reloadData();
      },
      (error: any) => {
        // Release the button in an error situation
        setGiveAccessClicked(false);
      }
    );
  }

  return (
    <>
      {isMultipleUserBannerExpanded ? (
        <div className="fixed w-full bottom-0 left-0 border z-40">
          <div className="text-center text-gray-600 bg-amber-200 border-t border-amber-300">
            <p className="p-2">
              <span className="pr-2">
                {t("CollabDoc.ReleaseAccess.UserIsTryingToView_Long", {
                  viewingUsersName: viewingUsersName
                })}
              </span>
              <button className="btn-primary text-sm" disabled={giveAccessClicked} onClick={onGiveAccessButtonClick}>
                {giveAccessClicked ? t("CollabDoc.ReleaseAccess.PleaseWait") : t("CollabDoc.ReleaseAccess.GiveAccess")}
              </button>
              <FontAwesomeIcon
                className="ml-5 cursor-pointer"
                icon={faXmark}
                size="xs"
                onClick={() => setIsMultipleUserBannerExpanded(false)} />
            </p>
          </div>
        </div>
      ) : (
        <div className="fixed bottom-0 right-0 border z-40">
          <div className="text-center text-gray-600 bg-amber-200 border-t border-amber-300">
            <p className="p-2">
              <button onClick={() => setIsMultipleUserBannerExpanded(true)}>
                {t("CollabDoc.ReleaseAccess.UserIsTryingToView_Short", {
                  viewingUsersName: viewingUsersName
                })}
              </button>
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default CollabDocReleaseAccessBanner;
