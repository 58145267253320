import { questionSequenceHelper } from ".";
import { AnswerSetApprovalStatus } from "../types/collab-docs";
import { CollabDocFormDto } from "../types/dtos/collab-docs";
import { BaseCommentDto, FormQuestionDto } from "../types/dtos/forms";
import { FormQuestion, QuestionAnswer, QuestionTasks } from "../types/forms";

const collabDocValidator = {
  /** Validate the entire doc, to be used before saving/submitting the document */
  isValid(
    forms: CollabDocFormDto[],
    answers: QuestionAnswer[],
    tasks: QuestionTasks[],
    comments: BaseCommentDto[],
    loggedInUserId: number,
    formSubjectUserId: number,
    proposedStatus: AnswerSetApprovalStatus
  ): boolean {
    // If no questions (somehow) return false to prevent any saving, as this is an invalid doc anyway
    if (forms.length === 0 || forms[0].questions.length === 0) return false;

    // If the user is saving the document to come back to later than we don't need the collab doc to be valid
    // e.g. don't need all the questions to be answered
    if (
      proposedStatus === "SAVED-BY-EMPLOYEE-WITHOUT-APPROVING" ||
      proposedStatus === "SAVED-BY-MANAGER-WITHOUT-APPROVING"
    )
      return true;

    // Set to true by default, will be set to false as soon as an invalid question is found
    let isValid: boolean = true;

    // Check whether the status is a final submission, as we need to validate
    // for "expected" values (which is a special validation type)
    const proposedStatusIsFinalSubmission =
      proposedStatus === "DUAL-PREP-SUBMITTED" ||
      proposedStatus === "FULLY-APPROVED";

    // Loop over each form, and break if we find an invalid question
    for (var iForm = 0; iForm < forms.length; iForm++) {
      const form = forms[iForm];

      // Start from the beginning of the form, validate the first question, and while still valid,
      // keep going through and finding the next question, and validating that one
      let questionDto: FormQuestionDto | null = form.questions[0];

      // Whilst there's a next question, and the questions are valid, keep validating!
      while (isValid && questionDto !== undefined && questionDto !== null) {
        const thisQuestion = new FormQuestion(questionDto);

        // Get the answer for this question
        const answer = answers.find(
          (x) => x.questionId === questionDto!.questionId
        );
        const questionAnswer = answer ? answer.answer : null;
        const questionTasks = tasks.find(
          (x) => x.questionId === questionDto!.questionId
        );
        const questionComments = comments.filter(
          (x) => x.questionId === thisQuestion.questionId
        );

        const validationResult = thisQuestion.validate(
          questionAnswer,
          questionTasks ? questionTasks.tasks : null,
          thisQuestion.taskManagementConfig ?? null,
          questionComments,
          loggedInUserId,
          formSubjectUserId,
          "COLLAB-DOC",
          proposedStatusIsFinalSubmission
        );

        if (validationResult.isValid) {
          // Was valid, so find the next question that is displayed to the user
          const nextQuestionId =
            questionSequenceHelper.collabDocs.getNextQuestionToDisplay(
              thisQuestion,
              questionAnswer,
              form.questions
            );
          if (nextQuestionId) {
            const match = form.questions.find(
              (x) => x.questionId === nextQuestionId
            );
            questionDto = match ? new FormQuestion(match) : null;
          } else {
            questionDto = null;
          }
        } else {
          // Question wasn't valid, break and return false
          isValid = false;
        }
      }

      // If we've already found an invalid question, no need to check the next form
      if (!isValid) {
        break;
      }
    }

    return isValid;
  },
};

export default collabDocValidator;
