import { useTranslation } from "react-i18next";
import { TaskPopupTabLoader } from "./TaskPopupTabLoader";
import { UserTaskHistoryItem } from "../../../types/dtos/tasks/advanced-tasks/UserTaskHistoryItem";
import { dateHelper } from "../../../helpers";
import { useEffect, useState } from "react";
//@ts-ignore
import Pagination from "react-js-pagination";
import parse from "html-react-parser";

interface TaskHistoryPopupTabProps {
  isLoading: boolean;
  userTaskId: string | null;
  historyItems: UserTaskHistoryItem[];
}

const TaskHistoryPopupTab = ({
  isLoading,
  userTaskId,
  historyItems
}: TaskHistoryPopupTabProps) => {
  const { t } = useTranslation();

  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalItemCount, setTotalItemCount] = useState<number>(historyItems.length);
  const [itemsOnDisplay, setItemsOnDisplay] = useState<UserTaskHistoryItem[]>(
    []
  );

  useEffect(() => {
    setTotalItemCount(historyItems.length);
    calculateItemsToDisplay(pageNumber);
  }, [historyItems]);

  const handlePageNumberChange = (pageNumber: number) => {
    setPageNumber(pageNumber);
    calculateItemsToDisplay(pageNumber);
  };

  const calculateItemsToDisplay = (newPageNumber: number) => {
    var startIndex = (newPageNumber - 1) * itemsPerPage;
    let displayItems = [...historyItems].splice(startIndex, itemsPerPage);
    setItemsOnDisplay(displayItems);
  };

  return (
    <div className="min-h-60">
      {isLoading && <TaskPopupTabLoader />}
      {!isLoading && (
        <>
          {userTaskId && (
            <>
              <table className="datatable mt-2 text-sm">
                <thead>
                  <tr className="text-left" key="Date">
                    <th className="!py-3 w-36">
                      <div className="flex">
                        <span className="grow">{t('Pages.Admin.History.Date')}</span>
                      </div>
                    </th>
                    <th className="!py-3" key="Description">
                      <div className="flex">
                        <span className="grow">{t('Pages.Admin.History.Description')}</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {itemsOnDisplay != undefined && itemsOnDisplay.map((item, index) => (
                    <tr key={index}>
                      <td className="w-40">
                        {dateHelper.convertUtcDateToLocal(item.timestamp).toLocaleDateString(undefined) + " - " + dateHelper.convertUtcDateToLocal(item.timestamp).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}
                      </td>
                      <td>
                        {parse(item.description)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {totalItemCount && totalItemCount > itemsPerPage && (
                <div className="grid grid-cols-1 pt-1 md:grid-cols-5 md:text-gray-500">
                  <div className="col-span-3">
                    {/* Only show the pagination if there is more than one page (aka if the totalItemCount is greater than itemsPerPage) */}
                    <div className="mt-2 text-xs">
                      <Pagination
                        activePage={pageNumber}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={totalItemCount}
                        pageRangeDisplayed={5}
                        onChange={handlePageNumberChange}
                        activeClass="pagination-active-list-item"
                        itemClass="pagination-list-item"
                        itemClassFirst="pagination-first-item"
                        itemClassLast="pagination-last-item"
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TaskHistoryPopupTab;
