import reportWebVitals from "./reportWebVitals";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "react-oidc-context";
import App from "./App";
import FullScreenLoader from "./components/loaders/FullScreenLoader";
import { oidcConfig } from "./config/auth/identityServerDetails";
import "./App.css";
import Accessibe from "./components/third-party/AccessibePlugin";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense fallback={<FullScreenLoader />}>
        <AuthProvider {...oidcConfig}>
          <App />
          <Accessibe />
        </AuthProvider>
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
