import { useContext, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Body, TopBar, LeftNav, BottomNav } from "../layout";
import ReactErrorFallback from "../error-handling/ReactErrorFallback";
import { t } from "i18next";
import AppContext from "../../state/AppContext";

function StandardLayout() {
  const appContext = useContext(AppContext);
  const [hasErrored, setHasErrored] = useState(false);

  useEffect(() => {
    // When the side bar is open we need to add the class to the body to allow for the Accessibe trigger
    // to be hidden... because there are scenarios where this overlaps key functionality (like a button) 
    // and the user is unable to interact with the feature as intended.
    if (appContext.sideBarIsOpen) {
      document.body.classList.add('sidebar-opened');
    } else {
      document.body.classList.remove('sidebar-opened');
    }
  }, [appContext.sideBarIsOpen]);

  return (
    <div className="relative min-h-screen max-h-screen flex overscroll-contain overflow-hidden print:overflow-visible print:max-h-none">
      <div className="bg-gray-100 w-60 flex-none hidden lg:block relative print:hidden">
        <LeftNav />
        <span className="absolute bottom-6 left-0 right-0 m-auto text-center font-medium text-gray-400 select-none">
          {t("Common.PoweredBy")}
        </span>
      </div>

      <div className="flex flex-col w-full">
        <TopBar />
        <ErrorBoundary
          FallbackComponent={ReactErrorFallback}
          resetKeys={[hasErrored]}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
            setHasErrored(false);
            window.console.error("Reset state, error happened");
          }}
        >
          <Body />
        </ErrorBoundary>
      </div>
      <BottomNav />
    </div>
  );
}

export default StandardLayout;
