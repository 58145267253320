import {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import { debounce } from "lodash";
import produce from "immer";
import { useQueue } from "react-use";
import JourneyFormPager from "./JourneyFormPager";
import JourneyQuestion from "./JourneyQuestion";
import JourneySubmitted from "./JourneySubmitted";
import FormQuestion from "../../types/forms/FormQuestion";
import {
  QuestionAnswer,
  QuestionAnswerType,
  QuestionAnswerValue,
  QuestionTasks,
} from "../../types/forms";
import { JourneyFormDto, JourneyFormGroupDto } from "../../types/dtos/journeys";
import { EnforcedCommentType, FormQuestionDto } from "../../types/dtos/forms";
import { JourneyCommentDto } from "../../types/dtos/journeys/JourneyCommentDto";
import { journeyQuestionSequenceHelper } from "../../helpers/questionSequenceHelpers";
import {
  CatchUpValidator,
  dateHelper,
  formAnswerHelper,
  goalReviewQuestionHelper,
  interactionHelper,
  journeyAnswerHelper,
  questionTextHelper,
  taskTypeHelper,
} from "../../helpers";
import UserContext from "../../state/UserContext";
import JourneyWelcomeMessage from "./JourneyWelcomeMessage";
import { useAuth } from "react-oidc-context";
import journeyApi from "../../api/forms/journeyApi";
import {
  JourneySubmission,
  JourneySubmissionResponse,
} from "../../types/journeys";
import { ErrorPopup } from "../common";
import MeetingFormFields from "../../types/catch-ups/MeetingFormFields";
import ManageCatchUpForm from "../catch-ups/ManageCatchUpForm";
import CatchUpDto from "../../types/catch-ups/CatchUpDto";
import catchUpApi from "../../api/dashboard/catchUpApi";
import ContinueJourneyMessage from "./ContinueJourneyMessage";
import {
  JourneyAnswerSetInfo,
  JourneySavedAnswerDto,
  JourneySavedObjectDto,
} from "../../types/dtos/journeys/answers";
import EditableTaskDbObject from "../../types/tasks/EditableTaskDbObject";
import { BaseUserDetailsDto } from "../../types/dtos/generic";
import PreJourneyScreen from "./PreJourneyScreen";
import { t } from "i18next";
import AdvancedTaskDto from "../../types/dtos/forms/AdvancedTaskDto";

interface JourneyFormProps {
  formDetails: JourneyFormGroupDto;
  onFormChange(formDto: JourneyFormDto): void;
  showContinueJourneyMessage: boolean;
  onHideContinueJourneyMessage(): void;
  showWelcomeMessage: boolean;
  onHideWelcomeMessage(): void;
  onLoadNextJourney(): void;
  hasJourneyBeenSubmitted: boolean;
  onJourneySubmissionSuccess(value: boolean): void;
  showManageCatchUpMeetingScreen: boolean;
  onShowManageCatchUpMeetingScreenChange(value: boolean): void;
  formTitle: string | undefined;
  /** Whether or not we're in Dual Prep prep mode */
  isInPrepMode: boolean;
  /** Whether or not the updates are being displayed right now in the journey widget */
  updatesAreVisible: boolean;
  updateCount: number;
  /** True when there's a single update to render, but the assigned journey is for that update */
  onlyUpdateIsForAssignedJourney: boolean;
  onToggleUpdatesVisibility: (showShouldUpdates: boolean) => void;
  createAndPrefillCollabDoc(): void;
  scrollToWidgetTop(): void;
}

function JourneyForm({
  formDetails,
  onFormChange,
  showContinueJourneyMessage,
  onHideContinueJourneyMessage,
  showWelcomeMessage,
  onHideWelcomeMessage,
  onLoadNextJourney,
  hasJourneyBeenSubmitted,
  onJourneySubmissionSuccess,
  showManageCatchUpMeetingScreen,
  onShowManageCatchUpMeetingScreenChange,
  formTitle,
  isInPrepMode,
  updatesAreVisible,
  updateCount,
  onlyUpdateIsForAssignedJourney,
  onToggleUpdatesVisibility,
  createAndPrefillCollabDoc,
  scrollToWidgetTop,
}: JourneyFormProps) {
  // Auth/API
  const auth = useAuth();
  const apiJourneys = new journeyApi(auth.user?.access_token);
  const apiCatchUp = new catchUpApi(auth.user?.access_token);
  const answerHelper = new journeyAnswerHelper(apiJourneys);

  // Context
  const userContext = useContext(UserContext);
  const formSubjectUserId = userContext.user.id; // In the future, we might have manager journeys about their people, and this needs to be passed in to validation functions

  // Refs
  const answerSetInfo = useRef<JourneyAnswerSetInfo | null>(null);
  const setAnswerSetInfo = (newInfo: JourneyAnswerSetInfo | null) => {
    answerSetInfo.current = newInfo;
  };

  // State
  const [answerSetParticipants, setAnswerSetParticipants] = useState<
    BaseUserDetailsDto[]
  >([]);
  const [answerState, setAnswerState] = useState<QuestionAnswer[]>([]);
  // The state variable for tasks added in this document, but not yet pushed to the user's dashboard
  const [tasks, setTasks] = useState<QuestionTasks[]>([]);
  const [comments, setComments] = useState<JourneyCommentDto[]>([]);
  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);
  const [activeFormIndex, setActiveFormIndex] = useState<number>(0);
  const [activeQuestion, setActiveQuestion] = useState<FormQuestion>();
  const [previousQuestionId, setPreviousQuestionId] = useState<string | null>(
    null
  );
  const [enableNextButton, setEnableNextButton] = useState<boolean>(false);
  const [enablePreviousButton, setEnablePreviousButton] =
    useState<boolean>(false);
  const [journeyIsSubmitting, setJourneyIsSubmitting] =
    useState<boolean>(false);
  const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);
  const [errorPopupDetails, setErrorPopupDetails] = useState<any | null>(null);

  const [forceRedirectOnSubmission, setForceRedirectOnSubmission] =
    useState<boolean>(false);
  const [anotherJourneyAvailable, setAnotherJourneyAvailable] =
    useState<boolean>(false);
  const [exitQuestionnaireCompleted, setExitQuestionnaireCompleted] =
    useState<boolean>(false);
  const [dualPrepCollabDocGuidId, setDualPrepCollabDocGuidId] = useState<
    string | null
  >(null);

  const [
    showArrangeMeetingValidationErrors,
    setShowArrangeMeetingValidationErrors,
  ] = useState<boolean>(false);
  const [userSkippedArrangingMeeting, setUserSkippedArrangingMeeting] =
    useState<boolean>(false);
  const [targetDateAndTime, setTargetDateAndTime] = useState<
    Date | undefined
  >();
  const [disableCreateMeetingButton, setDisableCreateMeetingButton] =
    useState<boolean>(false);

  const formFields: MeetingFormFields = {
    subjectUserId: undefined,
    otherParticipantId: undefined,
    subjectClientFormId: undefined,
    targetDate: targetDateAndTime,
    title: formTitle,
  };

  function getAnswerValueForQuestionId(
    questionId: string
  ): QuestionAnswerValue | null {
    const match = answerState.find((x) => x.questionId === questionId)?.answer;
    return match ? match : null;
  }

  function getCommentsForQuestionId(questionId: string): JourneyCommentDto[] {
    return comments.filter((x) => x.questionId === questionId);
  }

  /** Check whether or not the Next button can be enabled, depending on whether there
   * are more questions, and whether the current question has a valid answer */
  const canEnableNextButton = () => {
    // Only enable the next button when there's an active question with a valid answer
    if (!activeQuestion) return false;
    const questionAnswer = getAnswerValueForQuestionId(
      activeQuestion.questionId
    );
    const questionTasks = tasks.find(
      (x) => x.questionId === activeQuestion.questionId
    );
    const questionComments = getCommentsForQuestionId(
      activeQuestion.questionId
    );

    // If the active question has taskManagementConfig and the answerSetGuidId is null, BUT there is
    // one available to it now... set it.
    if (
      activeQuestion.taskManagementConfig != null &&
      activeQuestion.taskManagementConfig.answerSetGuidId == null &&
      answerSetInfo.current?.answerSetUniqueId != null
    ) {
      activeQuestion.taskManagementConfig.answerSetGuidId =
        answerSetInfo.current?.answerSetUniqueId;
    }

    return activeQuestion.validate(
      questionAnswer,
      questionTasks ? questionTasks.tasks : null,
      activeQuestion.taskManagementConfig ?? null,
      questionComments,
      userContext.user.id,
      formSubjectUserId,
      "JOURNEY",
      false
    ).isValid;
  };

  // Lifecycle events
  useEffect(() => {
    // Select the first question from the first form on mount and
    // convert the DTO to an instance of the `JourneyFormQuestion` class so
    // we can use methods declared on it
    if (
      formDetails.forms[activeFormIndex].questions &&
      formDetails.forms[activeFormIndex].questions.length > 0
    ) {
      const formQuestion = new FormQuestion(
        formDetails.forms[activeFormIndex].questions[0]
      );
      setActiveQuestion(formQuestion);
    }

    setAnswerSetInfo({
      answerSetUniqueId: formDetails.partialSaveData
        ? formDetails.partialSaveData.answerSetUniqueId
        : null,
      answerSetDateCreated: formDetails.partialSaveData
        ? formDetails.partialSaveData.answerSetDateCreated
        : null,
      clientFormVersionIds: formDetails.forms.map((x) => x.clientFormVersionId),
      employeeDetailId: formDetails.employeeDetailId,
      journeyReference: formDetails.journeyReference,
    });

    // If there are saved answers from a previous session answering this journey, load them into state
    if (formDetails.partialSaveData?.answers) {
      setAnswerState(
        formAnswerHelper.prepareAnswersForInitialDisplay(
          formDetails.partialSaveData.answers
        )
      );
    }

    if (formDetails.partialSaveData?.comments) {
      setComments(formDetails.partialSaveData?.comments);
    }

    if (formDetails.partialSaveData?.tasks) {
      const questionTasks = taskTypeHelper.getQuestionTaskObjects(
        formDetails.partialSaveData.tasks
      );
      setTasks(questionTasks);
    }

    // Set the participants
    const participants = [formDetails.subjectUser];
    if (formDetails.managerRolePlayingUser) {
      participants.push(formDetails.managerRolePlayingUser);
    }
    setAnswerSetParticipants(participants);
  }, [formDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // On form change, tell the parent component (so we can change bg colour etc)
    const latestForm = formDetails.forms[activeFormIndex];
    onFormChange(latestForm);
  }, [activeFormIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // As the `activeQuestion` changes, calculate which question to show
    // when the user goes backwards in the form
    let previousQuestion: string | null = null;
    if (activeQuestion) {
      previousQuestion = journeyQuestionSequenceHelper.getPreviousQuestionId(
        activeQuestion,
        formDetails.forms[activeFormIndex].questions,
        answerState
      );
    }
    setPreviousQuestionId(previousQuestion ? previousQuestion : null);
    setEnableNextButton(canEnableNextButton());
  }, [activeQuestion]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // When the answer changes, or tasks change for goal setting, conditionally enable the "Next" button
    setEnableNextButton(canEnableNextButton());

    // Show the Previous button except when on the first question of the first form
    const showPreviousPagerButton: boolean =
      activeFormIndex > 0 ||
      (previousQuestionId !== null && previousQuestionId.length > 0);
    setEnablePreviousButton(showPreviousPagerButton);
  }, [activeQuestion, answerState, previousQuestionId, tasks, comments]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Ensure rolled over goals are kept in sync, if this is a goal review question
    if (activeQuestion) {
      debouncedSyncRolloverGoals(answerState, tasks);
    }
  }, [answerState]);

  const onContinueSavedJourney = () => {
    // Find the last question and set it as the active one
    if (formDetails.partialSaveData?.mostRecentQuestionId) {
      for (var iForm = 0; iForm < formDetails.forms.length; iForm++) {
        const thisForm = formDetails.forms[iForm];
        const matchedQuestion = thisForm.questions.find(
          (x) =>
            x.questionId === formDetails.partialSaveData!.mostRecentQuestionId
        );
        if (matchedQuestion) {
          setActiveFormIndex(iForm);
          setActiveQuestion(new FormQuestion(matchedQuestion));
          break;
        }
      }
    }

    // Show the normal journey screen
    onHideContinueJourneyMessage();
  };

  const onSnoozeSavedJourney = (
    successCallback: (newExpiryDate: Date) => void,
    errorCallback: () => void
  ) => {
    apiJourneys.snoozeExpiration(
      answerSetInfo.current!.answerSetUniqueId!,
      successCallback,
      errorCallback
    );
  };

  /** Update the answer state for the current question with the given value */
  const onValueChange = (
    newValue: QuestionAnswerValue,
    answerType: QuestionAnswerType,
    formId: number
  ) => {
    if (!activeQuestion) return;

    const answerTimestamp = dateHelper.getCurrentDateUtc();

    // Update the existing state answer if there is one, otherwise add it to the state
    // if it's a new answer
    const nextState = produce(answerState, (draft) => {
      const match = draft.find(
        (x) => x.questionId === activeQuestion.questionId
      );
      if (match !== undefined) {
        match.id = null; // TODO: Is this the right thing to do? Or should we remove the answer, and add a new one instead?
        match.answer = newValue;
        match.timestamp = answerTimestamp;
        match.userId = userContext.user.id;
      } else {
        draft.push({
          id: null,
          questionId: activeQuestion.questionId,
          answer: newValue,
          timestamp: answerTimestamp,
          userId: userContext.user.id,
          answerType: answerType,
          formId: formId,
        });
      }
    });
    setAnswerState(nextState);
  };

  /** Update the single standard comment for this question */
  const onCommentChange = (
    newValue: string | null,
    onSaveSuccess: () => void,
    onSaveError: () => void
  ) => {
    // Don't allow the comments to change in journeys if there's no active question
    // or if the current question has comments disabled
    if (!activeQuestion || !activeQuestion.commentsEnabled) return;

    // Update the existing state answer if there is one, otherwise add it to the state
    // if it's a new answer
    const nextState = produce(comments, (draft) => {
      const match = draft.find(
        (x) => x.questionId === activeQuestion.questionId
      );
      if (match !== undefined) {
        // A comment exists in state already, update or remove it, depending
        // on whether or not a value has been supplied
        if (newValue && newValue.trim().length > 0) {
          match.comment = newValue;
        } else {
          const indexToRemove = draft.findIndex(
            (x) => x.questionId === activeQuestion.questionId
          );
          draft.splice(indexToRemove, 1);
        }
      } else if (newValue !== null) {
        draft.push({
          questionId: activeQuestion.questionId,
          behaviourId: null,
          goalId: null,
          comment: newValue,
          commentType: "STANDARD",
          clientFormId: null,
        });
      }
    });

    setComments(nextState);

    // The comment state is changed as the popup is closed, so as the user "commits" their change,
    // not as they type each character, so it's safe to perform the API call to save the data here
    answerHelper.saveComment(
      answerSetInfo.current!,
      userContext.user.id,
      activeQuestion.questionId,
      newValue,
      (saveResponse: JourneySavedObjectDto) => {
        setStateAnswerSetInfoOnAnswerSave(saveResponse);
        onSaveSuccess();
      },
      onSaveError
    );
  };

  /** Update one of the enforced comments for this question */
  const onEnforcedCommentChange = (
    newValue: string | null,
    commentFor: EnforcedCommentType,
    objectId: number,
    clientFormId: number
  ) => {
    // Don't allow the comments to change in journeys if there's no active question
    // or if the current question has enforced comments disabled
    if (!activeQuestion || !activeQuestion.hasEnforcedCommentQuestionText())
      return;

    // Update the existing state answer if there is one, otherwise add it to the state
    // if it's a new comment
    const nextState = produce(comments, (draft) => {
      const match = draft.find(
        (x) =>
          x.questionId === activeQuestion.questionId &&
          x.commentType === "ENFORCED" &&
          ((commentFor === "BEHAVIOUR" && x.behaviourId === objectId) ||
            (commentFor === "GOAL" && x.goalId === objectId))
      );
      if (match !== undefined) {
        // A comment exists in state already, update or remove it, depending
        // on whether or not a value has been supplied
        if (newValue && newValue.trim().length > 0) {
          match.comment = newValue;
        } else {
          const indexToRemove = draft.findIndex(
            (x) =>
              x.questionId === activeQuestion.questionId &&
              ((commentFor === "BEHAVIOUR" && x.behaviourId === objectId) ||
                (commentFor === "GOAL" && x.goalId === objectId))
          );
          draft.splice(indexToRemove, 1);
        }
      } else if (newValue !== null) {
        draft.push({
          questionId: activeQuestion.questionId,
          behaviourId: commentFor === "BEHAVIOUR" ? objectId : null,
          goalId: commentFor === "GOAL" ? objectId : null,
          comment: newValue,
          commentType: "ENFORCED",
          clientFormId: clientFormId,
        });
      }
    });

    setComments(nextState);
  };

  /** When a task is added/edited/deleted */
  const onChangeQuestionTasks = (
    questionTasks: QuestionTasks,
    onSaveSuccess: () => void,
    onSaveError: () => void
  ) => {
    const onTasksSaveSuccess = (saveResponse: JourneySavedObjectDto) => {
      // Record the AnswerSetUniqueId
      // (only really relevant if this is a new journey, and the first thing to be saved is a task,
      // so we need to store the generated AnswerSetUniqueId to use when saving further answers)
      setAnswerSetInfo({
        ...answerSetInfo.current!,
        answerSetUniqueId: saveResponse.answerSetUniqueId,
        answerSetDateCreated: saveResponse.answerSetDateCreated,
      });

      if (saveResponse.object) {
        // Convert the database tasks into the types expected in the UI
        const dbTasks = saveResponse.object as
          | EditableTaskDbObject<string>[]
          | null;
        const dbTaskCollection = dbTasks
          ? taskTypeHelper.getTaskObjects(dbTasks)
          : [];

        // Change the task state

        const nextState = produce(tasks, (draft) => {
          const match = draft.find(
            (x) => x.questionId === questionTasks.questionId
          );
          if (match !== undefined) {
            // Update the match what came back from the server
            match.tasks = dbTaskCollection;
          } else {
            const dbQuestionTasks: QuestionTasks = {
              ...questionTasks,
              tasks: dbTaskCollection,
            };
            draft.push(dbQuestionTasks);
          }
        });
        setTasks(nextState);
      }

      onSaveSuccess();
    };

    const onTasksSaveError = () => {
      // Update the state with the submitted values so that we have it stored
      // in the state so we can retry saving the tasks (otherwise they'd be gone!)
      const nextState = produce(tasks, (draft) => {
        const match = draft.find(
          (x) => x.questionId === questionTasks.questionId
        );
        if (match !== undefined) {
          // Update the match what came back from the server
          match.tasks = questionTasks.tasks;
        } else {
          const dbQuestionTasks: QuestionTasks = {
            ...questionTasks,
            tasks: questionTasks.tasks,
          };
          draft.push(dbQuestionTasks);
        }
      });
      setTasks(nextState);

      onSaveError();
    };

    // Save the tasks via the API
    answerHelper.saveQuestionTasks(
      answerSetInfo.current!,
      questionTasks,
      onTasksSaveSuccess,
      onTasksSaveError
    );
  };

  const onChangeAdvancedTasks = (
    questionId: string,
    tasks?: AdvancedTaskDto[],
    cancelledTasks?: AdvancedTaskDto[],
    newTasks?: AdvancedTaskDto[]
  ) => {
    if (activeQuestion && activeQuestion.questionId === questionId) {
      const newActiveQuestion = new FormQuestion(activeQuestion);

      if (tasks) {
        newActiveQuestion.taskManagementConfig!.tasks = tasks;
      }
      if (cancelledTasks) {
        newActiveQuestion.taskManagementConfig!.cancelledTasks = cancelledTasks;
      }
      if (newTasks) {
        newActiveQuestion.taskManagementConfig!.newTasks = newTasks;
      }

      // Combine the task and newTasks and update the joint list
      const liveAndNewTasks = [
        ...newActiveQuestion.taskManagementConfig!.tasks,
        ...newActiveQuestion.taskManagementConfig!.newTasks,
      ];
      newActiveQuestion.taskManagementConfig!.liveAndNewTasks = liveAndNewTasks;

      setActiveQuestion(newActiveQuestion);
    }
  };

  const onPreAdvancedTaskInteractionCheckForAnswerSet = (
    onSaveSuccess: (
      answerSetUniqueId: string | null,
      answerSetDateCreated: Date | null
    ) => void,
    onSaveError: () => void
  ) => {
    const onTasksSaveSuccess = (response: JourneySavedObjectDto) => {
      // Record the AnswerSetUniqueId
      // (only really relevant if this is a new journey, and the first thing to be saved is a task,
      // so we need to store the generated AnswerSetUniqueId to use when saving further answers)
      setAnswerSetInfo({
        ...answerSetInfo.current!,
        answerSetUniqueId: response.answerSetUniqueId,
        answerSetDateCreated: response.answerSetDateCreated,
      });

      onSaveSuccess(response.answerSetUniqueId, response.answerSetDateCreated);
    };

    // Hit the helper to call the API to get or create the answer set details
    answerHelper.getOrCreateAnswerSet(
      answerSetInfo.current!,
      onTasksSaveSuccess,
      () => {
        onSaveError();
      }
    );
  };

  const onPagerForwards = () => {
    // Reset the flag to keep validation errors showing,
    // otherwise error warnings appear before the user has
    // had a chance to answer the question
    setShowValidationErrors(false);

    const currentAnswer = getAnswerValueForQuestionId(
      activeQuestion!.questionId
    );

    const questionComments = getCommentsForQuestionId(
      activeQuestion!.questionId
    );

    // Run the validation
    const questionTasks = tasks.find(
      (x) => x.questionId === activeQuestion!.questionId
    );

    const validationResult = activeQuestion!.validate(
      currentAnswer,
      questionTasks ? questionTasks.tasks : null,
      activeQuestion!.taskManagementConfig ?? null,
      questionComments,
      userContext.user.id,
      formSubjectUserId,
      "JOURNEY",
      false
    );
    if (!validationResult.isValid) {
      setShowValidationErrors(true);
      return;
    }

    // Retrieve the next questionId to answer, takes conditional logic into account
    let nextQuestionId: string | null = null;
    if (activeQuestion) {
      nextQuestionId = journeyQuestionSequenceHelper.getNextQuestionId(
        activeQuestion,
        currentAnswer
      );
    }

    // If there is a question in this form left to answer, find it from the list
    // of questions and set it as the active question
    if (nextQuestionId) {
      const nextQuestion = formDetails.forms[activeFormIndex].questions.find(
        (x) => x.questionId === nextQuestionId
      );
      if (nextQuestion) {
        setActiveQuestion(new FormQuestion(nextQuestion));
        return;
      }
    }

    // If no question has been found, and if there is another form
    // to complete, go to the first question in that form

    const isLastForm = activeFormIndex === formDetails.forms.length - 1;
    if (isLastForm) {
      // Submit this form - in theory, this should be handled by the Submit button
      onJourneySubmit();
    } else {
      // Go to the start of the next form

      // Loop over the remaining forms, and find the next form with questions in it
      // (knowing that forms can be empty, say if they contain only questions for the manager,
      // but we need the form id to be saved in the AnswerSetForms, so we don't exclude it
      // from being sent to the frontend via the API)
      let foundNextForm = false;
      for (
        var iForm = activeFormIndex + 1;
        iForm < formDetails.forms.length;
        iForm++
      ) {
        if (
          formDetails.forms[iForm].questions != null &&
          formDetails.forms[iForm].questions.length > 0
        ) {
          setActiveFormIndex(iForm);
          const newActiveQuestion = formDetails.forms[iForm].questions[0];
          setActiveQuestion(new FormQuestion(newActiveQuestion));
          foundNextForm = true;
          break; // Exit the for loop
        }
      }

      if (!foundNextForm) {
        // If we get here, the user completed all forms with questions in, then the last remaining forms didn't have
        // any questions in them, so we should submit the journey
        onJourneySubmit();
      }
    }
  };

  const onPagerBackwards = () => {
    if (previousQuestionId) {
      const prevQuestion = formDetails.forms[activeFormIndex].questions.find(
        (x) => x.questionId === previousQuestionId
      );
      if (prevQuestion) {
        setActiveQuestion(new FormQuestion(prevQuestion));
        return;
      }
    }

    // If the current question is the first one in a form, and there are previous forms
    // in the set of forms, then we need to go back to the last question the user answered
    // in the previous form. The `previousQuestionId` state value doesn't work here as the
    // activeFormIndex changes too
    const isFirstForm = activeFormIndex === 0;
    if (!isFirstForm) {
      // Go to the previous form
      const newFormIndex = activeFormIndex - 1;
      setActiveFormIndex(newFormIndex);

      // Loop backwards over the questions in this form,
      // and find the last one with an answer
      // Note that if we ever introduce optional questions for journeys, we'll
      // need to ensure we store null answers in the state for such questions
      // to avoid breaking the logic in this pageBackwards function
      let newActiveQuestion: FormQuestionDto | null = null;
      for (
        let iQuestion = formDetails.forms[newFormIndex].questions.length - 1;
        iQuestion >= 0;
        iQuestion--
      ) {
        const loopQuestionId =
          formDetails.forms[newFormIndex].questions[iQuestion].questionId;
        const answerForLoopQuestion = answerState.find(
          (x) => x.questionId === loopQuestionId
        );
        if (answerForLoopQuestion) {
          newActiveQuestion =
            formDetails.forms[newFormIndex].questions[iQuestion];
          break;
        }
      }

      if (!newActiveQuestion) {
        // Shouldn't happen in theory, but as a fallback, go to the first question in the active form
        newActiveQuestion = formDetails.forms[newFormIndex].questions[0];
      }

      setActiveQuestion(new FormQuestion(newActiveQuestion));
    } else {
      // Do nothing - first form, first question, we shouldn't reach here anyway
    }
  };

  /* **************************************** Answer Saving **************************************** */

  // Use a queue for any API call which will create an AnswerSet if one
  // doesn't already exist, to avoid creating more than one AnswerSet
  // for a journey
  const answerSaveQueue = useQueue<(removeFromQueue: () => void) => void>(); // { add, remove, first, last, size }

  useEffect(() => {
    if (answerSaveQueue.size > 0 && answerSaveQueue.first !== undefined) {
      const apiCall = answerSaveQueue.first;
      apiCall(() => {
        // Ensure this callback happens after `setStateAnswerSetInfoOnAnswerSave` has been called,
        // as that's the whole point of this queue, to avoid two or more API calls creating
        // an AnswerSet each, as the first API call hasn't returned the AnswerSetInfo
        answerSaveQueue.remove();
      });
    }
  }, [answerSaveQueue.first]);

  const setStateAnswerSetInfoOnAnswerSave = (
    saveResponse: JourneySavedAnswerDto | JourneySavedObjectDto
  ) => {
    setAnswerSetInfo({
      ...answerSetInfo.current!,
      answerSetUniqueId: saveResponse.answerSetUniqueId,
      answerSetDateCreated: saveResponse.answerSetDateCreated,
    });
  };

  const onValueSave = useCallback(
    (answer: QuestionAnswer, onSuccess: () => void, onError: () => void) => {
      if (answer) {
        // Call the API
        answerSaveQueue.add((removeFromQueue: () => void) => {
          answerHelper.saveAnswer(
            answerSetInfo.current!,
            answer.questionId,
            answer,
            (saveResponse: JourneySavedAnswerDto) => {
              setStateAnswerSetInfoOnAnswerSave(saveResponse);
              onSuccess();
              removeFromQueue();
            },
            () => {
              onError();
              removeFromQueue();
            }
          );
        });
      } else {
        onError();
      }
    },
    [
      answerSetInfo.current,
      answerHelper,
      answerSaveQueue,
      setStateAnswerSetInfoOnAnswerSave,
    ]
  );

  const onRetryValueSave = useCallback(
    (questionId: string, onSuccess: () => void, onError: () => void) => {
      let answerToSave: QuestionAnswer | undefined = answerState.find(
        (x) => x.questionId === questionId
      );

      if (answerToSave) {
        // Call the API
        answerSaveQueue.add((removeFromQueue: () => void) => {
          answerHelper.saveAnswer(
            answerSetInfo.current!,
            questionId,
            answerToSave!,
            (saveResponse: JourneySavedAnswerDto) => {
              setStateAnswerSetInfoOnAnswerSave(saveResponse);
              onSuccess();
              removeFromQueue();
            },
            () => {
              onError();
              removeFromQueue();
            }
          );
        });
      } else {
        console.log(
          "Couldn't find an answer to save for question: " + questionId
        );
        onError();
      }
    },
    [answerSetInfo.current, answerState, answerHelper, answerSaveQueue]
  );

  const onRetryTasksSave = useCallback(
    (questionId: string, onSuccess: () => void, onError: () => void) => {
      const tasksToSave = tasks.find((x) => x.questionId === questionId);

      if (tasksToSave) {
        // Call the API
        answerSaveQueue.add((removeFromQueue: () => void) => {
          onChangeQuestionTasks(
            tasksToSave,
            () => {
              onSuccess();
              removeFromQueue();
            },
            () => {
              onError();
              removeFromQueue();
            }
          );
        });
      } else {
        console.log(
          "Couldn't find an answer to save for question: " + questionId
        );
        onError();
      }
    },
    [answerSetInfo.current, tasks, onChangeQuestionTasks, answerSaveQueue]
  );

  const onEnforcedCommentSave = useCallback(
    (
      questionId: string,
      behaviourId: number | null,
      goalId: number | null,
      clientFormId: number,
      onSuccess: () => void,
      onError: () => void
    ) => {
      const match = comments.find(
        (x) =>
          x.questionId === questionId &&
          x.commentType === "ENFORCED" &&
          (!behaviourId || x.behaviourId === behaviourId) &&
          (!goalId || x.goalId === goalId)
      );

      let commentText: string | null;
      if (
        match !== undefined &&
        match.comment &&
        match.comment.trim().length > 0
      ) {
        // There's an enforced comment with a value to save
        commentText = match.comment.trim();
      } else {
        // Need to delete the enforced comment for this behaviour, if one exists in the db already
        commentText = null;
      }

      // Call the API
      answerSaveQueue.add((removeFromQueue: () => void) => {
        answerHelper.saveEnforcedComment(
          answerSetInfo.current!,
          userContext.user.id,
          questionId,
          behaviourId,
          goalId,
          clientFormId,
          commentText,
          (saveResponse: JourneySavedObjectDto) => {
            setStateAnswerSetInfoOnAnswerSave(saveResponse);
            onSuccess();
            removeFromQueue();
          },
          () => {
            onError();
            removeFromQueue();
          }
        );
      });
    },
    [
      /*docForms,*/ answerSetInfo.current,
      answerState,
      comments,
      answerSaveQueue,
    ]
  );

  const onRetryEnforcedCommentSave = useCallback(
    (
      questionId: string,
      behaviourId: number | null,
      goalId: number | null,
      clientFormId: number,
      onSuccess: () => void,
      onError: () => void
    ) => {
      // Find the comment to save for this behaviour/goal for this question
      const commentToSave = comments.find(
        (x) =>
          x.questionId === questionId &&
          (!behaviourId || x.behaviourId === behaviourId) &&
          (!goalId || x.goalId === goalId)
      );

      if (commentToSave) {
        // Call the API
        onEnforcedCommentSave(
          questionId,
          behaviourId,
          goalId,
          clientFormId,
          onSuccess,
          onError
        );
      } else {
        console.log(
          "Couldn't find an enforced comment to save for question: " +
            questionId
        );
        onError();
      }
    },
    [answerSetInfo.current, comments, onEnforcedCommentSave, answerSaveQueue]
  );

  /* **************************************** End Answer Saving **************************************** */

  /** Call this function when a goal review status radio button is changed
   * to ensure any related goal setting sections are kept in sync
   */
  const synchroniseRolledOverGoals = (
    currentAnswers: QuestionAnswer[],
    currentTasks: QuestionTasks[]
  ): void => {
    if (
      !activeQuestion ||
      !activeQuestion.isGoalReviewQuestion() ||
      !activeQuestion.goalReviewOptions
    )
      return;

    // Get the goal setting question to rollover to, if there is one
    const goalSettingQuestionId =
      formDetails?.formComplexities?.goalRolloverConfig?.goalSettingQuestionId;

    // If there's no goal setting question, we can't roll over any goals
    if (!goalSettingQuestionId) return;

    const checkResult = goalReviewQuestionHelper.checkRolledOverGoals(
      formDetails.formComplexities,
      activeQuestion.goalReviewOptions,
      currentAnswers,
      currentTasks
    );

    // Either update an existing answer for the goal setting question, or create one
    let questionTasks =
      currentTasks.find((x) => x.questionId === goalSettingQuestionId)?.tasks ||
      [];

    let performSave = false;
    if (checkResult.goalsToSave) {
      // Save the new rolled over goals to goal setting
      questionTasks = questionTasks.concat(checkResult.goalsToSave);
      performSave = true;
    }

    if (checkResult.taskIdsToDelete) {
      // Remove the invalid goals from goal setting
      questionTasks = questionTasks.filter(
        (x) =>
          x.taskId === null ||
          checkResult.taskIdsToDelete.indexOf(x.taskId) === -1
      );
      performSave = true;
    }

    if (performSave) {
      onChangeQuestionTasks(
        {
          formId: activeQuestion.formId,
          questionId: goalSettingQuestionId,
          tasks: questionTasks,
        },
        () => {
          // Tasks saved
        },
        () => {
          // Tasks save error
          console.log("Tasks save errored");
        }
      );
    }
  };

  // use the `debouncedSyncRolloverGoals` function to avoid a race condition
  // caused by quickfire clicking of goal status options to have rolled
  // over goals leftover if status quickly changed from "rollover" to "achieved"
  const debouncedSyncRolloverGoals = useMemo(
    () => debounce(synchroniseRolledOverGoals, 500),
    [activeQuestion, formDetails, answerSetInfo.current, tasks]
  );

  /** Save the response via the API */
  const onJourneySubmit = () => {
    // Show the loading spinner and disable the submit button on the journey widget
    setJourneyIsSubmitting(true);

    // Don't put the "arrange a catch up" in the user's face on submission,
    // but give them the option to go back and arrange one if they want
    skipArrangeMeeting();

    // Check to see if we need to perform an answer set check (to cover scenarios where a user might
    // not have had to give any answers in the form... e.g. all optional questions, or only one task advanced
    // management question)
    if (formDetails!.shouldPerformAnswerSetCheckOnSubmission) {
      answerHelper.getOrCreateAnswerSet(
        answerSetInfo.current!,
        (response: JourneySavedObjectDto) => {
          const modelToSubmit: JourneySubmission = {
            answerSetUniqueId: response.answerSetUniqueId,
            employeeDetailId: formDetails!.employeeDetailId,
            journeyReference: formDetails!.journeyReference,
          };

          // Need to update answerSetInfo with the new answerSetUniqueId
          setAnswerSetInfo({
            ...answerSetInfo.current!,
            answerSetUniqueId: response.answerSetUniqueId,
            answerSetDateCreated: response.answerSetDateCreated,
          });

          performJourneySubmission(modelToSubmit);
        },
        () => {
          console.log(
            "Error getting/creating new answer set for journey submission"
          );
        }
      );
    } else {
      const modelToSubmit: JourneySubmission = {
        answerSetUniqueId: answerSetInfo.current!.answerSetUniqueId!,
        employeeDetailId: formDetails!.employeeDetailId,
        journeyReference: formDetails!.journeyReference,
      };

      performJourneySubmission(modelToSubmit);
    }
  };

  const performJourneySubmission = (modelToSubmit: JourneySubmission) => {
    apiJourneys.submitResponse(
      modelToSubmit,
      (responseData: JourneySubmissionResponse) => {
        setJourneyIsSubmitting(false);

        if (responseData.successful) {
          onJourneySubmissionSuccess(true);
          setForceRedirectOnSubmission(responseData.forceRedirectToCollabDoc);
          setAnotherJourneyAvailable(responseData.newJourneyAssigned);
          setExitQuestionnaireCompleted(
            responseData.exitQuestionnaireCompleted
          );
          setDualPrepCollabDocGuidId(responseData.dualPrepCollabDocGuidId);
          setErrorPopupDetails(null);
          interactionHelper.deFocusJourney();
        } else {
          setShowErrorPopup(true);
          setErrorPopupDetails(responseData.error);
        }
      },
      (error) => {
        setJourneyIsSubmitting(false);
        setShowErrorPopup(true);
        setErrorPopupDetails(error);
        console.log("Error saving journey", error);
      }
    );
  };

  if (!activeQuestion) return null;

  /** When the user clicks the screen they see alongside the updates to start their current journey */
  const onAdvancePreJourneyScreen = () => {
    onToggleUpdatesVisibility(false);
  };

  /** When the user clicks to view their list of updates again */
  const onBackToUpdatesList = () => {
    onToggleUpdatesVisibility(true);
  };

  // Show the Submit button only on the last form, on the last question
  const showSubmitPagerButton =
    activeFormIndex === formDetails.forms.length - 1 &&
    activeQuestion &&
    activeQuestion.isLastQuestionInForm();

  // Disable the submit button if the last question isn't valid
  const disableSubmitPagerButton =
    showSubmitPagerButton && !canEnableNextButton();

  // Get the current answer from the state to pass to form controls as the current value
  const currentAnswer =
    answerState.find((x) => x.questionId === activeQuestion.questionId)
      ?.answer || null;

  const questionComments: JourneyCommentDto[] = comments.filter(
    (x) => x.questionId === activeQuestion.questionId
  );

  const questionNewTasks = tasks.find(
    (x) => x.questionId === activeQuestion.questionId
  );

  const displayWelcomeMessage =
    !showContinueJourneyMessage &&
    showWelcomeMessage &&
    formDetails.welcomeMessage !== undefined &&
    formDetails.welcomeMessage !== null &&
    formDetails.welcomeMessage.trim().length > 0;

  // Events - Manage Catch Up Screen
  const handleArrangeMeetingFieldChanges = (newValues: MeetingFormFields) => {
    setTargetDateAndTime(newValues.targetDate);
  };

  const checkArrangeMeetingFormIsValid = (): boolean => {
    const validator = new CatchUpValidator(false);

    return validator.isFullyValid({
      subjectUserId: userContext.user.id,
      otherParticipantId: formDetails.managerRolePlayingUser.userId,
      subjectClientFormId: undefined,
      targetDate: targetDateAndTime,
      title: formTitle,
    });
  };

  const handleSendCatchUpMeeting = () => {
    // Disable the button to prevent multiple discussions being saved for the same AnswerSet
    setDisableCreateMeetingButton(true);

    setShowArrangeMeetingValidationErrors(false);

    const successCallback = () => {
      // Progress to the final screen by turning off the flag to redirect to manage catch up screen
      onShowManageCatchUpMeetingScreenChange(false);
      setUserSkippedArrangingMeeting(false);
      setDisableCreateMeetingButton(false);
    };

    const errorCallback = () => {
      setDisableCreateMeetingButton(false);
      console.log("Error saving catch up");
    };

    if (checkArrangeMeetingFormIsValid()) {
      const catchUpDetails: CatchUpDto = {
        answerSetUniqueId: answerSetInfo.current!.answerSetUniqueId,
        scheduledDate: targetDateAndTime!,
        scheduledDateLocaleString:
          targetDateAndTime!.toLocaleString([], {
            year: "2-digit",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "2-digit",
            hourCycle: "h12",
          }) +
          " (" +
          targetDateAndTime!
            .toLocaleTimeString([], { timeZoneName: "short" })
            .split(" ")[1] +
          ")",
        initiatedByEmployeeId: userContext.user.id,
        subjectEmployeeId: userContext.user.id,
        participantEmployeeId: formDetails.managerRolePlayingUser.userId,
        title: formTitle!,
        discussionType: "REVIEW",
      };

      apiCatchUp.CreateNewCatchUpFromJourney(
        catchUpDetails,
        successCallback,
        errorCallback
      );
    } else {
      setShowArrangeMeetingValidationErrors(true);
      return;
    }
  };

  const skipArrangeMeeting = () => {
    // User has skipped the option to arrange a meeting, therefore enable the skipped flag
    // and relax the flag that displays the meeting screen so the user can see the final screen.
    setUserSkippedArrangingMeeting(true);
    onShowManageCatchUpMeetingScreenChange(false);
  };

  const manageCatchUpFormHeader = (
    <div className="text-white">
      <p>
        {t("Journey.Submit.WhenWouldYouLikeToDiscussThis", {
          managerName: formDetails.managerRolePlayingUser.firstName,
        })}
      </p>
      <br />
    </div>
  );

  const managerCatchUpFormFooter = (
    <div className="flex flex-row-reverse justify-between">
      <div className="flex flex-col justify-end">
        <button
          className="journey-btn-primary disabled:cursor-wait"
          onClick={handleSendCatchUpMeeting}
          disabled={disableCreateMeetingButton}
        >
          {t("Journey.Submit.RequestMeeting")}
        </button>
      </div>
      <button
        className="text-white/50 hover:text-white"
        onClick={skipArrangeMeeting}
      >
        {t("Journey.Submit.DontNeedMeeting")}
      </button>
    </div>
  );

  // Logic for showing the pre journey screen - only when updates are visible and there's no partial journey
  const canShowPreJourneyScreen =
    updatesAreVisible && !formDetails.partialSaveData;

  // Conditionally hide the "Request Catch Up" button based on the module config "HideRequestCatchUpButton"
  const hideRequestCatchUpButton =
    userContext.user.client.moduleConfigs.findIndex(
      (cfg) =>
        cfg.key === "HideRequestCatchUpButton" &&
        cfg.value.trim().toLowerCase() === "true"
    ) >= 0;

  return (
    <>
      <div className="w-full flex flex-col justify-stretch h-full grow">
        {!hasJourneyBeenSubmitted && displayWelcomeMessage && (
          <>
            {canShowPreJourneyScreen && (
              <PreJourneyScreen
                journeyTitle={formTitle!}
                onAdvanceClick={onAdvancePreJourneyScreen}
              />
            )}
            {!canShowPreJourneyScreen && (
              <JourneyWelcomeMessage
                htmlText={questionTextHelper.replaceTokensInFormIntros(
                  formDetails.welcomeMessage!,
                  userContext,
                  formDetails.managerRolePlayingUser
                )}
                onHide={onHideWelcomeMessage}
                userHasUpdates={
                  updateCount > 0 && !onlyUpdateIsForAssignedJourney
                }
                onBackToUpdates={onBackToUpdatesList}
                isPrefillAnswersEnabled={formDetails.isPrefillAnswersEnabled}
                createAndPrefillCollabDoc={createAndPrefillCollabDoc}
                journeyDeadline={formDetails.journeyDeadline}
              />
            )}
          </>
        )}
        {!hasJourneyBeenSubmitted &&
          showContinueJourneyMessage &&
          formDetails.partialSaveData && (
            <ContinueJourneyMessage
              journeyTitle={formTitle!}
              journeyDeadline={formDetails.journeyDeadline}
              expiryDate={formDetails.partialSaveData.expiryDate}
              onContinue={onContinueSavedJourney}
              onSnooze={onSnoozeSavedJourney}
            />
          )}
        {!hasJourneyBeenSubmitted &&
          !displayWelcomeMessage &&
          !showContinueJourneyMessage && (
            <>
              <JourneyQuestion
                answerSetParticipants={answerSetParticipants}
                question={activeQuestion}
                currentValue={currentAnswer}
                onValueChange={onValueChange}
                onValueSave={onValueSave}
                comments={questionComments}
                onCommentChange={onCommentChange}
                onEnforcedCommentChange={onEnforcedCommentChange}
                onEnforcedCommentBlur={onEnforcedCommentSave}
                showValidationErrors={showValidationErrors}
                enableNextButton={enableNextButton}
                showSubmitBtn={showSubmitPagerButton}
                onNextPageClick={onPagerForwards}
                subjectUser={formDetails.subjectUser}
                onChangeQuestionTasks={onChangeQuestionTasks}
                tasks={questionNewTasks ? questionNewTasks : null}
                formColor={formDetails.backgroundColour}
                formComplexities={formDetails.formComplexities}
                isInPrepMode={isInPrepMode}
                onRetryValueSave={onRetryValueSave}
                onRetryTasksSave={onRetryTasksSave}
                onRetryEnforcedCommentSave={onRetryEnforcedCommentSave}
                answerSetUniqueId={answerSetInfo.current?.answerSetUniqueId}
                answerSetDateCreated={
                  answerSetInfo.current?.answerSetDateCreated
                }
                onPreAdvancedTaskInteractionCheckForAnswerSet={
                  onPreAdvancedTaskInteractionCheckForAnswerSet
                }
                onChangeAdvancedTasks={onChangeAdvancedTasks}
              />
              <JourneyFormPager
                enableNextButton={enableNextButton}
                showPrevBtn={enablePreviousButton}
                showSubmitBtn={showSubmitPagerButton}
                disableSubmitBtn={disableSubmitPagerButton}
                onNextPageClick={onPagerForwards}
                onPreviousPageClick={onPagerBackwards}
                onSubmit={onJourneySubmit}
                showSubmitSpinner={journeyIsSubmitting}
                scrollToWidgetTop={scrollToWidgetTop}
              />
            </>
          )}
        {hasJourneyBeenSubmitted &&
          showManageCatchUpMeetingScreen &&
          answerSetInfo.current !== null &&
          !journeyIsSubmitting && (
            <>
              <ManageCatchUpForm
                customHeaderContent={manageCatchUpFormHeader}
                formFieldValues={formFields}
                otherParticipant={formDetails.managerRolePlayingUser}
                onFormChange={handleArrangeMeetingFieldChanges}
                showValidationErrors={showArrangeMeetingValidationErrors}
                answerSetUniqueId={answerSetInfo.current!.answerSetUniqueId}
                customFooterContent={managerCatchUpFormFooter}
              />
            </>
          )}
        {hasJourneyBeenSubmitted &&
          !showManageCatchUpMeetingScreen &&
          answerSetInfo.current !== null &&
          !journeyIsSubmitting && (
            <JourneySubmitted
              journeyType={formDetails.journeyType}
              subjectUserId={formSubjectUserId}
              answerSetParticipants={answerSetParticipants}
              answerSetUniqueId={answerSetInfo.current!.answerSetUniqueId!}
              forceRedirectToCollabDoc={forceRedirectOnSubmission}
              newJourneyAssigned={anotherJourneyAvailable}
              exitQuestionnaireCompleted={exitQuestionnaireCompleted}
              dualPrepCollabDocGuidId={dualPrepCollabDocGuidId}
              onLoadNextJourney={onLoadNextJourney}
              showRequestCatchUpButton={
                userSkippedArrangingMeeting && !hideRequestCatchUpButton
              }
              onShowManageCatchUpMeetingScreenChange={
                onShowManageCatchUpMeetingScreenChange
              }
            />
          )}
        <ErrorPopup
          error={errorPopupDetails}
          showPopup={showErrorPopup}
          onTogglePopup={setShowErrorPopup}
        />
      </div>
    </>
  );
}

export default JourneyForm;
