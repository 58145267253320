import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import UserContext from "../../../../state/UserContext";
import advancedTasksApi from "../../../../api/task/advancedTasksApi";
import { UserTaskDetail } from "../../../../types/dtos/tasks/advanced-tasks/UserTaskDetail";
import { ScrollableModalContentTemplate } from "../../../common";
import {
  SimpleFormAnswer,
  SimpleFormContent,
  SimpleFormValidationError,
} from "../../../../types/dtos/simple-forms";
import SimpleForm from "../../../simple-forms/editable/SimpleForm";
import { simpleFormHelper } from "../../../../helpers";
import { UserTaskSaveResponse } from "../../../../types/dtos/tasks/advanced-tasks/api-response/UserTaskSaveResponse";
import TaskDetailsSection from "./TaskDetailsSection";
import { ClientTaskType } from "../../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import { ClientTaskTypeCategory } from "../../../../types/dtos/tasks/advanced-tasks/ClientTaskTypeCategory";
import DangerAlert from "../../../alerts/DangerAlert";
import LeavingWarningFooter from "../LeavingWarningFooter";
import { TaskEditPopupUsageScenario } from "../../../../types/tasks/TaskEditPopupUsageScenario";
import { AnswerSetUserTaskDetails } from "../../../../types/dtos/tasks/advanced-tasks/add-popup/AnswerSetUserTaskDetails";
import SimpleFormResponseStatus from "../../../../types/simple-forms/SimpleFormResponseStatus";
import DraftResponseBanner from "./DraftResponseBanner";

interface AdvancedTaskReviewViewProps {
  usageScenario: TaskEditPopupUsageScenario;
  taskType: ClientTaskType | null;
  details: UserTaskDetail;
  formIsDirty: boolean;
  categories: ClientTaskTypeCategory[];
  /** Whether or not this view has been launched directly in the popup, or from the main task details tab's "Complete" button  */
  viewLoadedDirectly: boolean;
  userIsTryingToClosePopup: boolean;
  goBackToBodyDetails(): void;
  goBackFromWarning(): void;
  onClosed(): void;
  /** When loaded directly (e.g. from the collab doc) and clicking the go back option, need to do check to show warning */
  onDirectBackClosed(): void;
  setFormIsDirty(): void;
  proceedAfterWarning(): void;
  onSavedForLater(): void;

  /* Specific properties only used within journey/collab doc usage **/
  currentAnswerSetUniqueId?: string | null;
  formId?: number | null;
}

const AdvancedTaskReviewView = ({
  taskType,
  details,
  categories,
  viewLoadedDirectly,
  formIsDirty,
  userIsTryingToClosePopup,
  usageScenario,
  currentAnswerSetUniqueId = null,
  formId = null,
  goBackToBodyDetails,
  goBackFromWarning,
  onClosed,
  onDirectBackClosed,
  setFormIsDirty,
  proceedAfterWarning,
  onSavedForLater,
}: AdvancedTaskReviewViewProps) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const auth = useAuth();
  const tasksApi = new advancedTasksApi(auth.user?.access_token);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const [viewIsReady, setViewIsReady] = useState<boolean>(false);
  const [formContent, setFormContent] = useState<SimpleFormContent | null>(
    null
  );
  const [formAnswers, setFormAnswers] = useState<SimpleFormAnswer[]>([]);
  const [validationErrors, setValidationErrors] = useState<
    SimpleFormValidationError[] | null
  >(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const matchingTaskType = userContext.user.client.taskTypes.find(
    (tt) => tt.id === details.taskTypeId
  );

  useEffect(() => {
    setViewIsReady(false);
    if (!details.userTaskId) return;

    // If there's no review form, we just show a "Are you sure" UI
    if (!matchingTaskType?.reviewFormId) {
      setViewIsReady(true);
    } else {
      // Load the form content
      loadFormFromApi(details.userTaskId);
    }
  }, [details.userTaskId]);

  function loadFormFromApi(userTaskId: string) {
    const onFormLoaded = (data: SimpleFormContent | null) => {
      setFormContent(data);
      // If there's an existing review form response, load the answers
      setFormAnswers(details.reviewFormInstance?.response.answers || []);
      setViewIsReady(true);
    };

    const onFormLoadError = (error: any) => {
      console.error("Unable to load review form", error);
    };

    tasksApi.getReviewForm(userTaskId, onFormLoaded, onFormLoadError);
  }

  const scrollToTop = () => {
    if (!scrollableContainerRef.current) return;
    scrollableContainerRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleFormAnswerChange = (newAnswer: SimpleFormAnswer) => {
    const newAnswers = simpleFormHelper.updateAnswerState(
      newAnswer,
      formAnswers
    );
    setFormIsDirty();
    setFormAnswers(newAnswers);
  };

  const onSaveForLaterClick = () => {
    createReviewFormResponse("DRAFT");
  };

  const onReviewWithForm = () => {
    createReviewFormResponse("SUBMITTED");
  };

  const createReviewFormResponse = (
    responseStatus: SimpleFormResponseStatus
  ) => {
    // There's a form to fill out, so validate then post the response
    if (!details.userTaskId) return;

    const onReviewError = (error: any) => {
      setIsSaving(false);
      scrollToTop();
      console.error("Unable to close task", error);
    };

    const onReviewSuccess = (data: UserTaskSaveResponse) => {
      setIsSaving(false);
      setValidationErrors(null);
      if (data.savedSuccessfully) {
        onClosed();
      } else if (data.validationErrors && data.validationErrors.length > 0) {
        setValidationErrors(data.validationErrors);
      } else {
        onReviewError(data.errorReason);
      }
    };

    // Reset validation errors
    setValidationErrors(null);

    let answerSetDetails: AnswerSetUserTaskDetails | null = null;
    if (usageScenario === "JOURNEY-COLLAB-DOC") {
      answerSetDetails = {
        answerSetGuidId: currentAnswerSetUniqueId,
        clientFormId: formId,
      };
    }

    // Post the response
    setIsSaving(true);
    tasksApi.reviewTaskWithForm(
      {
        userTaskId: details.userTaskId,
        formId: formContent!.formId,
        answers: formAnswers,
        responseId: details.reviewFormInstance?.response.responseId || null,
        questionIdsToPullCommentsFrom:
          matchingTaskType?.commentQuestionIds || [],
        answerSetDetails: answerSetDetails,
        responseStatus: responseStatus,
      },
      onReviewSuccess,
      onReviewError
    );
  };

  // Don't render anything until the view is marked as ready
  if (!matchingTaskType || !viewIsReady) return null;

  // Also don't render if there isn't a Review form, or the formContent is null
  if (!matchingTaskType.reviewFormId || formContent == null) return null;

  const bodyContent: JSX.Element = userIsTryingToClosePopup ? (
    // Review Form Warning Content
    <DangerAlert
      prefix=""
      message={t("TaskType.Popup.Warning.ChangesMaybeLost")}
    />
  ) : (
    // Review Form Content
    <>
      {taskType && (
        <TaskDetailsSection
          categories={categories}
          taskType={taskType}
          details={details}
          viewType="BODY-AND-CLOSE-FORM"
          showComments
          usageScenario={usageScenario}
        />
      )}
      <SimpleForm
        formContent={formContent}
        answers={formAnswers}
        onChange={handleFormAnswerChange}
        loggedInUser={userContext.user}
        subjectUser={details.ownerEmployee}
        validationErrors={validationErrors}
      />
    </>
  );

  const footerContent: JSX.Element = userIsTryingToClosePopup ? (
    // Review Form Warning Footer
    <LeavingWarningFooter
      goBackFromWarning={goBackFromWarning}
      proceedAfterWarning={proceedAfterWarning}
    />
  ) : (
    // Review Form Footer
    <div className="flex flex-row justify-end gap-4">
      {formIsDirty && (
        <button onClick={onSaveForLaterClick} className="hover:underline">
          {t("TaskType.Popup.Buttons.SaveForLater")}
        </button>
      )}

      <button
        onClick={onReviewWithForm}
        className="btn-primary"
        disabled={isSaving}
      >
        {t("TaskType.Popup.Buttons.SubmitAndClose")}
      </button>
    </div>
  );

  return (
    <>
      {/* If the review form is a draft and the user isn't trying to close popup */}
      {details?.reviewFormInstance?.response?.status === "DRAFT" &&
        !userIsTryingToClosePopup && (
          <DraftResponseBanner
            dateLastModified={
              details.reviewFormInstance.response.dateLastModified
            }
            lastModifiedByEmployeeId={
              details.reviewFormInstance.response.lastModifiedByEmployeeId
            }
            lastModifiedByEmployeeName={
              details.reviewFormInstance.response.lastModifiedByEmployeeName
            }
          />
        )}
      <ScrollableModalContentTemplate
        footer={footerContent}
        bodyRef={scrollableContainerRef}
      >
        <div className="pt-2">{bodyContent}</div>
      </ScrollableModalContentTemplate>
    </>
  );
};

export default AdvancedTaskReviewView;
